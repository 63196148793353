import React from 'react';
import PropTypes from 'prop-types';
import FieldValidIcon from './Icons/FieldValidIcon';
import FieldInvalidIcon from './Icons/FieldInvalidIcon';
import Label from './Label';

//Tailwind classes used by the component
const styleClasses = (error, disabled, inputFieldBgColor) =>
  `font-MarkPro min-h-160 ${
    !disabled ? `${inputFieldBgColor || 'bg-dark-grey'}` : 'bg-medium-grey'
  } text-white py-8 pl-16 pr-24 leading-lg mb-16 leading-lg mb-16 focus:outline-none w-full ${
    error
      ? 'border-live-red border-2'
      : 'border border-light-grey focus:border-white focus:border-white os-transition os-transitionproperty-all'
  }`;

class TextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeHolder: props.placeHolder,
      value: props.value,
      validate: props.validate,
      validator: props.validator,
      _validatorMessage: '',
      changed: props.changed,
      onBlur: props.onBlur,
      onFocus: props.onFocus,
      showIcon: props.showIcon,
      labelText: props.labelText,
      _disabled: props.disabled,
      maxLength: props.maxLength,
      className: props.className,
    };
  }

  //Internal validation handler
  validatorFunction(e) {
    e.target.value = e.target.value.trim();
    this.changeHandler(e);

    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
    if (
      this.props.validate === true &&
      typeof this.props.validator === 'function'
    ) {
      let validatorReturn = this.props.validator(e.target.value, e);
      this.setState({ _validatorMessage: validatorReturn });
    }
  }

  focusHandler(e) {
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus(e);
    }
  }

  //Internal change handler
  changeHandler(e) {
    this.setState({ value: e.target.value });
    if (typeof this.props.changed === 'function') {
      this.props.changed(e.target.value, e);
    }
  }

  renderIcons = () => {
    const { _validatorMessage, showIcon } = this.state;
    return (
      <div className='absolute pin-r m-8'>
        {showIcon && _validatorMessage && (
          <FieldInvalidIcon className='leading-none' />
        )}
        {showIcon && _validatorMessage == null && (
          <FieldValidIcon className='leading-none' />
        )}
      </div>
    );
  };

  render() {
    const { width, maxLength, labelTextSize, inputFieldBgColor } = this.props;
    const {
      placeHolder,
      value,
      _validatorMessage,
      labelText,
      _disabled,
    } = this.state;
    return (
      <div
        className={`${width} ` + this.state.className}
        style={!isNaN(width) ? { width: `${width}rem` } : null}
      >
        <div className='w-full relative mt-16'>
          <Label
            textSize={labelTextSize}
            className='font-MarkProBold'
            color={_validatorMessage ? 'text-live-red' : 'text-white'}
          >
            {labelText}
          </Label>
          <div className={`mt-16 flex flex-row flex-no-wrap`}>
            <span className='relative w-full'>
              {this.renderIcons()}
              <textarea
                placeholder={placeHolder}
                value={value}
                onBlur={(e) => this.validatorFunction(e)}
                onFocus={(e) => this.focusHandler(e)}
                onChange={(e) => this.changeHandler(e)}
                className={`resize-none ${styleClasses(
                  _validatorMessage,
                  _disabled,
                  inputFieldBgColor,
                )}`}
                disabled={_disabled}
                maxLength={maxLength}
              ></textarea>
            </span>
          </div>
        </div>
        <Label
          textSize={'text-xs'}
          color={'text-live-red'}
          className={`
          font-MarkProBold
          block
          ${_validatorMessage ? `mb-16` : ``}
        `}
        >
          {_validatorMessage}
        </Label>
      </div>
    );
  }
}

TextArea.propTypes = {
  /** Placeholder for this input */
  placeHolder: PropTypes.string,
  /** Whether to validate user input once focus is lost from this input. Defaults to ``false``. */
  validate: PropTypes.bool,
  /** Validator function for user input. The function will be invoked with the value and the original event object & must return ``null`` or a ``string`` to indicate a successful or failed input validation. In case of failed validation, the passed string will be rendered as validation error message. This property is required if the ``validate`` property is set to ``true`` */
  validator: PropTypes.func,
  /** onBlur event handler. The function will be invoked with the original event */
  onBlur: PropTypes.func,
  /** onFocus event handler. The function will be invoked with the original event */
  onFocus: PropTypes.func,
  /** Value this input field is currently holding. */
  value: PropTypes.string,
  /** Handler for change event. This function will be invoked with the new value when a user changes the input */
  changed: PropTypes.func,
  /** Width supports a Tailwind width class passed on as string or a fixed width passed on as number in rem.**/
  width: PropTypes.string,
  /** Whether to show input valid/invalid icons inside the input box in case of successful/failed validations. Defaults to true */
  showIcon: PropTypes.bool,
  /** Label for this input. Defaults to null. */
  labelText: PropTypes.string,
  /** Whether this input is disabled or not. Defaults to false */
  disabled: PropTypes.bool,
  /** Maximum length of user input supported by this input */
  maxLength: PropTypes.number,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Text size override for the Label for this input. */
  labelTextSize: PropTypes.string,
};

//Default props
TextArea.defaultProps = {
  placeHolder: '',
  width: 'w-full',
  value: '',
  validate: false,
  validator: null,
  showIcon: true,
  labelText: null,
  disabled: false,
  className: '',
  labelTextSize: 'text-sm',
  maxWoreds: null,
};

export default TextArea;
