import React from 'react';
import PropTypes from 'prop-types';

const FieldInvalidIcon = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 32 32`}
    {...props}
  >
    <g fill="none" fillRule="evenodd" transform="translate(8 8)">
      <circle cx="8" cy="8" r="8" fill="#ff0000" />
      <path
        fill="#000"
        d="M7.564 9.55L7 4.24V4h2.136v.24l-.564 5.31H7.564zm-.467 2.86v-1.923H9.04v1.923H7.097z"
      />
    </g>
  </svg>
);

FieldInvalidIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FieldInvalidIcon.defaultProps = {
  width: 32,
  height: 32,
};

export default FieldInvalidIcon;
