import _ from 'lodash';
import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import LoadingIcon from '../../ui/ui-kit/Icons/LoadingIcon';
import HeaderNav from '../../ui/ui-kit/HeaderNav';
import PageFooter from '../../features/PageFooter';
import * as documentTitleAction from '../../../actions/documentTitle';
import { withFeatureFlags } from '../../context/withFeatureFlags';
import { getAbsoluteStaticUrl } from 'utils/path';
import Button from 'components/ui/ui-kit/Button';

import LogoIcon from '../../ui/ui-kit/Icons/LogoIcon';

import { SignupContextProvider, defaultSignupContext } from './SignupContext';
import ThemeSectionTemplate from './ThemeSectionTemplate';

class SignUp extends Component {
  componentDidUpdate(prevProps) {
    const signUpLink = _.get(this.props, 'systemConfig.links.signUp', null);

    if (signUpLink) {
      window.location.href = signUpLink;
    }
  }

  handleOnViewSubscriptionButtonClick(e) {
    e.preventDefault();
    const topOffset = 150;
    let targetElement = document.getElementById('subscription-plans');
    let targetElementPosition = targetElement.getBoundingClientRect().top;
    const offsetPosition =
      targetElementPosition + window.pageYOffset - topOffset;
    targetElement &&
      window.scrollTo({ behavior: 'smooth', top: offsetPosition });
  }

  render() {
    const { user, getFeatureFlags } = this.props;
    const userInitObserver = _.get(user, 'initObserver');
    const userLoggedIn = _.get(user, 'loggedIn');

    const params = this.props.location.search;
    const { availableSignupThemes } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);
    const themeQueryString = queryString.parse(params).theme;
    const signupTheme = availableSignupThemes.includes(themeQueryString)
      ? themeQueryString
      : 'default';

    const featurePaymentPage = getFeatureFlags('FEATURE_PAYMENT_PAGE');

    // OSN-172 - Mobile webview
    // if (setDevice === 'mobile') {
    //   return (
    //     <div>
    //       {/* What's on optus sport area */}
    //       {this.renderWhatsOn()}

    //       {/* Devices promotion area */}
    //       {this.renderDevices({ excludeAppsDownload: true })}
    //     </div>
    //   );
    // }
    const signupContext = {
      ...defaultSignupContext,
      theme: signupTheme,
      userLoggedIn: userLoggedIn,
      featurePaymentPage: featurePaymentPage,
      handleOnViewSubscriptionButtonClick: this
        .handleOnViewSubscriptionButtonClick,
    };

    return (
      <div className='signup-container'>
        {/* Header nav */}
        <HeaderNav
          customClassNames='signup-header-nav'
          simpleHeader={true}
          showBack={true}
          onBackClicked={() => window.open('/', '_self')}
          backLabel='Home'
          textClass='text-white hover:text-teal'
          textContainerClass='px-0 xs:px-0'
        >
          <Link
            to='/'
            className={`
                nav-logo-icon h-48 flex items-center
                border-transparent
                m-auto os-transition hover:border-teal border-b-4 font-MarkProBold pb-0  pt-0 mb-0 mt-0 sm:text-center
              `}
          >
            <LogoIcon />
          </Link>
          {!userLoggedIn ? (
            <button
              className={`
                os-transition
                text-white
                focus:outline-none
                hover:text-teal pr-16 header-login-link
              `}
              onClick={(e) => {
                e.preventDefault();
                window.open('/login', '_self');
              }}
            >
              <span
                className={`
                  text-base
                  font-MarkProHeavy
                  leading-24px
                  text-teal no-underline hover:text-teal-dark active:text-teal-light
                `}
              >
                Login
              </span>
            </button>
          ) : null}
        </HeaderNav>

        {/* In page content */}
        {!userInitObserver ? (
          <div
            className={`
              page
              login
              mt-80
              mx-auto
              px-14
              w-269
              xs:w-350
              md:mt-144
              md:w-369
              lg:w-381
            `}
          >
            <LoadingIcon
              className={`
                block
                m-auto
                text-center
                lg:h-64
                lg:w-64
              `}
            />
          </div>
        ) : (
          <div
            className={`
              min-h-page
              min-w-279
              overflow-x-hidden
            `}
          >
            <SignupContextProvider value={signupContext}>
              <ThemeSectionTemplate />
            </SignupContextProvider>

            {/* What's on optus sport area */}
            {/* {this.renderWhatsOn()} */}

            {/* Devices promotion area */}
            {/* {this.renderDevices({ downloadAppStoreUrl, downloadPlayStoreUrl })} */}

            {/* Important info area */}
            {/*<ImportantInformation title='Important info' theme='dark' />*/}
          </div>
        )}

        {/* Footer content */}
        <PageFooter ref='footer' hideDownloadApps={true} />
      </div>
    );
  }

  renderWhatsOn() {
    return (
      // <section
      //   className={`
      //     py-40
      //     md:py-64
      //     lg:py-128
      //   `}
      // >
      //   <h2
      //     className={`
      //       font-MarkProHeavy
      //       font-900
      //       leading-none
      //       text-center
      //       text-xl
      //       mb-24
      //       md:mb-32
      //       md:text-2xl
      //       lg:text-2xl
      //     `}
      //   >
      //     {defaultConfig.features.SIGNUP_PAGE_CONFIG.logosSection.headingText}
      //   </h2>
      //   {/*
      //   <Message
      //     className={`
      //       mx-16
      //       my-24
      //       pt-16
      //       pr-16
      //       pb-16
      //       pl-24
      //       max-w-753
      //       bg-dark-grey
      //       border-l-8
      //       rounded-lg
      //       md:mx-auto
      //     `}
      //     width={`w-auto`}
      //     titleClassName={`font-MarkProBold mb-8 leading-normal`}
      //     titleTextSize={`text-base`}
      //     messageClassName={`leading-normal`}
      //     messageTextSize={`text-base`}
      //     type={`warning`}
      //     open={true}
      //     title={`A note to our customers`}
      //     message={`
      //       Live match coverage is on hold with the temporary suspension of the 2019/20 football season in response to the coronavirus pandemic. Live matches will resume if and when it becomes safe for all concerned.
      //     `}
      //     showIcon={false}
      //     showClose={false}
      //   />
      //   */}
      //   <ul
      //     className={`
      //     flex
      //     flex-wrap
      //     justify-center
      //     list-reset
      //     m-auto
      //     max-w-830
      //   `}
      //   >
      //     <li
      //       className={`
      //       w-128
      //     `}
      //     >
      //       <img
      //         src={getAbsoluteStaticUrl('/images/competition/PL.png')}
      //         alt='English Premier League'
      //         width='100%'
      //         height='auto'
      //       />
      //     </li>
      //     <li
      //       className={`
      //       w-128
      //     `}
      //     >
      //       <img
      //         src={getAbsoluteStaticUrl('/images/competition/WSL.png')}
      //         alt='FA Women Super League'
      //         width='100%'
      //         height='auto'
      //       />
      //     </li>
      //     <li
      //       className={`
      //       w-128
      //     `}
      //     >
      //       <img
      //         src={getAbsoluteStaticUrl('/images/competition/LL.png')}
      //         alt='LALIGA'
      //         width='100%'
      //         height='auto'
      //       />
      //     </li>
      //     <li
      //       className={`
      //       w-128
      //     `}
      //     >
      //       <img
      //         src={getAbsoluteStaticUrl('/images/competition/WWC.png')}
      //         alt='FIFA Women World Cup 2023'
      //         width='100%'
      //         height='auto'
      //       />
      //     </li>
      //     <li
      //       className={`
      //       w-128
      //     `}
      //     >
      //       <img
      //         src={getAbsoluteStaticUrl('/images/competition/EQ.png')}
      //         alt='European Qualifiers'
      //         width='100%'
      //         height='auto'
      //       />
      //     </li>
      //     <li
      //       className={`
      //       w-128
      //     `}
      //     >
      //       <img
      //         src={getAbsoluteStaticUrl('/images/competition/NL.png')}
      //         alt='UEFA Nations League'
      //         width='100%'
      //         height='auto'
      //       />
      //     </li>
      //     <li
      //       className={`
      //       w-128
      //     `}
      //     >
      //       <img
      //         src={getAbsoluteStaticUrl('/images/competition/JL.png')}
      //         alt='J.League'
      //         width='100%'
      //         height='auto'
      //       />
      //     </li>
      //     <li
      //       className={`
      //       w-128
      //     `}
      //     >
      //       <img
      //         src={getAbsoluteStaticUrl('/images/competition/KLeague.png')}
      //         alt='K League'
      //         width='100%'
      //         height='auto'
      //       />
      //     </li>
      //     <li
      //       className={`
      //       w-128
      //     `}
      //     >
      //       <img
      //         src={getAbsoluteStaticUrl('/images/competition/DFB.png')}
      //         alt='DFB Pokal'
      //         width='100%'
      //         height='auto'
      //       />
      //     </li>
      //     <li
      //       className={`
      //       w-128
      //     `}
      //     >
      //       <img
      //         src={getAbsoluteStaticUrl('/images/competition/DFBfrauen.png')}
      //         alt='DFB Pokal Frauen'
      //         width='100%'
      //         height='auto'
      //       />
      //     </li>
      //     <li
      //       className={`
      //       w-128
      //     `}
      //     >
      //       <img
      //         src={getAbsoluteStaticUrl('/images/competition/EURO.png')}
      //         alt='UEFA Euro 2024'
      //         width='100%'
      //         height='auto'
      //       />
      //     </li>
      //   </ul>
      // </section>
      null
    );
  }

  // can be removed
  renderDevices({
    downloadAppStoreUrl = 'https://itunes.apple.com/au/app/optus-sport/id1113368382?mt=8',
    downloadPlayStoreUrl = 'http://play.google.com/store/apps/details?id=au.com.optus.sport',
    excludeAppsDownload = false,
  }) {
    return (
      <section
        className={`
          devices
          py-40
          md:py-64
          lg:py-128
        `}
      >
        <h2
          className={`
            font-MarkProHeavy
            font-900
            text-xl
            leading-none
            text-center
            mb-32
            md:text-2xl
            lg:text-4xl
            md:mb-40
            lg:mb-64
          `}
        >
          One account, so many devices
        </h2>
        <ul
          className={`
            text-center
            flex
            flex-wrap
            justify-center
            list-reset
            mb-24
            m-auto
            px-16
            max-w-1206
            md:mb-48
            lg:mb-72
            lg:px-0
          `}
        >
          <li
            className={`
              w-full
              max-w-xs
              mb-24
              px-0
              md:w-1/2
              md:px-8
              lg:w-1/4
              lg:px-16
            `}
          >
            <img
              className={`
                mb-24
              `}
              src={getAbsoluteStaticUrl('/images/mobile-tablet.svg')}
              alt='Mobile &amp; Tablet'
            />
            <h3
              className={`
                font-MarkProHeavy
                text-xl
                leading-none
                mb-16
              `}
            >
              Mobile &amp; Tablet
            </h3>
            <p
              className={`
                text-base
                leading-normal
              `}
            >
              We support Android &amp; iOS devices. Download us from Google Play
              or the Apple app store.
            </p>
          </li>
          <li
            className={`
              w-full
              max-w-xs
              mb-24
              px-0
              md:w-1/2
              md:px-8
              lg:w-1/4
              lg:px-16
            `}
          >
            <img
              className={`
                mb-24
              `}
              src={getAbsoluteStaticUrl('/images/tv.svg')}
              alt='TV'
            />
            <h3
              className={`
                font-MarkProHeavy
                text-xl
                leading-none
                mb-16
              `}
            >
              TV
            </h3>
            <p
              className={`
                text-base
                leading-normal
              `}
            >
              Watch us on the big screen! With Fetch TV, Apple TV, Android TV,
              Samsung, LG, Amazon Fire, and Chromecast.
            </p>
          </li>
          <li
            className={`
              w-full
              max-w-xs
              mb-24
              px-0
              md:w-1/2
              md:px-8
              lg:w-1/4
              lg:px-16
            `}
          >
            <img
              className={`
                mb-24
              `}
              src={getAbsoluteStaticUrl('/images/computer.svg')}
              alt='Computer'
            />
            <h3
              className={`
                font-MarkProHeavy
                text-xl
                leading-none
                mb-16
              `}
            >
              Computer
            </h3>
            <p
              className={`
                text-base
                leading-normal
              `}
            >
              All major modern browsers are supported. Chrome, Firefox, Safari,
              Edge.
            </p>
          </li>
          <li
            className={`
              w-full
              max-w-xs
              mb-24
              px-0
              md:w-1/2
              md:px-8
              lg:w-1/4
              lg:px-16
            `}
          >
            <img
              className={`
                mb-24
              `}
              src={getAbsoluteStaticUrl('/images/game-console.svg')}
              alt='Game Consoles'
            />
            <h3
              className={`
                font-MarkProHeavy
                text-xl
                leading-none
                mb-16
              `}
            >
              Game Consoles
            </h3>
            <p
              className={`
                text-base
                leading-normal
              `}
            >
              Enjoy Optus Sport on PlayStation 4, Xbox One and Xbox Series
              consoles.
            </p>
          </li>
        </ul>

        <Button
          type='secondary-alternate'
          text='Full list of available devices'
          htmlType='link'
          buttonClassName='min-w-220 mb-64 m-auto'
          href='https://www.optus.com.au/for-you/support/answer?id=6989'
        ></Button>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    systemConfig: state.systemConfig,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    documentActions: bindActionCreators(documentTitleAction, dispatch),
  };
}

export default compose(
  withFeatureFlags,
  connect(mapStateToProps, mapDispatchToProps),
)(SignUp);
