import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EpgRow from './EpgRow';

class Epg extends Component {
  constructor(props) {
    super(props);

    const { events } = this.props;
    const openSections = {};

    if (events) {
      _.each(events, eventData => {
        const { eventId } = eventData;

        openSections[eventId] = _.get(eventData, 'isOpen', false);
      })
    }

    this.state = {
      openSections
    };
  }

  onClick = id => {
    if (id) {
      const { allowMultipleOpen } = this.props;
      const { openSections } = this.state;
      const isOpen = _.get(openSections, id, false);

      if (allowMultipleOpen) {
        this.setState({
          'openSections': {
            ...openSections,
            [id]: !isOpen
          }
        });
      } else {
        this.setState({
          'openSections': {
            [id]: !isOpen
          }
        });
      }
    }
  };

  render() {
    const { className, events } = this.props;
    const { openSections } = this.state;
    const emptyEpg = !events || _.isEmpty(events);

    return (
      <div
        className={`
          w-auto
          ${className || ''}
          ${emptyEpg ? `text-center pt-16 lg:pt-20` : 'pt-4 lg:pt-8'}
        `}
      >
        {!emptyEpg ?
          _.map(events, (eventData, index) => {
            const { eventId } = eventData;
            const isOpen = _.get(openSections, eventId, false);

            return (
              <EpgRow
                eventId={eventId}
                event={eventData}
                isOpen={isOpen}
                key={index}
                onClick={this.onClick}
              />
            );
          })
          :
          `EPG currently not available.`
        }
      </div>
    );
  }
}

Epg.propTypes = {
  className: PropTypes.string,
  events: PropTypes.arrayOf(Object).isRequired,
  allowMultipleOpen: PropTypes.bool
};

Epg.defaultProps = {
  allowMultipleOpen: true
};

export default Epg;
