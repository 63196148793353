import { useSelector } from 'react-redux';
import getAssetIdByLocationId from 'utils/getAssetIdByLocationId';

const useDynamicContentCard = (contentCardLocationId) => {
  const { dynamicContentCards = [] } =
    useSelector((state) => ({
      dynamicContentCards: state.dynamicContentCards,
    })) || {};

  const assetId = getAssetIdByLocationId(
    dynamicContentCards,
    contentCardLocationId,
  );

  return assetId;
};

export default useDynamicContentCard;
