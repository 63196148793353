import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';
import FieldValidIcon from './Icons/FieldValidIcon';
import FieldInvalidIcon from './Icons/FieldInvalidIcon';
import InfoIcon from './Icons/InfoIcon';
import SearchIcon from './Icons/SearchIcon';
import LockedIcon from '../ui-kit/Icons/LockedIcon';

import VisibilityIcon from './Icons/VisibilityIcon';
import _ from 'lodash';

//Tailwind classes used by the component
const styleClasses = (
  error,
  disabled,
  marginBottom,
  disableSpinButton,
  inputFieldBgColor,
) =>
  `${
    disableSpinButton ? 'disable-spin-button' : ''
  } os-transition os-transitionproperty-all font-MarkPro ${
    !disabled ? `${inputFieldBgColor || 'bg-dark-grey'}` : 'bg-medium-grey'
  } text-white px-16 ${
    marginBottom ? `mb-${marginBottom}` : 'mb-16'
  } h-48 focus:outline-none w-full ${
    error
      ? 'border-live-red border-2 rounded'
      : 'border-light-grey border-2 focus:border-white rounded'
  }`;

/**
 * Optus Sport React User input field component
 */

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeHolder: props.placeHolder,
      value: props.value || ``,
      type: props.type,
      validate: props.validate,
      validator: props.validator,
      _validatorMessage: '',
      changed: props.changed,
      onBlur: props.onBlur,
      onFocus: props.onFocus,
      showIcon: props.showIcon,
      labelText: props.labelText,
      toolTip: props.toolTip,
      passwordMasked: true,
      toolTipVisible: false,
      _disabled: props.disabled,
      maxLength: props.maxLength,
      className: props.className,
      showLockedIcon: props.showLockedIcon,
      isTrim: props?.isTrim,
    };
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = {};

    const nextDisabled = _.get(nextProps, 'disabled');
    const nextShowLockedIcon = _.get(nextProps, 'showLockedIcon');
    const nextValidate = _.get(nextProps, 'validate');
    const nextShowIcon = _.get(nextProps, 'showIcon');
    const nextForcedValidatorMessage = _.get(
      nextProps,
      'forcedValidatorMessage',
    );

    if (nextDisabled !== prevState._disabled) {
      nextState._disabled = nextDisabled;
    }
    if (nextShowLockedIcon !== prevState.showLockedIcon) {
      nextState.showLockedIcon = nextShowLockedIcon;
    }
    if (nextValidate !== prevState.validate) {
      nextState.validate = nextValidate;
    }
    if (nextShowIcon !== prevState.showIcon) {
      nextState.showIcon = nextShowIcon;
    }
    if (
      nextForcedValidatorMessage &&
      nextForcedValidatorMessage !== prevState._validatorMessage
    ) {
      nextState._validatorMessage = nextForcedValidatorMessage;
    }
    return nextState;
  }

  showToolTip = (toolTipVisible) => {
    this.setState({ toolTipVisible });
  };

  //Internal validation handler
  validatorFunction(e) {
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
    const _validatorMessage = this.props.validator(e.target.value, e);
    this.setState({ _validatorMessage });
  }

  //Internal change handler
  changeHandler(e) {
    const max = _.get(this.props, 'maxLength', 0);
    const inputValue = _.get(e, 'target.value', '');
    const value = this.state.isTrim ? inputValue.trim() : inputValue;

    if (max && value.length > max) {
      const truncatedValue = value.substring(0, max);
      this.setState({ value: truncatedValue });
    } else {
      this.setState({ value: value });
    }
    if (typeof this.props.changed === 'function') {
      this.props.changed(value, e);
    }
  }

  togglePassword(_state) {
    this.setState({ passwordMasked: _state });
  }

  renderIcons = () => {
    const {
      _validatorMessage,
      type,
      showIcon,
      toolTip,
      passwordMasked,
      showLockedIcon,
    } = this.state;
    const isPassword = type === 'password';
    const showPassword = passwordMasked === false;
    return (
      <div className='absolute pin-r p-10'>
        {showIcon && _validatorMessage && <FieldInvalidIcon />}
        {showIcon && _validatorMessage == null && <FieldValidIcon />}
        {isPassword && (
          <VisibilityIcon
            className={` os-transition os-transitionproperty-all cursor-pointer text-white ${
              !showPassword ? ' opacity-50' : ''
            }`}
            onClick={() => this.togglePassword(showPassword)}
          />
        )}
        {toolTip != null && (
          <InfoIcon
            onPointerEnter={() => this.showToolTip(true)}
            onPointerLeave={() => this.showToolTip(false)}
            className='cursor-pointer'
          />
        )}
        {this.props.type === 'search' ? (
          <SearchIcon width='32' height='32' />
        ) : null}
        {showLockedIcon && <LockedIcon />}
      </div>
    );
  };

  componentDidMount() {
    const { inputRef, autoFocus, value, validator } = this.props;
    const ref = inputRef || this.inputRef;
    if (ref.current && autoFocus) {
      ref.current.focus();
    }
    if (value && validator) {
      const _validatorMessage = validator(value);
      this.setState({ _validatorMessage });
    }
  }

  render() {
    const {
      width,
      autocomplete,
      name,
      marginBottom,
      inputRef,
      disableSpinButton,
      defaultValue,
      autoFocus,
      show,
      labelTextSize,
      inputContainerClassName,
      inputFieldContainerClassName,
      inputFieldBgColor,
    } = this.props;
    const {
      placeHolder,
      value,
      type,
      validator,
      _validatorMessage,
      onFocus,
      validate,
      toolTipVisible,
      labelText,
      toolTip,
      passwordMasked,
      _disabled,
      maxLength,
    } = this.state;
    let isPassword = type === 'password';

    const hidden = show ? `` : `hidden`;
    const valueProp = defaultValue ? { defaultValue } : { value };
    return (
      <div
        className={`${width} ${this.props.className} ${hidden} ${inputContainerClassName}`}
        style={!isNaN(width) ? { width: `${width}rem` } : null}
      >
        <div className='w-full relative flex flex-col items-left'>
          {this.props.labelText ? (
            <Label
              textSize={labelTextSize}
              className='font-MarkProBold os-transition os-transitionproperty-all md:text-left'
              color={_validatorMessage ? 'text-live-red' : 'text-white'}
            >
              {labelText}
            </Label>
          ) : null}

          <div
            className={`mt-16 flex flex-row flex-no-wrap ${inputFieldContainerClassName}`}
          >
            <span className='relative w-full'>
              {this.renderIcons()}
              <input
                {...valueProp}
                autoFocus={autoFocus}
                name={name || `input-${parseInt(Math.random() * 1000000, 10)}`}
                type={!isPassword ? type : passwordMasked ? type : 'string'}
                placeholder={placeHolder}
                onBlur={
                  validate === true && typeof validator === 'function'
                    ? (e) => this.validatorFunction(e)
                    : null
                }
                onFocus={
                  typeof onFocus === 'function'
                    ? (e) => this.props.onFocus(e)
                    : null
                }
                ref={inputRef || this.inputRef}
                onChange={(e) => this.changeHandler(e)}
                className={styleClasses(
                  _validatorMessage,
                  _disabled,
                  marginBottom,
                  disableSpinButton,
                  inputFieldBgColor,
                )}
                disabled={_disabled}
                maxLength={maxLength}
                autoComplete={autocomplete || `some-random-string`}
                {...(this.props.inputProps || {})}
              />
            </span>
          </div>
          {toolTip && toolTipVisible && (
            <Label className='os-triangle-after-tooltip -mt-4 py-16 px-8 bg-medium-grey w-full absolute block text-sm'>
              {toolTip}
            </Label>
          )}
        </div>
        <Label
          textSize={'text-xs'}
          color={'text-live-red'}
          className={`
            font-MarkProBold
            block
            os-transition
            os-transitionproperty-all
            ${_validatorMessage ? `mb-16` : ``}
          `}
        >
          {_validatorMessage}
        </Label>
      </div>
    );
  }
}

Input.propTypes = {
  /** Placeholder for this input */
  placeHolder: PropTypes.string,
  /** Type of value this input expects. */
  type: PropTypes.oneOf([
    'string',
    'number',
    'password',
    'search',
    'tel',
    'email',
  ]),
  /** Whether to validate user input once focus is lost from this input. Defaults to ``false``. */
  validate: PropTypes.bool,
  /** Validator function for user input. The function will be invoked with the value and the original event object & must return ``null`` or a ``string`` to indicate a successful or failed input validation. In case of failed validation, the passed string will be rendered as validation error message. This property is required if the ``validate`` property is set to ``true`` */
  validator: PropTypes.func,
  /** onBlur event handler. The function will be invoked with the original event */
  onBlur: PropTypes.func,
  /** onFocus event handler. The function will be invoked with the original event */
  onFocus: PropTypes.func,
  /** Value this input field is currently holding. Type of value will depend on the type of the input. For passwords, the type of value will be ``string``. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Handler for change event. This function will be invoked with the new value when a user changes the input */
  changed: PropTypes.func,
  /** Width supports a Tailwind width class passed on as string or a fixed width passed on as number in rem.**/
  width: PropTypes.string,
  /** Whether to show input valid/invalid icons inside the input box in case of successful/failed validations. Defaults to true */
  showIcon: PropTypes.bool,
  /** Label for this input. Defaults to null. */
  labelText: PropTypes.string,
  /** Tooltip text to be shown. Defaults to null. */
  toolTip: PropTypes.string,
  /** Whether this input is disabled or not. Defaults to false */
  disabled: PropTypes.bool,
  /** Maximum length of user input supported by this input */
  maxLength: PropTypes.number,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Custom margin-bottom size */
  marginBottom: PropTypes.string,
  /** element reference */
  inputRef: PropTypes.object,
  /** Hide input's spin arrows  */
  disableSpinButton: PropTypes.bool,
  /** Auto focus */
  autoFocus: PropTypes.bool,
  /** Default value */
  defaultValue: PropTypes.string,
  /** Used to force validator message */
  forcedValidatorMessage: PropTypes.string,
  /** Text size override for the Label for this input. */
  labelTextSize: PropTypes.string,
};

//Default props
Input.defaultProps = {
  placeHolder: '',
  width: 'w-full',
  value: '',
  type: 'string',
  validate: false,
  validator: null,
  showIcon: true,
  labelText: null,
  toolTip: null,
  disabled: false,
  className: '',
  showLockedIcon: false,
  marginBottom: null,
  disableSpinButton: false,
  show: true,
  labelTextSize: 'text-sm',
  inputContainerClassName: '',
  inputFieldContainerClassName: '',
};

export default Input;
