export default {
  light: {
    color: '#000',
  },
  lightv2: {
    color: '#000',
  },
  dark: {
    color: '#FFF',
  },
};
