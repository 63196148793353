import React, { useContext } from 'react';
import { getAbsoluteStaticUrl } from '../../../utils/path';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import SignupContext from './SignupContext';

function SellingPointsView({ itemsList, images }) {
  return (
    <section className='relative w-full flex flex-col bg-gray pl-16 sm:pl-32 lg:pl-120 lg:items-center pb-0 xs:pb-48 sm:pb-0 xs:mt-80 sm:mt-0 signup-page-selling-points'>
      <div className='relative flex justify-start sm:justify-center items-center w-full text-black pt-64 pb-96 sm:pt-0 sm:pb-0 lg:max-w-1184'>
        <div className='content-section mr-0 md:-mr-32 lg:mr-16'>
          <div className=''>
            {itemsList.map((sp, i) => (
              <div
                className={`
                      text-left
                      max-w-150 xxs:max-w-180 xs:max-w-234 md:max-w-347 lg:max-w-412
                      ${i === itemsList.length - 1 ? 'mb-0' : 'mb-72 md:mb-96'}
                    `}
                key={i}
              >
                <h1
                  className={`
                        font-MarkProHeavy
                        font-900
                        text-lg
                        xxs:text-xl
                        xs:text-2xl
                        md:text-4xl
                        lg:text-5xl
                        text-green
                        leading-none
                      `}
                >
                  {sp.headerText}
                </h1>
                {sp.subText && (
                  <p
                    className={`
                        font-MarkPro
                        lg:mb-40
                        leading-normal
                        text-base
                        lg:text-lg
                        text-light-indicator-grey
                        font-bold
                      `}
                  >
                    {sp.subText}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
        <picture className='flex relative justify-center items-center mb-0 sm:-mb-70'>
          <div className='sp-gradient-background'></div>
          <source
            media={`(max-width:374px)`}
            srcSet={getAbsoluteStaticUrl(images?.mobile)}
          />
          <source
            media={`(max-width:992px)`}
            srcSet={getAbsoluteStaticUrl(images?.tablet)}
          />
          <img
            src={getAbsoluteStaticUrl(images?.desktop)}
            alt={''}
            className={'h-full w-full object-cover'}
          />
        </picture>
      </div>
    </section>
  );
}

const SellingPoints = ({ getFeatureFlags }) => {
  const signupContext = useContext(SignupContext);
  const currentTheme = signupContext.theme;
  const { sellingPointsSection } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);

  if (!sellingPointsSection?.showSection) {
    return null;
  }

  let itemsList = [];
  let images = {
    mobile: '',
    tablet: '',
    desktop: '',
  };

  if (!sellingPointsSection.themes) {
    // non-themed version config
    itemsList = sellingPointsSection.itemsList;
    images = {
      mobile: '/images/signup/pl-selling-points-mobile-2.png',
      tablet: '/images/signup/pl-selling-points-tablet-2.png',
      desktop: '/images/signup/pl-selling-points-desktop-2.png',
    };
  } else {
    // themed version config
    itemsList = sellingPointsSection.themes.default.itemsList;
    images = sellingPointsSection.themes.default.images;

    if (
      currentTheme &&
      sellingPointsSection.themes[currentTheme] &&
      sellingPointsSection.themes[currentTheme]?.images
    ) {
      images = sellingPointsSection.themes[currentTheme].images;
    }
  }

  return <SellingPointsView itemsList={itemsList} images={images} />;
};
export default withFeatureFlags(SellingPoints);
