import React, { Component } from "react";
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

import StatusBar from './StatusBar';
import Team from './Team';

const duration = 300;

const transitionStyles = {
  entering: { opacity: 0, transform: `translateX(150px) scale(0.25)` },
  entered:  { opacity: 1, transform: `translateX(0) scale(1)` },
  exiting: { opacity: 1, transform: `translateX(0) scale(1)` },
  exited: { opacity: 0, transform: `translateX(-150px) scale(0)` }
};

class Fixture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blurElement: false,
      in: false
    }
  }

  componentDidMount() {
    this.setState({
        in: true
    });
  }

  componentWillUnmount() {
    this.setState({
      in: false
    });
  }

  renderLiveHoverSection = (livePlayURL, liveStartOverText) => {
    return (
      <div className={`w-135 h-80 absolute no-blur pin-l pin-t saag-fixture-live-hover-section ${this.state.blurElement ? 'popover-fadein' : 'popover-fadeout'}`}>
        <div className="h-40 flex flex-no-wrap justify-center items-center border-b border-medium-dark-grey">
          <a href={`${livePlayURL}&watchLive=true&watchFromStart=false`} className="text-white font-MarkProBold text-xs leading-loose letter-spacing-0point1 opacity-60 hover:opacity-100 no-underline hover:underline">Watch Live</a>
        </div>
        <div className="h-40 flex flex-no-wrap justify-center items-center">
          <a href={`${livePlayURL}&watchFromStart=true&watchLive=false`} className="text-white font-MarkProBold text-xs leading-loose letter-spacing-0point1 opacity-60 hover:opacity-100 no-underline hover:underline">{liveStartOverText}</a>
        </div>
      </div>
    )
  }

  onUpcomingFixtureClick = url => {
    this.props.gotoMatch(url)
  }

  renderStatusBar = (type, startDate, startTime, showScore) => {
    if (type === "live") {
      return (
        <div className="pl-14 mb-8">
          <StatusBar
            type={type}
            time={this.props.matchMinutes}
            liveStatus={this.props.matchStatus}
            matchPeriod={this.props.matchPeriod}
            startDate={startDate}
            showScore={showScore}
          />
        </div>
      )
    } else if (type === "upcoming") {
      return (
        <div className="pl-14 mb-8 os-top-1 relative">
          <StatusBar
            type={type}
            startDate={startDate}
            startTime={startTime}
            matchPeriod={this.props.matchPeriod}
            showScore={showScore}
          />
        </div>
      )
    } else if (type === "completed") {
      return (
        <div className="pl-14 mb-8 os-top-1 relative">
          <StatusBar
            type={type}
            startDate={startDate}
            showScore={showScore}
          />
        </div>
      )
    }
    return null;
  }

  getSFShortcode = teamName => {
    let shortCode = '';

    switch (teamName) {
      case 'Semi-Final 1 Loser':
        shortCode = 'SF 1 Loser';
        break;
      case 'Semi-Final 1 Winner':
        shortCode = 'SF 1 Winner';
        break;
      case 'Semi-Final 2 Loser':
        shortCode = 'SF 2 Loser';
        break;
      case 'Semi-Final 2 Winner':
        shortCode = 'SF 2 Winner';
        break;
      case 'Quarter-Final 1 Loser':
        shortCode = 'QF 1 Loser';
        break;
      case 'Quarter-Final 1 Winner':
        shortCode = 'QF 1 Winner';
        break;
      case 'Quarter-Final 2 Loser':
        shortCode = 'QF 2 Loser';
        break;
      case 'Quarter-Final 2 Winner':
        shortCode = 'QF 2 Winner';
        break;
      case 'Quarter-Final 3 Loser':
        shortCode = 'QF 3 Loser';
        break;
      case 'Quarter-Final 3 Winner':
        shortCode = 'QF 3 Winner';
        break;
      case 'Quarter-Final 4 Loser':
        shortCode = 'QF 4 Loser';
        break;
      case 'Quarter-Final 4 Winner':
        shortCode = 'QF 4 Winner';
        break;
      default:
        shortCode = 'TBC';
    }

    return shortCode;
  }

  render() {
    const {
      showScore,
      homeTeamShortcode,
      awayTeamShortcode,
      matchPeriod,
      homeScore,
      awayScore,
      type,
      homeTeamImg = '',
      awayTeamImg = '',
      matchID,
      startDate,
      startTime,
      enableLiveHover,
      liveStartOverText
    } = this.props;

    const defaultStyle = {
      transition: `opacity ${duration}ms ease-out, transform ${duration + 150}ms cubic-bezier(0.23, 1, 0.32, 1)`,
      opacity: 0,
      transform: `translateX(150px) scale(0.25)`
    };

    return (
      <Transition in={this.state.in} timeout={duration} unmountOnExit key={matchID}>
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}
            onMouseEnter={() => this.setState({ blurElement: true })}
            onMouseLeave={() => this.setState({ blurElement: false })}
            className="relative os-box-sizing-border-box saag-fixture"
          >
            <div
              onClick={() => this.onUpcomingFixtureClick(this.props.matchDetailsURL)}
              className={`flex flex-col flex-no-wrap w-135 h-80 border-r border-medium-grey bg-widget-grey cursor-pointer saag-fixture-type-${type} ${enableLiveHover && this.state.blurElement && type !== "upcoming" && type !== "completed" ? 'blur-element-saag-fixture' : ''}`}
            >
              {this.renderStatusBar(type, startDate, startTime, showScore)}
              <div className="mb-8">
                <Team
                  imgUrl={homeTeamImg}
                  teamShortCode={homeTeamShortcode && homeTeamShortcode.length > 5 ? this.getSFShortcode(homeTeamShortcode) : homeTeamShortcode}
                  score={parseInt(homeScore)}
                  leading={homeScore > awayScore ? true : false}
                  showScore={showScore && type !== 'upcoming'}
                />
              </div>

              <div className="">
                <Team
                  imgUrl={awayTeamImg}
                  teamShortCode={awayTeamShortcode && awayTeamShortcode.length > 5 ? this.getSFShortcode(awayTeamShortcode) : awayTeamShortcode}
                  score={parseInt(awayScore)}
                  leading={homeScore < awayScore ? true : false}
                  showScore={showScore && type !== 'upcoming'}
                />
              </div>
            </div>

            {enableLiveHover && type === "live" && this.state.blurElement && matchPeriod !== "Full Time" ? this.renderLiveHoverSection(this.props.matchDetailsURL, liveStartOverText) : null}
          </div>
        )}
      </Transition>
    )
  }
}

Fixture.propTypes = {
  /** Show score option */
  showScore: PropTypes.bool.isRequired
};

Fixture.defaultProps = {
  showScore: false
};

export default Fixture;
