import React from 'react';

const CircularInfoIcon = ({ width, height, viewBox }) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26ZM16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4ZM17.004 14H15.004V22H17.004V14ZM16 9.75C15.2914 9.75 14.75 10.2981 14.75 11.0067C14.75 11.7086 15.2914 12.25 16 12.25C16.7019 12.25 17.25 11.7086 17.25 11.0067C17.25 10.2981 16.7019 9.75 16 9.75Z'
      fill='white'
    />
  </svg>
);

CircularInfoIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: `0 0 32 32`,
};

export default CircularInfoIcon;
