import React from 'react';
import Button from './ui-kit/Button';

const BrowseHomeButton = ({ width = 'w-full', ...props }) => (
  <Button
    className={`mb-16 text-black ${width}`}
    type='primary-alternate'
    width={width}
    text='Browse Optus Sport'
    handler={() => window.open(`/`, `_self`)}
    rounded
    textClassName={`font-MarkProHeavy text-base leading-normal`}
    {...props}
  />
);

export default BrowseHomeButton;
