import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import CircularInfoIcon from './ui-kit/Icons/CircularInfoIcon';
import Input from './ui-kit/Input';
import CardFieldInput from './CardFieldInput';
import { withFeatureFlags } from '../context/withFeatureFlags';
import styled, { css } from 'styled-components';
import { useClickOutside as ClickOutside } from '../../utils/hooks';
import { analyticAction } from '../../ducks/analytic';
import { isValidToken } from 'ducks/payment';

const TooltipItem = styled.div`
  display: none;
  visibility: hidden;
  opacity: 0;
  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
      visibility: visible;
      display: block;
    `}
`;

const Tooltip = ({ allowedCardTypes = [] }) => {
  const [tooltip, setTooltip] = useState(false);
  return (
    <>
      <span className={`absolute pin-r mr-8 mt-8 cursor-pointer`}>
        <button
          onMouseEnter={() => setTooltip(true)}
          onMouseLeave={() => setTooltip(false)}
          onClick={() => setTooltip(true)}
        >
          <CircularInfoIcon />
        </button>
      </span>
      <ClickOutside callback={() => setTooltip(false)}>
        <TooltipItem isActive={tooltip} className={`cvv-tooltip`}>
          <div className={`cvv-border-triangle`} />
          <div
            className={`
              cvv-content
              px-16
              py-8
              bg-lightest-grey
              text-black
              z-99999
              absolute
              border-lightest-grey
              rounded-lg
            `}
          >
            <p className={`text-sm leading-1.29`}>
              <span className={`font-MarkProBold`}>Visa® and Mastercard® cards:</span>{' '}
              Three digit CVV code is located on the back of your credit/debit
              card on the right side of the white signature strip.
              <br />
              <br />
              {allowedCardTypes.includes('amex') && (
                <span>
                  <span className={`font-MarkProBold`}>
                    American Express® card:
                  </span>{' '}
                  Four digit CVV code is located on the front right side of the
                  card.
                </span>
              )}
            </p>
          </div>
        </TooltipItem>
      </ClickOutside>
    </>
  );
};

// TODO: Move to common utils function
function loadScript(id, src, callback, errorCallback) {
  if (document.getElementById(id) && window.customcheckout) {
    return callback();
  }
  const script = document.createElement(`script`);
  script.id = id;
  script.async = false;
  script.src = src;
  script.onload = callback;
  script.onerror = errorCallback;
  document.body.appendChild(script);
}

class CardDetailsInput extends React.Component {
  state = {
    fields: {
      'card-holder': false,
      'card-number': false,
      'card-expiry': false,
      'card-cvv': false,
    },
  };

  componentDidMount() {
    this.loadCustomCheckout();
  }

  componentDidUpdate(prevProps) {
    const {
      customCheckoutLoaded,
      customCheckoutEventListenersReady,
    } = this.state;
    if (customCheckoutLoaded && !customCheckoutEventListenersReady) {
      this.setupCustomCheckoutEventListeners();
      this.setState({ customCheckoutEventListenersReady: true });
      return;
    }
    const { getToken, onToken } = this.props;
    const shouldGetToken = getToken && getToken !== prevProps.getToken;
    if (shouldGetToken && onToken) {
      const { getFeatureFlags } = this.props;
      const merchantId = getFeatureFlags(`FEATURE_PAYMENT_MERCHANT_ID`);
      this.checkout.createOneTimeToken(merchantId, (result) => {
        if (!isValidToken(result)) {
          this.props.analyticAction({
            eventName: 'Checkout Form Token Failure',
          });
        } else {
          this.props.analyticAction({
            eventName: 'Checkout Form Token Success',
          });
        }

        const { name } = this.state;
        onToken({
          holderName: name,
          ...result,
        });
      });
      return;
    }
  }

  componentWillUnmount() {
    Object.keys(this.getEventMap()).forEach((eventName) => {
      this.removeEventListener(eventName);
    });

    // Ken (22Aug2020):
    // There is an auto attached event listener for 'token' which is left behind
    // so we must make it listen to nothing. There's no exposed prototype to remove
    // it as it's internalised within the bambora checkout js library - controller class.
    // This is an issue where we have soft navigates. On hard page loads, the event
    // listener for 'token' is obviously removed.
    const { customCheckoutLoaded } = this.state;
    if (this.checkout && customCheckoutLoaded) {
      this.checkout.createOneTimeToken(null, null);
    }
    // Dirty hack until Bambora exposes destroy:
    // Suppress orphaned eventlistener anon function attached to global window object
    // by making it perform no action, just return null
    if (
      window.controller &&
      typeof window.controller.receiveMessage === 'function'
    ) {
      window.controller.receiveMessage = (event) => null;
    }

    this.checkout = null;
  }

  loadCustomCheckout = () => {
    const { getFeatureFlags } = this.props;
    const customCheckoutUrl = getFeatureFlags(
      `FEATURE_PAYMENT_CUSTOM_CHECKOUT_URL`,
    );
    loadScript(
      `custom-checkout`,
      customCheckoutUrl,
      () => {
        if (window.customcheckout) {
          this.checkout = window.customcheckout();
          this.setState({ customCheckoutLoaded: true });

          this.props.analyticAction({
            eventName: 'Checkout Form Loaded Success',
          });
        } else {
          this.setState({ customCheckoutLoaded: false });
          this.props.analyticAction({
            eventName: 'Checkout Form Loaded Failure',
          });
        }
      },
      () => {
        this.setState({ customCheckoutLoaded: false });
        this.props.analyticAction({
          eventName: 'Checkout Form Loaded Failure',
        });
      },
    );
  };

  onValidate = (field, valid) => {
    const { name, fields } = this.state;
    const updatedFields = {
      ...fields,
      [field]: valid,
    };
    this.setState({
      fields: updatedFields,
    });
    const isValid =
      valid && !!name && Object.values(updatedFields).every(Boolean);
    if (this.props.onValidate) {
      this.props.onValidate(isValid);
    }
  };

  setEvent = (eventName) => (event) => {
    this.setState({
      event: {
        [eventName]: event,
      },
    });
  };

  addEventListener(eventName, listener) {
    if (this.checkout) {
      this.checkout.on(eventName, listener);
    }
  }

  removeEventListener(eventName) {
    if (this.checkout) {
      this.checkout.on(eventName, null);
    }
  }

  getEventMap = () => {
    const events = [`brand`, `empty`, `complete`, `error`, `focus`, `blur`];
    return events.reduce((eventMap, event) => {
      return {
        ...eventMap,
        [event]: this.setEvent(event),
      };
    }, {});
  };

  setupCustomCheckoutEventListeners() {
    Object.entries(this.getEventMap()).forEach((event) => {
      const [eventName, callback] = event;
      this.addEventListener(eventName, callback);
    });
  }

  render() {
    const disabled = !this.state.customCheckoutEventListenersReady;
    const { className = ``, getFeatureFlags } = this.props;
    const allowedCardTypes = getFeatureFlags(`FEATURE_ALLOWED_CARD_TYPES`);
    return (
      <div className={`${className} new-credit-card-section`}>
        <Input
          id='card-holder'
          className='mb-16'
          type='string'
          labelText='Name on card'
          placeHolder='John Smith'
          changed={(value) => {
            const name = value.trim();
            this.setState({ name });
            // TODO: Do some name validation...
            this.onValidate(`card-holder`, !!value);
          }}
          disabled={disabled}
          inputFieldContainerClassName='xs:mt-8'
        />
        <div className='flex flex-col mb-24'>
          <CardFieldInput
            field='card-number'
            type='card-number'
            label='Card number'
            placeholder='0000 0000 0000 0000'
            disabled={disabled}
            event={this.state.event}
            checkout={this.checkout}
            onValidate={this.onValidate}
            allowedCardTypes={allowedCardTypes}
          />
        </div>
        <div className='flex flex-1 justify-between mb-16'>
          <div className='flex flex-col w-49p'>
            <CardFieldInput
              field='card-expiry'
              type='expiry'
              label='Expiration date'
              placeholder='MM/YY'
              disabled={disabled}
              event={this.state.event}
              checkout={this.checkout}
              onValidate={this.onValidate}
              allowedCardTypes={allowedCardTypes}
            />
          </div>
          <div className='flex flex-col w-49p'>
            <CardFieldInput
              field='card-cvv'
              type='cvv'
              label='Security Code'
              placeholder='CVV'
              disabled={disabled}
              event={this.state.event}
              checkout={this.checkout}
              onValidate={this.onValidate}
              allowedCardTypes={allowedCardTypes}
            >
              <Tooltip allowedCardTypes={allowedCardTypes} />
            </CardFieldInput>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  analyticAction,
};

export default compose(
  withFeatureFlags,
  connect(null, mapDispatchToProps),
)(CardDetailsInput);
