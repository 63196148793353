import React from 'react';
import jsCookie from 'js-cookie';
import H3 from '../ui-kit/Typography/H3';
import Button from '../ui-kit/Button';
import {
  CHECKOUT_INITIATED_FROM_COOKIE_NAME,
  CHECKOUT_INITIATED_FROM_COOKIE_VALUES,
} from 'utils/cookies';

export const renderSubscribe = (styles, history, location) => {
  return (
    <div className={`flex justify-center items-center w-full py-80 ${styles}`}>
      <div className='flex flex-col max-w-830 xs:items-center'>
        <H3 className='block text-center text-xl sm:text-2xl lg:text-3xl leading-36 lg:leading-48'>
          Subscribe to Optus Sport to access this competition
        </H3>
        <Button
          type='submit'
          disabled={false}
          handler={() => {
            jsCookie.set(
              CHECKOUT_INITIATED_FROM_COOKIE_NAME,
              CHECKOUT_INITIATED_FROM_COOKIE_VALUES.USER_COMPETITION,
            );
            history.push('/checkout', {
              referrer: location.pathname,
            });
          }}
          className='my-10'
          text='Subscribe Now'
          width='w-full xs:w-296'
        />
      </div>
    </div>
  );
};
