import React from 'react';

import Button from './Button';

const PrimaryButtonClassNames = 'bg-teal text-black hover:bg-teal-light';
const SecondaryButtonClassNames =
  'border-solid border-white border-2 bg-transparent text-white hover:border-light-indicator-grey hover:text-light-indicator-grey hover:bg-transparent';

function ContentCardButtonLayout({
  contentCard,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) {
  const { bannerType, primaryButtonLabel, secondaryButtonLabel } = contentCard;

  switch (bannerType) {
    case 'ONE_BUTTON':
      return (
        <div className='md:flex w-full'>
          <div className='md:w-1/2 md:pr-8'>
            <div className='pb-16 md:pb-0 '>
              <Button
                label={primaryButtonLabel}
                className={PrimaryButtonClassNames}
                onClick={onPrimaryButtonClick}
              />
            </div>
          </div>
        </div>
      );
    case 'TWO_BUTTONS':
      return (
        <div className='sm:flex w-full'>
          <div className='sm:w-1/2 sm:pr-8'>
            <div className='pb-16 sm:pb-0 '>
              <Button
                label={primaryButtonLabel}
                className={PrimaryButtonClassNames}
                onClick={onPrimaryButtonClick}
              />
            </div>
          </div>
          <div className='sm:w-1/2 sm:pl-8'>
            <div className='pb-0'>
              <Button
                label={secondaryButtonLabel}
                className={SecondaryButtonClassNames}
                onClick={onSecondaryButtonClick}
              />
            </div>
          </div>
        </div>
      );
    default:
      return <></>;
  }
}

export default ContentCardButtonLayout;
