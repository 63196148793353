import React, { useContext } from 'react';

import { withFeatureFlags } from 'components/context/withFeatureFlags';
import SignupContext from './SignupContext';
import { getAbsoluteStaticUrl } from 'utils/path';
import {
  setOGTitle,
  setOGImage
} from 'utils/openGraph';

const SeoSection = ({ getFeatureFlags }) => {
  const signupContext = useContext(SignupContext);
  const currentTheme = signupContext.theme;

  const { seoSection = {} } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);
  if (!seoSection[currentTheme]) {
    return null;
  }

  const {
    image,
    title,
  } = seoSection[currentTheme];

  return (
    <>
      {image && setOGImage(getAbsoluteStaticUrl(image))}
      {title && setOGTitle(title)}
    </>
  );
};
export default withFeatureFlags(SeoSection);
