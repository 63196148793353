import React, { useEffect, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import SectionLoader from '../../ui-kit/SectionLoader';
import { selectors } from '../../../../store/createPortalStore';
import * as bannerAssetAction from '../../../../actions/bannerAsset';
import BannerUI from './BannerUI';
import {
  isValidContentCard,
  getActionHandlers,
  getImageProps,
  isBannerDataValid,
} from '../utils';
import {
  ContentCardProvider,
  ContentCardDispatchContext,
  ContentCardContext,
} from '../ContentCardContext';
import useContentCard from 'utils/hooks/useContentCard';

function Banner({ asset, ...props }) {
  const pages = useSelector((state) => state.pages);
  const { navigations } = useSelector((state) => state.navigation) || {};

  const dispatch = useContext(ContentCardDispatchContext);
  const contentCardContext = useContext(ContentCardContext);

  useEffect(() => {
    if (asset) {
      dispatch({
        type: 'assetReady',
        asset: { ...asset },
      });
    }
  }, [asset, dispatch]);

  const assetContext = contentCardContext?.asset;
  if (!assetContext) return <></>;
  const actionHandlers = getActionHandlers({
    dispatch,
    pages,
    editorial: false, // editorial
    asset: assetContext,
    navigations,
    type: 'banner',
  });

  const { defaultImageAspectRatio } = props;
  const { imgSrc, srcSet } = getImageProps(
    assetContext,
    defaultImageAspectRatio,
  );

  return (
    <BannerUI
      asset={assetContext}
      actionHandlers={actionHandlers}
      {...props}
      imgSrc={imgSrc}
      srcSet={srcSet}
    />
  );
}

/**
 * BannerWithContext
 *
 * @param {string} assetId
 * @param {string} defaultImageAspectRatio = '1:1', '9:16', '16:9', '11:4' | undefined
 *
 */
function BannerWithContext({
  assetId,
  userTags,
  actionSetBannerAssetSuccess,
  contentCardsInObj: { isReady: isContentCardsReady, data: contentCardsInObj },
  isDynamic,
  ...props
}) {
  const contentCardAsset = useContentCard(assetId, isDynamic);
  const { contentCard } = contentCardAsset || {};

  useEffect(() => {
    if (contentCardAsset) {
      actionSetBannerAssetSuccess(contentCardAsset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentCardAsset]);

  if (!userTags || !isContentCardsReady) return <SectionLoader />;

  if (
    !contentCardAsset ||
    // full valdiation for non Dynamic content card
    (!isDynamic &&
      !isValidContentCard(contentCardAsset, userTags, contentCardsInObj)) ||
    // type, action validation for Dynamic content card
    (isDynamic && !isBannerDataValid(contentCard))
  )
    return <></>;

  return (
    <ContentCardProvider>
      <Banner key={assetId} asset={contentCardAsset} {...props} />
    </ContentCardProvider>
  );
}

function mapStateToProps(state) {
  const contentCardsInObj = selectors.contentCards.getContentCardsInObj(state);

  return {
    userTags: selectors.user.getUserTags(state),
    contentCardsInObj,
  };
}

function mapDispatchToProps(dispatch) {
  const bannerAssetActions = bindActionCreators(bannerAssetAction, dispatch);
  const actionSetBannerAssetSuccess =
    bannerAssetActions?.setBannerAssetSuccess || (() => {});
  return {
    actionSetBannerAssetSuccess,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerWithContext);
