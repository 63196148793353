import React, { useContext } from 'react';

import Button from 'components/ui/ui-kit/Button';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import { getAbsoluteStaticUrl } from '../../../utils/path';
import SignupContext from './SignupContext';

const PageThemeSection = ({ pageName, sectionName, getFeatureFlags }) => {
  const signupContext = useContext(SignupContext);
  const currentTheme = pageName || signupContext.theme;

  const { pageThemeSection } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);
  if (
    !pageThemeSection[currentTheme] ||
    !pageThemeSection[currentTheme][sectionName]?.showSection
  ) {
    return null;
  }

  const {
    headingText,
    subText,
    showButton,
    buttonText,
    buttonUrl,
    buttonTarget,
    mobileImageUrl,
    tabletImageUrl,
    desktopImageUrl,
    className = '',
  } = pageThemeSection[currentTheme][sectionName];

  return (
    <section
      className={`
        w-full
        flex
        xs:h-full
        sm:h-428
        lg:h-457
        pb-32 sm:pb-48 lg:pb-160
        px-0 sm:px-32 lg:px-120
        justify-center
        sm:justify-end
        lg:justify-center
        ${className}
        relative lg:initial
      `}
    >
      <div className='flex items-center justify-start page-theme-container lg:relative'>
        <div
          className={`
            flex flex-col
            pl-0 sm:pl-32 lg:pl-120
            pr-16 sm:pr-0
            max-w-428 lg:max-w-500
            page-theme-content
          `}
        >
          <h1
            className={`
              font-MarkProHeavy
              font-900
              text-xl
              mb-8
              leading-none
              sm:text-2xl
              lg:text-3xl
            `}
          >
            {headingText}
          </h1>
          {subText && (
            <p
              className={`
            font-MarkPro
            mb-16
            sm:mb-0
            leading-normal
            text-base
            lg:text-lg
            `}
              dangerouslySetInnerHTML={{ __html: subText }}
            ></p>
          )}
          {showButton && (
            <Button
              type='primary-alternate'
              text={buttonText}
              className={`flex items-center pr-16 mb-16 sm:mb-0 w-full`}
              buttonClassName='min-h-48 xs:min-w-320 mt-16 w-full sm:w-auto'
              theme='light'
              href={buttonUrl}
              handler={() => {
                window.open(buttonUrl, buttonTarget || '_self');
              }}
            ></Button>
          )}
        </div>
        <div className='relative overflow-hidden sm:rounded-16px'>
          <div className='gradient-background'></div>

          <picture className='block rounded-0 sm:rounded-16px page-theme-image'>
            <source
              media={`(max-width:430px)`}
              srcSet={getAbsoluteStaticUrl(mobileImageUrl)}
            />
            <source
              media={`(max-width:991px)`}
              srcSet={getAbsoluteStaticUrl(tabletImageUrl)}
            />
            <source
              media={`(min-width:991px)`}
              srcSet={getAbsoluteStaticUrl(desktopImageUrl)}
            />
            <img
              src={getAbsoluteStaticUrl(desktopImageUrl)}
              alt={''}
              className={'relative rounded-0 sm:rounded-16px h-full w-full'}
            />
          </picture>
        </div>
      </div>
    </section>
  );
};
export default withFeatureFlags(PageThemeSection);
