import _ from 'lodash';
import { bindActionCreators } from 'redux';
import {
  getAssetEpg,
  getChannelEpg
} from '../../actions/asset';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  // Perform any processing here from store and return to component

  return _.merge(
    {},
    {
      'assetId':  _.get(state, ['asset', 'data', 'id']),
      'channelId': _.get(state, ['asset', 'data', 'channel', 'id'],
        _.get(ownProps, 'channelId')),
      'epg': _.get(state, ['asset', 'epg']),
      'readyEpg': _.get(state, ['asset', 'readyEpg'])
    }
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actionGetAssetEpg: bindActionCreators(getAssetEpg, dispatch),
    actionGetChannelEpg: bindActionCreators(getChannelEpg, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
