import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find, forEach, partition, isEmpty } from 'lodash';
import AutoSuggestList from '../AutoSuggestList';
import { getFilteredTeams } from 'actions/teams';
import FieldValidIcon from '../Icons/FieldValidIcon';

const applySort = (items, sortBy) => {
  if (!isEmpty(sortBy) && !isEmpty(items)) {
    const finalItemsList = [];
    let remainingItems = items;
    forEach(sortBy, (value) => {
      const [sorted, rest] = partition(
        remainingItems,
        (item) => !!find(item.competitions, { code: value }),
      );

      if (sorted.length) {
        finalItemsList.push(...sorted);
      }
      remainingItems = rest;
    });
    finalItemsList.push(...remainingItems);
    return finalItemsList;
  }
  return items;
};

const applyFilter = (items, filterBy) => {
  if (!isEmpty(filterBy) && !isEmpty(items)) {
    return items.filter((item) =>
      find(item.competitions, (competition) =>
        filterBy.includes(competition.code),
      ),
    );
  }
  return items;
};

const TeamSelect = ({
  label,
  className,
  placeholder = '',
  containerClass = '',
  sortBy = [],
  filterBy = [],
  onChange,
}) => {
  const dispatch = useDispatch();
  const navigations = useSelector((state) => state.navigation?.navigations);
  const filteredTeamsList = useSelector(
    (state) => state?.teams?.filteredTeams || [],
  );
  const [finalTeamsList, setFinalTeamsList] = useState([]);

  useEffect(() => {
    if (!isEmpty(navigations)) {
      let allCompetitionIds = new Set();
      navigations.map((nav) => {
        if (nav.opta || nav.optaItems) {
          const optaCompetition = nav.opta.competitionId;
          const optaItemsCompetitions =
            nav.optaItems?.map((o) => o.competitionId) || [];
          allCompetitionIds = new Set([
            ...allCompetitionIds,
            optaCompetition,
            ...optaItemsCompetitions,
          ]);
        }
        return null;
      });

      dispatch(getFilteredTeams(allCompetitionIds));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmpty(filteredTeamsList)) {
      let finalTeamsList = filteredTeamsList;
      if (!isEmpty(filterBy)) {
        finalTeamsList = applyFilter(finalTeamsList, filterBy);
      }

      if (!isEmpty(sortBy)) {
        finalTeamsList = applySort(finalTeamsList, sortBy);
      }

      setFinalTeamsList(finalTeamsList);
    }
  }, [filteredTeamsList, filterBy, sortBy]);

  const renderRowFn = (team, selectedTeam) => (
    <div className='flex w-full justify-between'>
      <div className='flex items-center'>
        <img
          className='object-contain w-28 h-28 mr-10'
          src={team?.logoUrl}
          alt={team?.officialName || ''}
        />
        <span className=''>{team?.officialName}</span>
      </div>
      {!isEmpty(selectedTeam) && team?.TeamID === selectedTeam?.TeamID && (
        <FieldValidIcon viewBox='0 2 28 28' />
      )}
    </div>
  );

  const getDisplayDetails = (selectedItem) => {
    return {
      icon: selectedItem.logoUrl,
      text: selectedItem.officialName,
    };
  };

  return (
    <AutoSuggestList
      label={label}
      className={className}
      placeholder={placeholder}
      containerClass={containerClass}
      items={finalTeamsList}
      onChange={onChange}
      renderRowFn={renderRowFn}
      getDisplayDetails={getDisplayDetails}
    />
  );
};

export default TeamSelect;
