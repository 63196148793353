import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  width: 100%;
  position: relative;
  border-radius: 1rem;
  padding-left: 1rem;
  height: 100%;
  border-width: 1px;
  padding-right: 1rem;
  ${(props) => {
    const {
      borderColor,
      bgColor,
      disabled,
      theme: { palette },
    } = props;
    return css`
      border-color: ${borderColor
        ? palette?.[borderColor || 'medium-dark-grey']
        : palette?.['medium-dark-grey']};
      background-color: ${bgColor
        ? palette?.[bgColor || 'medium-dark-grey']
        : palette?.['medium-dark-grey']};
      ${disabled &&
      css`
          border-color: ${palette?.['light-grey']};
        `}
    `;
  }}
`;

const Paper = ({ style, borderColor, bgColor, children, ...props }) => (
  <Container
    style={style}
    borderColor={borderColor}
    bgColor={bgColor}
    {...props}
  >
    {children}
  </Container>
);

export default Paper;
