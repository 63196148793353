import React from 'react';
import CardDetailsInput from './CardDetailsInput';
import Button from './ui-kit/Button';
import ActionModal from './ActionModal';

class PaymentDetailsInput extends React.Component {
  state = {};

  onToken = token => {
    if (!this.state.inProgress) {
      return;
    }
    this.setState({ inProgress: false });
    if (this.props.onToken) {
      this.props.onToken(token);
    }
  };

  render() {
    const { onClose, details } = this.props;
    const title = details ? 'Update Payment Details' : 'Add Payment Details';
    return (
      <ActionModal
        onBackClicked={onClose}
        title={title}
        content={
          <>
            <CardDetailsInput
              onValidate={valid => this.setState({ canUpdate: valid })}
              details={details}
              getToken={this.state.inProgress}
              onToken={this.onToken}
            />
          </>
        }
        renderActions={
          <>
            <Button
              type="primary"
              className="mb-16 flex justify-center w-full"
              width="w-full"
              text="Update"
              disabled={!this.state.canUpdate}
              handler={() => this.setState({ inProgress: true })}
              rounded
            />
          </>
        }
      />
    );
  }
}

export default PaymentDetailsInput;
