import React from 'react';
import SuccessIcon from '../ui-kit/Icons/SuccessIcon';
import H3 from '../ui-kit/Typography/H3';

const renderSuccess = (styles) => {
  return (
    <div className={`flex justify-center items-center w-full py-80 ${styles}`}>
      <div className='flex flex-col max-w-701 items-center'>
        <SuccessIcon className='block m-auto text-center h-96 w-96' />
        <H3
          className='block text-center md:mb-32 mt-32 lg:text-3xl'
          marginY='mb-16'
        >
          You have successfully entered the competition
        </H3>
        {/* <p className='w-full xs:w-369 leading-normal text-center'>
          Congrats you have X number of entries
        </p> */}
      </div>
    </div>
  );
};

export default renderSuccess;
