import React from 'react';
import useContentCard from 'utils/hooks/useContentCard';

import useDynamicContentCard from 'utils/hooks/useDynamicContentCard';

function DynamicContentCard({
  contentCardLocationId,
  contentCardUiComponent: UIComponent,
  ...props
}) {
  const assetId = useDynamicContentCard(contentCardLocationId);
  const isDynamic = true;
  const contentCard = useContentCard(assetId, isDynamic);
  if (!contentCard) {
    return <></>;
  }

  return <UIComponent assetId={assetId} {...props} isDynamic={isDynamic} />;
}

export default React.memo(DynamicContentCard);
