import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import 'react-day-picker/lib/style.css';
import 'react-phone-number-input/style.css';
import { usePrevious } from '../../../utils/hooks';
import EnterVoucher from './EnterVoucher';
import Email from '../Email';
import CreateAccount from '../CreateAccount';
import Congratulations from './Congratulations';
import LoginForm from '../LoginForm';
import VoucherFormContainer from './VoucherFormContainer';
import voucherConfig from '../../../constants/voucherConfig';
import { get, isEmpty } from 'lodash';
import VoucherTermsConditions from './VoucherTermsConditions';
import qs from 'qs';
import {
  initializeGoogleAnalytics,
  triggerGoogleAnalyticsEvent,
} from '../../../utils';
import VerifyPhoneNumberForm from '../VerifyPhoneNumberForm';
import {
  VOUCHER_STAGE,
  getVoucherSequence,
  getVoucherStages,
} from '../../../ducks/voucher';
import { isLoggedIn } from '../../../ducks/user';
import {
  formStart,
  completeCurrentStage,
  isCurrentStageRemoved,
  isLastStage,
  setCurrentSequence,
} from '../../../ducks/stepper';
import LoadingIcon from '../ui-kit/Icons/LoadingIcon';
import Message from '../ui-kit/Message';
import Label from '../ui-kit/Label';
import { dispatchAnalytic } from 'ducks/analytic';

const VoucherForm = (props) => {
  // Hooks
  const dispatch = useDispatch();
  const prevProps = usePrevious(props);
  // Props
  const {
    user,
    voucher,
    stepper,
    title,
    location,
    initObserver,
    couponCode,
    loggedIn,
    className,
    inProgress: inProgressProp,
    actionSubscriptionVoucher,
    loginFailure,
    actionUnsetLoginUser,
    actionToggleAuthInProgress,
    subscriptionVoucher,
    subscriptionUserCreate,
    isUserGetDetailsFinished,
    getFeatureFlags,
    autoFocusForm = true,
  } = props;

  // Props Destructure
  const {
    applied: voucherApplied,
    error: voucherError,
    createUserByVoucher: voucherCreateUser,
  } = voucher;
  const { currentStage: stepperCurrentStage, done: stepperDone } = stepper;
  // Local State
  const [loginStep, setLoginStep] = useState(false);
  const [inProgressLocal, setInProgressLocal] = useState(false);

  const getVoucherCodeParam = () => {
    const { search } = location;
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    return get(query, 'v', null);
  };

  const shouldLogin = (prevProps) => {
    const { validateEmail: currValidateEmail } = props || {};
    const { validateEmail: prevValidateEmail } = prevProps || {};
    const { inProgress, isEmailRegistered } = currValidateEmail || {};
    const isEmailValidationDone = prevValidateEmail?.inProgress && !inProgress;
    return isEmailValidationDone && isEmailRegistered;
  };

  const shouldApplyVoucher = () => {
    return stepperDone && !voucherApplied && !voucherError;
  };

  const getSequenceKey = () => {
    return isLoggedIn(user) ? `LOGGED_IN` : `LOGGED_OUT`;
  };

  const isUserLoggedIn = isLoggedIn(user);
  const hasTransitionedToLoggedIn =
    !isLoggedIn(prevProps?.user) && isUserLoggedIn;

  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  useEffect(() => {
    if (!stepperCurrentStage) {
      dispatch(
        formStart({
          currentSequence: getSequenceKey(),
          stages: getVoucherStages(getFeatureFlags),
          sequence: getVoucherSequence(getFeatureFlags),
        }),
      );
      return;
    }
    if (shouldLogin(prevProps)) {
      setLoginStep(true);
      return;
    }

    if (hasTransitionedToLoggedIn) {
      dispatch(setCurrentSequence({ currentSequence: getSequenceKey() }));
      return;
    }
    // Handle case for login and the current stage is gone and no next stage.
    // As the current stage is gone, completeCurrentStage needs to be invoked here.
    if (
      isUserLoggedIn &&
      isCurrentStageRemoved(stepper) &&
      isLastStage(stepper)
    ) {
      dispatch(completeCurrentStage());
      return;
    }

    if (!inProgressLocal && isUserLoggedIn && shouldApplyVoucher()) {
      const userId = get(user, 'authData.cognitoUser.attributes.sub');
      const voucherCode = get(
        props,
        'voucher.voucherCode',
        get(props, 'subscriptionVoucher.voucherData.voucherCode'),
      );
      setInProgressLocal(true);
      dispatch(actionSubscriptionVoucher(userId, voucherCode, true));
      return;
    }

    if (inProgressLocal && (voucherApplied || voucherError)) {
      if (voucherApplied && !isUserGetDetailsFinished) {
        // We need to wait for get user details to finish before completing
        return;
      }
      setInProgressLocal(false);
      dispatch(completeCurrentStage());
    }

    // eslint-disable-next-line
  }, [
    stepperCurrentStage,
    shouldApplyVoucher,
    isUserLoggedIn,
    isUserGetDetailsFinished,
  ]);

  const renderLoggedInInfo = () => {
    const userEmail = get(user, 'authData.cognitoUser.attributes.email');
    return loggedIn ? (
      <div className='w-full h-48 mb-16 minus8 text-center text-xs leading-loose text-left md:w-736 md:h-16 xl:mt-minus32'>
        You are logged in as
        <span style={{ fontWeight: 'bold' }}>{` ${userEmail}. `}</span>
        Validate your code to check whether you are eligible for this offer.
      </div>
    ) : (
      <></>
    );
  };

  const renderLandingPageText = () => {
    const { landingPageText } = voucherConfig || {};
    return (
      <h1 className='w-full font-MarkProHeavy text-left text-lg text-center leading-tight mb-24 text-left md:mb-24 md:mx-0 md:text-2xl md:leading-none xl:mb-64 xl:text-4xl'>
        {title || landingPageText}
      </h1>
    );
  };

  const renderErrorMessage = () => {
    const { inProgress = false, isSuccess = false, errorData = {} } = get(
      user,
      `validateEmail`,
      {},
    );
    if (!inProgress && !isSuccess && !isEmpty(errorData)) {
      const linkCss = `font-MarkPro w-fit-content text-teal hover:text-teal-dark active:text-teal-light active:no-underline os-transition os-transitionproperty-all`;
      return (
        <div className='my-32'>
          <Message
            type='failure'
            messageHtml={`Sorry, something went wrong. Please try again later, or if the issue persists please refer to <a class='${linkCss}' href='https://www.optus.com.au/for-you/support/answer?id=7019&utm_source=OSWeb-Signup-Error' target='_blank'>this help article</a>.`}
            open
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderVoucherForm = () => (
    <div className='max-w-753 w-full mx-16 mb-48 md:items-center md:px-16 xl:w-794 xl:px-0'>
      {renderLandingPageText()}
      {renderLoggedInInfo()}
      {renderErrorMessage()}
      <EnterVoucher
        stage={VOUCHER_STAGE.ENTER_CODE}
        voucherCodeParam={getVoucherCodeParam() || couponCode}
        autoFocusForm={autoFocusForm}
      />
      <VoucherTermsConditions
        stage={VOUCHER_STAGE.ACCEPT_TERMS}
        onLoad={() =>
          triggerGoogleAnalyticsEvent('voucher', 'step-terms', 'load')
        }
        onSubmit={({ checked }) =>
          triggerGoogleAnalyticsEvent(
            `voucher`,
            `step-terms`,
            checked ? `submit` : `error(terms)`,
          )
        }
      />
      <Email
        stage={VOUCHER_STAGE.ENTER_EMAIL}
        hideEditEmailLink={false}
        label='Enter your email'
        renderInfo={() => (
          <div className='md:w-353'>
            <Label textSize='text-xs leading-loose font-MarkProBold text-light-grey md:w-360 xl:w-381 align-left'>
              You can apply the code to an active Optus Sport account by using
              your current account email.
            </Label>
          </div>
        )}
        onLoad={() =>
          triggerGoogleAnalyticsEvent('voucher', 'step-email', 'load')
        }
        onSubmit={({ email }) => {
          triggerGoogleAnalyticsEvent(
            'voucher',
            'step-email',
            email ? 'submit' : 'error(invalid_email)',
          );
          email && dispatchAnalytic({ name: 'Signup Email Submitted' });
        }}
        showConfirmEmail
      />
      <VerifyPhoneNumberForm
        label='Verify your mobile number'
        stage={VOUCHER_STAGE.VERIFY_MOBILE}
        onLoad={() =>
          triggerGoogleAnalyticsEvent('voucher', 'step-mobile', 'load')
        }
        onSubmit={() =>
          triggerGoogleAnalyticsEvent('voucher', 'step-mobile', 'submit')
        }
        getFeatureFlags={getFeatureFlags}
      />
      <CreateAccount
        label='Create your account'
        stage={VOUCHER_STAGE.CREATE_ACCOUNT}
        onLoad={() =>
          triggerGoogleAnalyticsEvent('voucher', 'step-createaccount', 'load')
        }
        onInvalidPassword={() =>
          triggerGoogleAnalyticsEvent(
            'voucher',
            'step-createaccount',
            'error(password)',
          )
        }
        onSubmit={({
          allFieldsValid,
          validPassword,
          validPhoneNumber,
          checked,
        }) => {
          if (!allFieldsValid) {
            triggerGoogleAnalyticsEvent(
              'voucher',
              'step-createaccount',
              'error(mandatory_fields)',
            );
          }
          if (!validPassword) {
            triggerGoogleAnalyticsEvent(
              'voucher',
              'step-createaccount',
              'error(password)',
            );
          }
          if (!validPhoneNumber) {
            triggerGoogleAnalyticsEvent(
              'voucher',
              'step-createaccount',
              'error(phone)',
            );
          }
          if (!checked) {
            triggerGoogleAnalyticsEvent(
              'voucher',
              'step-createaccount',
              'error(terms)',
            );
          }
          triggerGoogleAnalyticsEvent(
            'voucher',
            'step-createaccount',
            'submit',
          );
          validPassword &&
            dispatchAnalytic({ name: 'Signup Password Submitted' });

          allFieldsValid &&
            dispatchAnalytic({
              name: 'Signup Personal Details Submitted',
            });
        }}
        showFields={[
          `firstName`,
          `lastName`,
          `phoneNumber`,
          `email`,
          `dateOfBirth`,
          `postcode`,
          `password`,
        ]}
      />
    </div>
  );

  const renderLoginForm = () => {
    const {
      data: { email },
    } = stepper;
    return (
      <LoginForm
        initObserver={initObserver}
        loggedIn={loggedIn}
        inProgress={inProgressProp}
        loginFailure={loginFailure}
        loginUser={bindActionCreators(actionUnsetLoginUser, dispatch)}
        toggleAuthInProgress={bindActionCreators(
          actionToggleAuthInProgress,
          dispatch,
        )}
        email={email}
        bannerMessage={() => (
          <Message
            type='warning'
            className='mt-8 mb-16'
            message='You already have an account. Log in to redeem your voucher.'
            open={true}
          />
        )}
        showSignup={false}
        disableEmail
        onMount={() => triggerGoogleAnalyticsEvent('voucher', 'login', 'load')}
        onSubmit={() =>
          triggerGoogleAnalyticsEvent('voucher', 'login', 'submit')
        }
        onError={() =>
          triggerGoogleAnalyticsEvent('voucher', 'login', 'error(password)')
        }
      />
    );
  };

  const renderCongratulations = () => (
    <Congratulations
      subscriptionVoucher={subscriptionVoucher}
      subscriptionUserCreate={subscriptionUserCreate}
    />
  );

  const renderLoading = () => (
    <div className='page mt-80 mx-auto px-14 w-269 xs:w-350 md:mt-144 md:w-369 lg:w-381'>
      <LoadingIcon className='block m-auto text-center lg:h-64 lg:w-64' />
    </div>
  );

  const shouldRenderCongratulations = () => {
    const isDoneAndApplied = stepperDone && (voucherApplied || voucherError);
    return isDoneAndApplied || voucherCreateUser;
  };

  const getRenderFunction = () => {
    if (loginStep && !isLoggedIn(user)) {
      return renderLoginForm();
    }

    if (inProgressLocal) {
      return renderLoading();
    }

    if (shouldRenderCongratulations()) {
      return renderCongratulations();
    }

    return renderVoucherForm();
  };

  return (
    <VoucherFormContainer
      classNames={className || `min-h-page mt-32 md:mt-40 xl:mt-96`}
      showHeader={props.showHeader}
    >
      {getRenderFunction()}
    </VoucherFormContainer>
  );
};

export default VoucherForm;
