
const voucherConfig = {
  landingPageText: ' Redeem your access code',
  content: `
    <p>
      <strong>Terms & Conditions:</strong>
    </p>
    <p>
      Enter your unique access code by the date displayed on your voucher to redeem your offer.
    </p>
    <p>
      Eligibility criteria may apply as per your voucher. Limit one per customer. Subject to your rights under the Australian Consumer Law, this voucher cannot be redeemed for cash or resold or otherwise exchanged.
    </p>
    <p>
      Compatible device required. Personal viewing in Australia only. Content and features may change. Streaming quality dependent on your device, service provider and internet connection (including bandwidth). Fair Go Policy applies. For full Optus Sport T&Cs, visit <a href='/terms' target='_blank' class='os-transition-btn font-MarkProBold text-teal hover:text-teal-dark active:text-teal-light no-underline' rel='noopener noreferrer'>sport.optus.com.au/terms</a>
    </p>
    `,
  required: true,
  checkBoxText: 'I accept the Access Code T&Cs',
  successTitle: 'Welcome',
  successContent: 'You have successfully redeemed your voucher and your Optus Sport account is now active and ready to go.',
  errorContent: 'This offer is available for new accounts only.',
  successPageBackgroundImage: '',
  successPageMainImage1X: '',
  successPageMainImage2X: ''
};

export default voucherConfig;
