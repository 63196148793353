import React from 'react';
import Tab from './Tab';
import styles from './styles';

class Tabs extends React.Component {
  state = {
    activeIndex: 0,
  };

  isActive = (idx) => {
    return this.state.activeIndex === idx;
  };

  onClick = (idx, name) => () => {
    this.setState({ activeIndex: idx });
    const { onClick } = this.props;
    if (onClick) {
      onClick(name);
    }
  };

  renderTabLabels = () => {
    const { children, theme = 'dark' } = this.props;
    const labelStyles = (active) => `${
      active ? `${styles[theme]?.title} border-b-4` : 'border-transparent'
    } align-bottom inline-block os-transition no-underline
    hover:${styles[theme]?.title} ${
      styles[theme]?.classes
    } border-b-4 hover:text-teal text-sm leading-1.14 text-center pb-12 pt-16 px-16`;
    const newChildren = Array.isArray(children) ? children : [children];
    return newChildren.map((child, idx) => {
      const { name, label } = child.props;
      const active = this.isActive(idx);
      return (
        <div
          key={`tab-label-${idx}-${name}`}
          className={styles[theme]?.tabStyles}
          onClick={this.onClick(idx, name)}
        >
          <span className={labelStyles(active)}>{label}</span>
        </div>
      );
    });
  };

  renderActive = () => {
    const { children } = this.props;
    const newChildren = Array.isArray(children) ? children : [children];
    const [child] = newChildren.filter((child, idx) => this.isActive(idx));
    return child;
  };

  hasInvalidChild = () => {
    const { children } = this.props;
    const newChildren = Array.isArray(children) ? children : [children];
    const [invalidChild] = newChildren.filter((child) => child.type !== Tab);
    return invalidChild;
  };

  render() {
    if (this.hasInvalidChild()) {
      throw new Error('Tabs must only have children of Tab type.');
    }
    const { className = ``, theme = 'dark' } = this.props;
    return (
      <div className={`w-full flex flex-col ${className}`}>
        <div className={`flex border-b ${styles[theme]?.title}`}>
          {this.renderTabLabels()}
        </div>
        <div className='flex py-16'>{this.renderActive()}</div>
      </div>
    );
  }
}

export default Tabs;
