import React, { useEffect, useRef, useState } from 'react';
import { selectors, useSelector } from '../../../store/createPortalStore';
import { useDispatch } from 'react-redux';
import { usePrevious } from '../../../utils/hooks';
import FormSection from '../../ui/ui-kit/FormSection';
import Input from '../../ui/ui-kit/Input';
import Button from '../../ui/ui-kit/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import defaultConfig from '../../../constants/defaultConfig';
import _ from 'lodash';
import Message from '../../ui/ui-kit/Message';
import { triggerGoogleAnalyticsEvent } from '../../../utils';
import {
  completeCurrentStage,
  isCurrentStage,
  getFormSectionProps,
} from '../../../ducks/stepper';
import { subscriptionVoucher } from '../../../actions/user';
import { saveVoucherCode } from '../../../ducks/voucher';

const EnterVoucher = (props) => {
  // Destructuring
  const {
    user: userSelector,
    stepper: stepperSelector,
    voucher: voucherSelector,
  } = selectors;
  // Selectors
  const user = useSelector(userSelector.user);
  const voucher = useSelector(voucherSelector.voucher);
  const stepper = useSelector(stepperSelector.stepper);
  // Props
  const { voucherCodeParam, stage, autoFocusForm } = props;
  // Local state
  const [state, setState] = useState({
    recaptchaVerified: false,
    voucherCode: voucherCodeParam || '',
    validVoucher: false,
    backEndValidated: false,
    showFailure: false,
    errorMessage: null,
  });
  const [inProgress, setInProgress] = useState(false);
  // Hooks
  const dispatch = useDispatch();
  const voucherCaptcha = useRef(null);
  const prevInProgress = usePrevious(inProgress);

  useEffect(() => {
    triggerGoogleAnalyticsEvent('voucher', 'step-entercode', 'load');
  }, []);

  const shouldCompleteStage = () => {
    const isSuccess = _.get(user, 'subscriptionVoucher.isSuccess'); // TODO: Remove dependency. Use voucher state
    const voucherData = _.get(user, 'subscriptionVoucher.voucherData'); // TODO: Remove dependency. Use voucher state
    const isValid = _.get(voucherData, 'eligibility');
    return inProgress && isCurrentStage(stepper, stage) && isSuccess && isValid;
  };

  const shouldShowError = () => {
    const { error } = voucher;
    return inProgress && isCurrentStage(stepper, stage) && error;
  };

  const getErrorCode = () => {
    const voucherData = _.get(user, 'subscriptionVoucher.voucherData'); // TODO: Remove dependency. Use voucher state
    const { reason } = voucherData;
    if (['expired', 'exhausted', 'invalid'].includes(reason)) {
      return `${reason}_voucher`;
    }
    return `ineligible`;
  };

  const getErrorMessage = () => {
    const isSuccess = _.get(user, 'subscriptionVoucher.isSuccess'); // TODO: Remove dependency. Use voucher state
    const voucherData = _.get(user, 'subscriptionVoucher.voucherData'); // TODO: Remove dependency. Use voucher state
    return isSuccess
      ? _.get(voucherData, 'errorDialog.content')
      : _.get(
          user,
          'subscriptionVoucher.voucherData.error.errors[0].message',
          'Code was not found',
        );
  };

  useEffect(() => {
    if (!prevInProgress && inProgress) {
      setState((state) => ({ ...state, showFailure: false }));
      serverSideVoucherValidator();
      return;
    }

    if (shouldCompleteStage()) {
      setState((state) => ({
        ...state,
        showFailure: false,
        errorMessage: ``,
      }));
      setInProgress(false);
      dispatch(completeCurrentStage());
      return;
    }
    if (shouldShowError()) {
      // TODO: Refactor error handling
      const errorMessage = getErrorMessage();
      const errorCode = getErrorCode();
      triggerGoogleAnalyticsEvent(
        'voucher',
        'step-entercode',
        `error(${errorCode})`,
      );
      setState((state) => ({
        ...state,
        showFailure: true,
        errorMessage,
      }));
      setInProgress(false);
      return;
    }
    // eslint-disable-next-line
  }, [inProgress, prevInProgress, voucher, stepper, stage]);

  const serverSideVoucherValidator = () => {
    const userId = _.get(user, 'authData.cognitoUser.attributes.sub');
    const voucherData = _.get(user, 'subscriptionVoucher.voucherData');
    const voucherCode =
      _.get(state, 'voucherCode') || _.get(voucherData, 'voucherCode', null);
    triggerGoogleAnalyticsEvent('voucher', 'step-entercode', 'submit');
    dispatch(saveVoucherCode(voucherCode));
    dispatch(subscriptionVoucher(userId, voucherCode));
  };

  const voucherCodeChange = (value) => {
    setState((state) => ({ ...state, voucherCode: value }));
  };

  const validateVoucher = (voucher) => {
    const validVoucherReg = /^[a-z,A-Z,0-9,-]{5,20}$/;
    const validVoucher = !_.isEmpty(voucher) && validVoucherReg.test(voucher);
    setState((state) => ({ ...state, validVoucher }));
    return validVoucher;
  };

  const verifyCallback = () => {
    setState((state) => ({ ...state, recaptchaVerified: true }));
  };

  const onRecaptchaExpired = () => {
    setState((state) => ({ ...state, recaptchaVerified: false }));
  };

  const changeHandler = (value) => value && value.length > 5;

  const onRecaptchaError = (e) => {
    console.log('reCaptcha error:', e);
    triggerGoogleAnalyticsEvent('voucher', 'step-entercode', 'error(captcha)');
  };

  const {
    recaptchaVerified,
    validVoucher,
    showFailure,
    subscriptionUserInProgress,
    errorMessage,
  } = state;

  const voucherData = _.get(user, 'subscriptionVoucher.voucherData');
  const voucherCode = _.get(voucherData, 'voucherCode') || ``;
  const formSectionProps = getFormSectionProps(stepper, stage);
  return (
    <FormSection label='Enter your code' hideEdit {...formSectionProps}>
      <div onClick={(e) => e.stopPropagation()}>
        {showFailure && (
          <Message
            type='failure'
            className='mt-8 mb-16'
            message={errorMessage}
            open={true}
          />
        )}
        <Input
          autoFocus={autoFocusForm ? !voucherCode && !voucherCodeParam : false}
          defaultValue={voucherCodeParam}
          className='leading-1.14 mb-9 md:w-360 xl:w-381'
          type='string'
          labelText='Access code *'
          placeHolder='Enter your access code'
          value={voucherCode || voucherCodeParam}
          changed={voucherCodeChange}
          onChangeHandler={changeHandler}
          validate={true}
          validator={(value, e) => {
            return _.isEmpty(value) || !validateVoucher(value)
              ? 'This is an invalid code format. Check your code and try again.'
              : null;
          }}
        />
        <div className='recaptchaWrapper mb-24'>
          <ReCAPTCHA
            ref={voucherCaptcha}
            size='normal'
            sitekey={defaultConfig.recaptchaApiKey}
            onChange={verifyCallback}
            onExpired={onRecaptchaExpired}
            onErrored={onRecaptchaError}
            theme='dark'
          />
        </div>
        <div
          className={`${
            validVoucher && recaptchaVerified
              ? 'pointer-events-auto'
              : 'pointer-events-none'
          }`}
        >
          <Button
            text='Validate my access code'
            type='primary-alternate'
            loading={inProgress || subscriptionUserInProgress}
            handler={() => setInProgress(true)}
            buttonClassName='w-full mb-16 md:w-266 xl:w-276'
            rounded
          />
        </div>
      </div>
    </FormSection>
  );
};

export default EnterVoucher;
