import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

const statusCurrent = 'current';
const statusIncomplete = 'incomplete';
const statusComplete = 'complete';

const stepBg = (status) => {
  switch (status) {
    case statusIncomplete:
      return 'bg-lighter-grey';
    case statusComplete:
      return 'bg-green';
    case statusCurrent:
    default:
      return 'bg-pink';
  }
};

const FormSection = ({ step, label, status, onEditClick, children, open, disable, hideEdit, ...props }) => (
  <div className={`${disable ? 'pointer-events-none' : ''} flex flex-col flex-no-wrap min-h-48 h-auto`} {...props}>
    <div className={`flex flex-row items-center min-h-48 h-auto mb-16 bg-medium-dark-grey`} {...props}>
      <span className={`w-20 h-20 text-xs font-MarkProBold rounded-full ml-14 flex flex-row text-black justify-center items-center ${stepBg(status)}`}>{step}</span>
      <span className="flex-grow font-MarkProBold text-white leading-1.14 text-sm ml-14">{label}</span>
      {!hideEdit && status === 'complete' && <button className="underline text-base text-teal leading-1 mr-7 focus:outline-none hover:text-teal-dark active:text-teal-light os-transition-btn" onClick={(e) => { if (onEditClick) { onEditClick(e); } }}>Edit</button>}
    </div>
    <CSSTransition
      in={open}
      timeout={500}
      classNames="os-dropdown"
      unmountOnExit>
      <div key="form">
        {children}
      </div>
    </CSSTransition>

  </div>
);

FormSection.propTypes = {
  /** Step count to show */
  step: PropTypes.string.isRequired,
  /** Label for this form section */
  label: PropTypes.string.isRequired,
  /** Status of the form section - whether it is completed or not */
  status: PropTypes.oneOf([statusCurrent, statusIncomplete, statusComplete]),
  /** Click handler for the edit button.*/
  onEditClick: PropTypes.func,
  /** Whether this form section is open or not */
  open: PropTypes.bool,
  /** Hide the Edit button*/
  hideEdit: PropTypes.bool,
};

FormSection.defaultProps = {
  open: false,
  status: statusIncomplete,
  onEditClick: null,
  hideEdit: false
};

export default FormSection;

