import React from 'react';
import PropTypes from 'prop-types';

const FieldValidIcon = ({ width, height, color, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 32 32`} {...props}>
  <g fill="none" fillRule="evenodd" transform="translate(8 8)">
  <circle cx="8" cy="8" r="8" fill="#00FF91"/>
  <path fill="#000" d="M5.635 10.83l-2.31-2.31L4.74 7.106l2.31 2.31 4.243-4.243 1.414 1.414-5.657 5.657-1.414-1.414z"/>
  </g>
  </svg>
  );

FieldValidIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

FieldValidIcon.defaultProps = {
	width: 32,
	height: 32
};

export default FieldValidIcon;
