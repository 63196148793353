import React from 'react';
import InfoIcon2 from '../ui-kit/Icons/InfoIcon2';
import H3 from '../ui-kit/Typography/H3';

const renderFormSubmitError = (styles) => {
  return (
    <div className={`flex justify-center items-center w-full py-80 ${styles}`}>
      <div className='flex flex-col max-w-701 items-center'>
        <InfoIcon2
          className='block m-auto text-center h-96 w-96'
          color='#ff0000'
        />
        <H3
          className='block text-center md:mb-32 mt-32 lg:text-3xl'
          marginY='mb-16'
        >
          An error has occurred
        </H3>
        <p className='w-full xs:w-369 leading-normal text-center'>
          Please refresh page or try again later
        </p>
      </div>
    </div>
  );
};

export default renderFormSubmitError;
