import React, { useRef, useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { BtnSearch, SearchFormIcon } from 'components/search/SearchBox/styled';
import SearchIcon from './Icons/SearchIcon';
import { getAbsoluteStaticUrl } from 'utils/path';
import Picture from 'components/ui/Picture';
import PlusIcon from './Icons/PlusIcon';

const overlayClassName = 'bottom-overlay';

const autosuggestTheme = (containerClass) => ({
  container: `auto-suggestions-container ${overlayClassName}`,
  input: `os-transition os-transitionproperty-all font-MarkPro bg-dark-grey text-white
            px-16 mb-0 h-48 focus:outline-none w-full auto-suggestions-input
            border-light-grey border-2 focus:border-white rounded`,
  inputOpen: '',
  inputFocused: '',
  suggestionsContainer: `bg-widget-grey suggestions-container ${containerClass}`,
  suggestionsContainerOpen: '',
  suggestionsList: 'suggestions-list',
  suggestion: 'suggestion',
  suggestionFirst: '',
  suggestionHighlighted: 'suggestion-high-lighted',
  sectionContainer: '',
  sectionContainerFirst: '',
  sectionTitle: '',
});

const escapeRegexCharacters = (str) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const AutoSuggestList = ({
  label,
  className,
  placeholder = '',
  containerClass = '',
  items: allItems = [],
  onChange,
  renderRowFn,
  getDisplayDetails,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState({});
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  const inputProps = {
    ref: inputRef,
    placeholder,
    value,
    autoFocus: false,
    onChange: (event, { newValue, method }) => {
      method !== 'click' && setValue(newValue);
    },
  };

  useEffect(() => {
    const containersList = document.getElementsByClassName(
      'suggestions-container',
    );
    if (containersList.length) {
      const suggestionsContainer = containersList[0];
      suggestionsContainer.scrollTo({ top: 0 });
    }
  }, []);

  useEffect(() => {
    setSuggestions(allItems);
    // eslint-disable-next-line
  }, [allItems]);

  const getSuggestionValue = (suggestion) => suggestion?.officialName;

  const renderSuggestion = (suggestion) => {
    if (renderRowFn && typeof renderRowFn === 'function') {
      return renderRowFn(suggestion, selectedSuggestion);
    }
  };

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason !== 'suggestion-selected') {
      setSuggestions(getSuggestions(allItems, value));
    }
  };

  const getSuggestions = (allItems, value = '') => {
    const escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === '') {
      return allItems;
    }

    const regex = new RegExp(escapedValue, 'i');
    const filteredItems = allItems.filter((item) =>
      regex.test(item.officialName),
    );
    return filteredItems;
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setSelectedSuggestion(suggestion);
    if (onChange && typeof onChange === 'function') {
      onChange(suggestion);
    }
  };

  const renderInputComponent = (inputProps) => {
    return (
      <div className={`flex relative bg-widget-grey`}>
        <div className='relative inline-block flex-1 p-1'>
          <input {...inputProps} />
          <BtnSearch type='search' onClick={() => {}} />
          <SearchFormIcon>
            <SearchIcon />
          </SearchFormIcon>
        </div>
      </div>
    );
  };

  const handleOnScroll = (event) => {
    const currentEl = event?.target || {};
    const parentEl = currentEl?.parentNode || {};
    const { scrollHeight = 0, scrollTop = 0, offsetHeight = 0 } = currentEl;
    if (scrollHeight === scrollTop + offsetHeight) {
      // user has scrolled to bottom of the container
      parentEl.classList.remove(overlayClassName);
    } else {
      parentEl.classList.add(overlayClassName);
    }
  };

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <div onScroll={handleOnScroll} {...containerProps}>
        {children}
      </div>
    );
  };

  const { icon = '', text = '' } = getDisplayDetails(selectedSuggestion);

  return (
    <div className={`${className}`}>
      <div className='flex flex-col justify-center items-center h-196'>
        {icon && (
          <span className='team-badge-wrap h-100 w-100 mr-13 md:mr-0 md:mb-8 flex-no-grow flex-no-shrink relative z-100 text-center'>
            <img className='object-contain' src={icon} alt={text} />
          </span>
        )}
        {text ? (
          <span className='mt-8'>{text}</span>
        ) : (
          <>
            <div className='flex flex-col justify-center items-center'>
              <Picture
                className='h-96 align-middle inline-block'
                src={getAbsoluteStaticUrl('/icons/icon-shield.png')}
                alt='Shield icon'
              />
              <PlusIcon className='absolute' fill='#AAAAAA' />
            </div>
            <span className='mt-16'>{label}</span>
          </>
        )}
      </div>
      <div className={`team-search-container`} id='team-search-form'>
        <div className='p-10'>
          <Autosuggest
            inputProps={inputProps}
            renderInputComponent={renderInputComponent}
            theme={autosuggestTheme(containerClass)}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderSuggestionsContainer={renderSuggestionsContainer}
            alwaysRenderSuggestions={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AutoSuggestList;
