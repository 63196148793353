import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  getPaymentDetails,
  deletePaymentDetails,
  updatePaymentDetails,
  PAYMENT_DETAILS_FETCH,
  PAYMENT_DETAILS_UPDATE,
  PAYMENT_DETAILS_DELETE,
} from '../../ducks/payment';
import LinkButton from './ui-kit/LinkButton';
import LoadingIcon from './ui-kit/Icons/LoadingIcon';
import Message from './ui-kit/Message';
import Alerts from './ui-kit/Alerts';
import PaymentDetailsInput from './PaymentDetailsInput';
import { isLoadingAnyOf, isSuccessAnyOf } from '../../ducks/loading';
import PaymentCard from './PaymentCard';
import { withFeatureFlags } from '../context/withFeatureFlags';
import { getAbsoluteStaticUrl } from 'utils/path';

class PaymentDetails extends React.Component {
  state = {
    showAlert: true,
  };

  componentDidUpdate() {
    const { isUpdated } = this.props;
    if (isUpdated) {
      this.props.getPaymentDetails({ keepErrorOk: true });
      return;
    }
    const { tokenGenerated, selected, details } = this.state;
    const { getToken, onToken } = this.props;
    if (!tokenGenerated && getToken && selected && onToken) {
      this.setState({ tokenGenerated: true });
      // We should fallback to passing payment details id
      // if we choose to not return the token in payment details
      onToken(details.token || details.id);
      return;
    }
  }

  onClick = (id, details) => {
    if (this.props.onClick) {
      // Previously used to select/deselect to support
      // future where more than on card is available
      // const currentSelection = this.state.selected;
      // const selected = currentSelection === id ? null : id;

      const selected = this.state.selected || id;
      this.setState({ selected, details });
      this.props.onClick(selected ? details : null);
    }
  };

  onToken = (token) => {
    this.setState({
      showUpdateDetails: false,
      showAlert: true,
    });
    const { details } = this.state;
    const { id } = details || {};
    this.props.updatePaymentDetails({
      paymentDetailsId: id,
      token,
    });
  };

  onUpdateDetails = (details) => (e) => {
    this.setState({ showUpdateDetails: true, details });
  };

  onDeleteDetails = ({ id }) => (e) => {
    this.props.deletePaymentDetails(id);
  };

  renderIcon({ cardBrand }) {
    const brand = cardBrand || `generic`;
    const cardImage = getAbsoluteStaticUrl(`/images/cards/${brand}.svg`);
    return (
      <img
        width='32'
        height='32'
        src={cardImage}
        alt={brand}
        onError={(e) =>
          (e.target.src = getAbsoluteStaticUrl(
            '/images/cards/generic-card.svg',
          ))
        }
      />
    );
  }

  renderTitle(detail) {
    const { cardLastFourDigits, cardExpirationDate } = detail;

    return (
      cardLastFourDigits &&
      cardExpirationDate && (
        <div className='flex items-center w-full'>
          <div className='flex-grow mr-16 md:flex-none'>
            •••• {cardLastFourDigits}
          </div>
          {/*<div className="flex-none w-1/2 ml-auto md:ml-0">Exp. {cardExpirationDate}</div>*/}
          {this.renderActions(detail)}
        </div>
      )
    );
  }

  renderActions(detail) {
    const { renderActions, getFeatureFlags = () => false } = this.props;

    const featurePaymentPage = getFeatureFlags('FEATURE_PAYMENT_PAGE');

    return (
      featurePaymentPage &&
      renderActions && (
        <button
          className='focus:outline-none truncate active:os-ty-4 os-transition-btn'
          onClick={this.onDeleteDetails(detail)}
        >
          <img
            className='max-w-32'
            width='32'
            height='32'
            src={getAbsoluteStaticUrl('/images/icon-trash.svg')}
            title='Delete Payment Details'
            alt='Delete Payment Details'
          />
        </button>
      )
    );
  }

  renderNoDetails() {
    const { renderNoDetails, getFeatureFlags = () => false } = this.props;
    const featurePaymentPage = getFeatureFlags('FEATURE_PAYMENT_PAGE');

    return (
      <>
        {renderNoDetails ? (
          renderNoDetails()
        ) : (
          <Message
            type='failure'
            message="You don't have any saved card details."
            open
          />
        )}

        {featurePaymentPage ? (
          <LinkButton
            type='primary'
            text='Add payment information'
            className={`text-base leading-normal`}
            handler={this.onUpdateDetails()}
          />
        ) : null}
      </>
    );
  }

  renderDetails() {
    const { loadingAny } = this.props;
    if (loadingAny) {
      return (
        <div className='flex justify-center'>
          <LoadingIcon />
        </div>
      );
    }
    const { payment } = this.props;
    const { details } = payment;
    if (this.state.showUpdateDetails) {
      const { details } = this.state;
      return (
        <PaymentDetailsInput
          onClose={() => this.setState({ showUpdateDetails: false })}
          details={details}
          onToken={this.onToken}
        />
      );
    }
    if (isEmpty(details)) {
      return this.renderNoDetails();
    }

    const {
      renderUpdateLink,
      displayCard,
      getFeatureFlags = () => false,
    } = this.props;

    const featurePaymentPage = getFeatureFlags('FEATURE_PAYMENT_PAGE');

    // Need to return different design when displayCard: true
    // i.e. display the mock card with card details on it
    // Improvement @TODO: look into moving this logic all into PaymentCard component
    return details.map((detail) => {
      const id = `payment-details-${detail.id}`;

      // Just preselect for the user as only one payment detail is available at all times
      // Later on when we support more cards, we should reintroduce select/deselecting
      // logic as below
      if (this.state.selected !== id) {
        this.onClick(id, detail);
      }

      // const isSelected = this.state.selected === id;
      // const border = this.props.onClick ? `border border-b ${isSelected ? `border-teal` : `border-transparent`} pt-8 px-8` : ``;

      // Previous style where border wraps the entire width and is selectable
      // <div key={id} className={`mt-8 ${border}`} onClick={() => this.onClick(id, detail)}>
      return (
        <div key={id} className={`mt-8`}>
          {displayCard ? (
            <PaymentCard
              detail={detail}
              // borderStyle={isSelected ? border : ''}
            />
          ) : (
            <>
              <div className='flex items-center mb-8'>
                <div className='flex-none'>{this.renderIcon(detail)}</div>
                <div className='flex-grow ml-8 px-8'>
                  {this.renderTitle(detail)}
                </div>
                <div className='flex-none'>
                  {featurePaymentPage && renderUpdateLink && (
                    <LinkButton
                      type='primary'
                      text='Update payment information'
                      className={`text-base leading-normal hidden md:block`}
                      handler={this.onUpdateDetails(detail)}
                    />
                  )}
                </div>
              </div>
              {featurePaymentPage && renderUpdateLink && (
                <LinkButton
                  type='primary'
                  text='Update payment information'
                  className={`text-base leading-normal text-left block md:hidden`}
                  handler={this.onUpdateDetails(detail)}
                />
              )}
            </>
          )}
        </div>
      );
    });
  }

  renderStatus() {
    const { payment, renderStatus } = this.props;
    if (!renderStatus) {
      return null;
    }
    if (!payment.error && !payment.ok) {
      return null;
    }
    const { showAlert } = this.state;
    const { loadingAny, getFeatureFlags } = this.props;
    if (loadingAny) {
      return null;
    }

    const [errorDetails = {}] = get(payment, `error.errors`, []);
    const defaultErrorMessage = getFeatureFlags(
      'FEATURE_PAYMENT_DEFAULT_ERROR',
    );
    const { code = '', message: errorMessage = '' } = errorDetails;
    const declinedCode = get(errorDetails, 'data.declinedCode', 0);

    let failureMessage = defaultErrorMessage;

    if (declinedCode > 0) {
      failureMessage = `${defaultErrorMessage} #Error ${declinedCode}`;
    } else {
      // User has no details stored so don't return as an error
      if (code === `GET_PAYMENTS_NO_DETAILS`) {
        return null;
      } else if (code === 'GET_PAYMENTS_FAILED') {
        // This is considered a legitimate get payment details error
        failureMessage = `Sorry, we encountered an error retrieving your payment information. Please try again. If the error persists, please contact Optus Sport support.`;
      } else {
        if (errorMessage) {
          if (errorMessage === 'CARD_NOT_SUPPORTED') {
            failureMessage = `Unfortunately, we cannot accept this type of card as form of payment. Please use a valid Australian credit or debit card for payment. Please note that Optus Sport content is not available for viewing outside of Australia.`;
          } else {
            const errorMessageNumber = parseInt(errorMessage);

            failureMessage = `${defaultErrorMessage} ${
              !isNaN(errorMessageNumber)
                ? `#Error ${errorMessageNumber}`
                : `(${errorMessage})`
            }`;
          }
        }
      }
    }

    const successMessage =
      get(payment, 'ok') === true
        ? 'Your payment details have been updated.'
        : null;
    const messageType = successMessage ? 'success' : 'failure';

    return showAlert ? (
      <Alerts
        className={`
        mt-16
      `}
        type={messageType}
        title={successMessage || failureMessage}
        onClose={() => this.setState({ showAlert: false })}
        open={true}
        showClose={true}
      />
    ) : null;
  }

  render() {
    const { gutterBottom, classes } = this.props;
    const { container = `` } = classes;
    const gutterStyles = gutterBottom ? `gutter-bottom` : ``;
    const containerStyles = `${gutterStyles} ${container}`;
    return (
      <div className={containerStyles}>
        {this.renderDetails()}
        {this.renderStatus()}
      </div>
    );
  }
}

PaymentDetails.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
  }),
  gutterBottom: PropTypes.bool,
  renderActions: PropTypes.bool,
  renderUpdateLink: PropTypes.bool,
  displayCard: PropTypes.bool,
};

PaymentDetails.defaultProps = {
  classes: {},
  gutterBottom: false,
  renderActions: true,
  renderUpdateLink: true,
  renderStatus: true,
  displayCard: false,
};

function mapStateToProps(state) {
  return {
    payment: state.payment,
    loadingAny: isLoadingAnyOf(state.loading, [
      PAYMENT_DETAILS_FETCH,
      PAYMENT_DETAILS_UPDATE,
      PAYMENT_DETAILS_DELETE,
    ]),
    isUpdated: isSuccessAnyOf(state.loading, [
      PAYMENT_DETAILS_UPDATE,
      PAYMENT_DETAILS_DELETE,
    ]),
  };
}

const mapDispatchToProps = {
  getPaymentDetails,
  deletePaymentDetails,
  updatePaymentDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withFeatureFlags(PaymentDetails));
