import React, { useContext } from 'react';

import { withFeatureFlags } from 'components/context/withFeatureFlags';
import SignupContext from './SignupContext';

import HeroSection from './HeroSection';
import SellingPoints from './SellingPoints';
import LogosSection from './LogosSection';
import PageThemeSection from './PageThemeSection';
import SubscriptionDetailsSection from './SubscriptionDetailsSection';
import DevicesSection from './DevicesSection';
import ExploreSubhubSection from './ExploreSubhubSection';
import CreateAccountSection from './CreateAccountSection';
import SubscriptionPlanSection from './SubscriptionPlanSection';
import SignupPromotionalBanner from './SignupPromotionalBanner';
import SeoSection from './SeoSection';

function mapSectionComponent(sectionKey) {
  switch (sectionKey) {
    case 'seoSection':
      return <SeoSection />
    case 'heroSection':
      return <HeroSection />;
    case 'sellingPointsSection':
      return <SellingPoints />;
    case 'logosSection':
      return <LogosSection />;
    case 'signup-page-theme-section-1':
    case 'signup-page-theme-section-2':
      return <PageThemeSection sectionName={sectionKey} />;
    case 'subscriptionDetailsSection':
      return <SubscriptionDetailsSection />;
    case 'subscriptionPlanSection':
      return <SubscriptionPlanSection />;
    case 'devicesSection':
      return <DevicesSection />;
    case 'exploreSubhubSection':
      return <ExploreSubhubSection />;
    case 'createAccountSection':
      return <CreateAccountSection />;
    case 'signupPromotionalBanner':
      return <SignupPromotionalBanner />;
    default:
      return <></>;
  }
}

const defaultSections = [
  'heroSection',
  'signupPromotionalBanner',
  'sellingPointsSection',
  'logosSection',
  'signup-page-theme-section-1',
  'subscriptionDetailsSection',
  'signup-page-theme-section-2',
  'subscriptionPlanSection',
  'devicesSection',
  'exploreSubhubSection',
  'createAccountSection',
];

const ThemeSectionTemplate = ({ getFeatureFlags }) => {
  const signupContext = useContext(SignupContext);
  const currentTheme = signupContext.theme;
  const { themes } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);

  const sections =
    (themes && themes[currentTheme]?.sections) || defaultSections;

  return (
    <>
      {sections.map((sectionKey) => (
        <React.Fragment key={sectionKey}>
          {mapSectionComponent(sectionKey)}
        </React.Fragment>
      ))}
    </>
  );
};

export default withFeatureFlags(ThemeSectionTemplate);
