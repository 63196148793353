import React from 'react';
import isEmpty from 'lodash/isEmpty';
import InfoIcon2 from '../ui-kit/Icons/InfoIcon2';
import H3 from '../ui-kit/Typography/H3';

export const renderInfo = (styles, mainText, winnersList, noWinnersText) => (
  <div className={`flex justify-center items-center w-full py-80 ${styles}`}>
    <div className='flex flex-col max-w-753 items-center'>
      <InfoIcon2 className='block m-auto text-center h-96 w-96' />
      <H3
        className='block text-center md:mb-32 mt-32 lg:text-3xl'
        marginY='mb-16'
      >
        {mainText}
      </H3>
      <p className='w-full xs:w-360 leading-normal text-center'>
        If you have any questions please visit the Optus Sport{' '}
        <a
          className='font-MarkProBold leading-lg text-teal hover:text-teal-dark active:text-teal-light os-transition-btn underline'
          href='https://www.optus.com.au/for-you/support/entertainment/optus-sport-hub'
          target='_blank'
          rel='noopener noreferrer'
        >
          Support Hub
        </a>
      </p>
      {!isEmpty(winnersList) && (
        <>
          <H3
            className='block text-center md:mb-32 mt-64 lg:text-3xl'
            marginY='mb-16'
          >
            Congratulations to the Winners!
          </H3>
          <ul className='text-xl leading-40'>
            {winnersList.map((w) => (
              <li>{w}</li>
            ))}
          </ul>
        </>
      )}
      {noWinnersText && (
        <div className='text-base text-center leading-base mt-64'>
          {noWinnersText}
        </div>
      )}
    </div>
  </div>
);

export const renderNotEligible = (styles) => {
  return renderInfo(styles, "You aren't eligible to enter this contest");
};

export const renderCompetitionClosed = (styles, winnersList, noWinnersText) => {
  return renderInfo(
    styles,
    'This competition is now closed',
    winnersList,
    noWinnersText,
  );
};
