import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCarouselLayout, getEditorialLimit } from '../new-home/utils';
import { loadEditorial } from 'actions/editorial';

const Rail = ({ block, styles, containerClass }) => {
  const dispatch = useDispatch();
  const { title, layout, bucketKey, index, panelType } = block;
  const limit = getEditorialLimit({ layout });

  useEffect(() => {
    dispatch(loadEditorial(bucketKey, limit));

    //eslint-disable-next-line
  }, [bucketKey, limit]);

  const getCarouselLayoutParams = {
    carousel: {
      id: bucketKey,
      title,
      layout,
      panelType,
      showViewAllLink: false,
      containerClass,
    },
    carouselIndex: index,
  };
  return (
    <div className={styles}>{getCarouselLayout(getCarouselLayoutParams)}</div>
  );
};

export default Rail;
