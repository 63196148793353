import React from "react";
import VendorButton from "../ui/ui-kit/VendorButton";

class DownloadApp extends React.Component {
  render() {
    const currentLocation = window && window.location;
    let currentUrl;
    if (currentLocation) {
      const { origin, pathname } = currentLocation;
      currentUrl = `${origin}${pathname}`;
    }
    const deepLink = currentUrl ? `https://c5pq6.app.goo.gl/?link=${currentUrl}&apn=au.com.optus.sport&isi=1113368382&ibi=au.com.optus.epl&ius=optussport` : null;

    return (
      <div className="h-full absolute z-20">
        <div className="w-full flex flex-col items-center" style={{ minHeight: 'calc(100vh - 8rem)' }}>
          <div className="w-full md:w-369 h-full bg-black flex flex-col items-center">
            <div className="w-full h-24 md:h-32 text-center mt-32 md:mt-40">
              <span className="text-white font-MarkProHeavy text-xl md:text-2xl leading-base">
                Download the app
          </span>
            </div>
            <div className="w-full h-72 text-center px-14 mt-16 md:mt-32">
              <span className="text-white font-MarkPro text-base leading-lg">
                To view this content on your mobile device or tablet please download
                the Optus Sport app for iOS or Android.
          </span>
            </div>
            <div className="w-full h-48 px-30 mt-32">
              <VendorButton link={deepLink || 'https://itunes.apple.com/au/app/optus-sport/id1113368382?mt=8'} type="apple" size="large" className="w-full" />
            </div>

            <div className="w-full h-48 px-30 mt-16">
              <VendorButton link={deepLink || 'http://play.google.com/store/apps/details?id=au.com.optus.sport'} type="google" size="large" className="w-full" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DownloadApp;
