import React from 'react';
import PropTypes from 'prop-types';

const CalendarIcon = ({ width, height, color, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width={width} height={height} viewBox={`0 0 32 32`} {...props}>
  <defs>
  <path id="a" d="M19 12h-6v1h-1v-1H9v11h14V12h-3v1h-1v-1zm0-1V9h1v2h4v13H8V11h4V9h1v2h6z"/>
  </defs>
  <g fill={color} fillRule="evenodd">
  <path fill="none" d="M0 0h32v32H0z"/>
  <mask id="b" fill={color}>
  <use xlinkHref="#a"/>
  </mask>
  <use fill={color} xlinkHref="#a"/>
  <g fill={color} mask="url(#b)">
  <path d="M0 0h32v32H0z"/>
  </g>
  </g>
  </svg>
  );

CalendarIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CalendarIcon.defaultProps = {
  color: 'currentColor',
  width: 32,
  height: 32
};

export default CalendarIcon;
