export const CHECKOUT_INITIATED_FROM_COOKIE_NAME = `checkout_initiated_from`;
export const CHECKOUT_INITIATED_FROM_COOKIE_VALUES = {
  USER_COMPETITION: 'USER_COMPETITION',
};
export const SIGNUP_INITIATED_FROM_COOKIE_NAME = `signup_initiated_from`;
export const SIGNUP_INITIATED_FROM_ASSET_COOKIE_NAME = `signup_initiated_from_asset`;
export const SIGNUP_INITIATED_FROM_COOKIE_VALUES = {
  PREMIUM_ASSET: 'premium_asset',
  FREE_ASSET: 'free_asset',
  OTHER_SIGNUP_LINK: 'other_signup_link',
  USER_COMPETITION_SIGNUP: 'user_competition_signup_account',
  USER_COMPETITION_SIGNUP_ANOTHER: 'user_competition_signup_another_account',
};
