
import React from 'react';
import PropTypes from 'prop-types';

const NotFoundIcon = ({ width, height, viewBox, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <g fill="none" fillRule="evenodd">
        <path fill="#FDCC08" fillRule="nonzero" d="M72 139c37.003 0 67-29.997 67-67S109.003 5 72 5 5 34.997 5 72s29.997 67 67 67zm0 5c-39.765 0-72-32.235-72-72S32.235 0 72 0s72 32.235 72 72-32.235 72-72 72z"/>
        <path stroke="#FDCC08" strokeWidth="5" d="M73 50l24 40H49z"/>
    </g>
</svg>

);

NotFoundIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

NotFoundIcon.defaultProps = {
  width: 144,
  height: 144,
  viewBox: "0 0 144 144"
};

export default NotFoundIcon;
