import React from 'react';
import { getAbsoluteStaticUrl } from '../../../utils/path';
import Button from 'components/ui/ui-kit/Button';
import { withFeatureFlags } from 'components/context/withFeatureFlags';

const SubscriptionDetailsSection = ({ getFeatureFlags }) => {
  const { subscriptionDetailsSection } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);

  if (!subscriptionDetailsSection?.showSection) {
    return null;
  }

  const {
    itemsList: subscriptionPlans,
    headingText,
    showButton,
    itemsListHeading: tableHeading,
  } = subscriptionDetailsSection;

  const handleOnViewSubscriptionButtonClick = (e) => {
    e.preventDefault();
    const topOffset = 130;
    let targetElement = document.getElementById('subscription-plans');
    let targetElementPosition = targetElement.getBoundingClientRect().top;
    const offsetPosition =
      targetElementPosition + window.pageYOffset - topOffset;
    targetElement &&
      window.scrollTo({ behavior: 'smooth', top: offsetPosition });
  };

  return (
    <section
      className={`
        flex
        items-center
        flex-col
        w-full
        relative
        flex
        justify-center
        px-16
        sm:px-32
        lg:px-120
        pt-48
        `}
    >
      <div className={`flex w-full justify-start md:max-w-794`}>
        <h1
          className={`
        font-MarkProHeavy
        font-900
        text-xl
        sm:text-2xl
        sm:leading-40
        mb-32
        lg:mb-40
        `}
        >
          {headingText}
        </h1>
      </div>
      <div className='flex flex-col w-full md:max-w-794'>
        <header className='flex items-start border-b border-b-2 border-white10 font-MarkProHeavy font-900 mb-8'>
          <div className='flex w-full leading-lg items-center'>
            {tableHeading.col1}
          </div>
          <div className='flex  min-w-64 justify-center align-center mr-8'>
            {tableHeading.col2}
          </div>
          <div className='flex min-w-64 justify-center align-center'>
            {tableHeading.col3}
          </div>
        </header>
        <div className='flex'>
          <div className='flex flex-col items-start w-full py-8'>
            {subscriptionPlans.map((sp, i) => {
              return (
                <div className='flex items-center my-8 h-32' key={i}>
                  {sp.name}
                </div>
              );
            })}
          </div>
          <div className='flex flex-col items-start py-8 mr-8'>
            {subscriptionPlans.map((sp, i) => {
              return (
                <div
                  className='flex my-8 h-32 min-w-64 justify-center align-center'
                  key={i}
                >
                  {sp.free ? (
                    <img
                      className={`w-32 h-32`}
                      src={getAbsoluteStaticUrl(
                        '/images/signup/icon-green-tick.svg',
                      )}
                      alt='>'
                    />
                  ) : (
                    <img
                      className={`w-32 h-32`}
                      src={getAbsoluteStaticUrl(
                        '/images/signup/icon-not-free.svg',
                      )}
                      alt='>'
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className='flex flex-col items-start bg-core-dark-grey rounded-16px py-8'>
            {subscriptionPlans.map((sp, i) => {
              return (
                <div
                  className='flex my-8 h-32 min-w-64 justify-center align-center'
                  key={i}
                >
                  {sp.plan ? (
                    <span>
                      <img
                        className={`w-32 h-32`}
                        src={getAbsoluteStaticUrl(
                          '/images/signup/icon-green-tick.svg',
                        )}
                        alt='>'
                      />
                    </span>
                  ) : (
                    <img
                      className={`w-32 mb-8`}
                      src={getAbsoluteStaticUrl(
                        '/images/signup/icon-not-free.svg',
                      )}
                      alt='>'
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {showButton && (
        <Button
          type='secondary-alternate-filter'
          text={`View subscription plans`}
          buttonClassName='min-h-48 min-w-320 mt-24 sm:mt-32'
          theme='light'
          href='https://subhub.optus.com.au/streaming-services/optussport'
          handler={handleOnViewSubscriptionButtonClick}
        ></Button>
      )}
    </section>
  );
};
export default withFeatureFlags(SubscriptionDetailsSection);
