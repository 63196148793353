import _ from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import smoothscroll from 'smoothscroll-polyfill';
import LoadingIcon from './ui-kit/Icons/LoadingIcon';
import BackIcon from './ui-kit/Icons/BackIcon';
import Epg from './ui-kit/Epg';
import MDTabLink from './ui-kit/MDTabLink';

smoothscroll.polyfill();

class AssetEpg extends Component {
  epgInterval = null;
  tabbedContentEls = {};
  initialResize = false;

  constructor(props) {
    super(props);

    this.state = {
      showSlider: false,
      selectedTabIndex: 0,
      count: 0,
      showRight: true,
      index:        0, // index of first card that showing on the page
      offset:       0, // px to transform
      showRightArrow: true,
      showLeftArrow: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.showSlideHandler.bind(this));

    if(document.querySelector('.tab-links-list') && document.querySelector('.tab-links-list').scrollLeft > 0) {
      this.setState({
        showLeftArrow: true
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.showSlideHandler.bind(this));

    // Clear the epg interval
    clearInterval(this.epgInterval);
    this.epgInterval = null;
  }

  showSlideHandler() {
    if (this.tabsEl) {
      const showSlider = this.tabsEl.scrollWidth > this.tabsEl.clientWidth;
      this.setState({
        showSlider: showSlider
      });
    }
  }

  getVisibleItems() {
    const list = ReactDOM.findDOMNode(this.tabsEl);

    if (list) {
      const len = list.children.length;
      const offsetLeft = Math.abs(this.state.offset);
      const clientWidth = list.clientWidth;
      let start;
      let i;
      let width = 0;

      for (start = 0; start < len; start++) {
        if (list.children[start].offsetLeft >= offsetLeft) {
          break;
        }
      }
      for (i = start; i < len && width < clientWidth; i++) {
        if (width + list.children[i].clientWidth > clientWidth) {
          break;
        }
        width += list.children[i].clientWidth;
      }
      return i - start;
    }
    return 0;
  }


  getOffset(index) {
    let list = ReactDOM.findDOMNode(this.tabsEl);
    let offset = 0;

    if (index <= 0 || !list) {
      /*
       * offset is 0 in the follow case:
       * 1. input index is 0 mean nothing to scroll;
       * 2. the list or not list;
       */
      return 0;
    }

    for (let i = 0; i < index && i < list.children.length; i++) {
      offset += list.children[i].clientWidth;
    }

    return -Math.min(offset, list.scrollWidth - list.clientWidth);
  }

  onPrevious(e) {
    const index = Math.max(0, this.state.index - this.getVisibleItems());

    e.preventDefault();
    this.setState({
      index:  index,
      offset: this.getOffset(index)
    });
  }

  onNext(e) {
    let list;
    let visibleItems;
    let index;

    e.preventDefault();
    list = ReactDOM.findDOMNode(this.tabsEl);
    visibleItems = this.getVisibleItems();
    index = Math.min(this.state.index + visibleItems, list.children.length - visibleItems);

    this.setState({
      index:  index,
      offset: this.getOffset(index)
    });
  }


  scrollTo(direction) {
    const navList = this.tabsEl;
    const count = direction === 'left' ? this.state.count - 1 : this.state.count + 1;
    const showRight = direction === 'left' ? navList.scrollWidth !== navList.scrollLeft -  navList.clientWidth : document.querySelectorAll('.epg-tab-slider-container > ul.list-reset')[0].offsetWidth + document.querySelectorAll('.epg-tab-slider-container > ul.list-reset')[0].scrollLeft >= document.querySelectorAll('.epg-tab-slider-container > ul.list-reset')[0].scrollWidth;
    this.setState({ count, showRight }, () => this.sideScroll(navList, direction, 25, 70, 10));
  }

  sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    let slideTimer = setInterval(() => {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  formatEpgData(data, days) {
    let tabs = [];
    let tabsContent = [];

    let { selectedTabIndex } = this.state;
    if (typeof selectedTabIndex === 'undefined') {
      selectedTabIndex = 0;
    }

    if (data) {
      let week = [];
      for (let i = 0; i < days; i++) {
        week.push(moment().add(i, 'days'));
      }

      // Actual events content by days
      tabsContent = week.map((day, index) => {
        return (
          <div
            key={index}
            ref={tabContent => this.tabbedContentEls[index] = tabContent}
            className={index === selectedTabIndex ? 'block' : 'hidden'}
          >
            {this.formatEpgDay(data, day)}
          </div>
        );
      });

      // Tabs by days
      tabs = week.map((day, index) => {
        const marginX = index === 0 ? 'mr-4' : 'mx-4'
        return (
          <MDTabLink
            className={`
              h-auto
              mr-0-important
              ${marginX}
            `}
            key={index}
            label={index > 0 ? day.format('ddd D') : 'Today'}
            active={index === selectedTabIndex}
            link="#"
            callback={(e) => {
              if (selectedTabIndex !== index) {
                this.setState({selectedTabIndex: index});
              }
            }}
          />
        );
      });
    }

    return {
      tabs,
      tabsContent
    };
  }

  formatEpgDay(epg, day) {
    let startFromIndex = null;
    const epgFinishedEvents = _.get(this.props, 'epgFinishedEvents', 2);
    const events = _.get(epg, ['DVB-EPG', 'Service', 'Event'], []);

    if (!events.length) {
      return;
    }

    let shows = events
      .filter(event => {
        const eventEpgStart = _.get(event, 'epgStart');
        const eventEpgStop = _.get(event, 'epgStop');

        if (eventEpgStart && eventEpgStart.search(/z/i) < 0) {
          event.epgStart += 'Z';
        }
        if (eventEpgStop && eventEpgStop.search(/z/i) < 0) {
          event.epgStop += 'Z';
        }

        const epgStart = moment(new Date(event.epgStart));

        return event.name && epgStart.isSame(day, 'day');
      })
      .map((event, index) => {
        const eventEpgStart = _.get(event, 'epgStart');
        const eventEpgStop = _.get(event, 'epgStop');

        const epgStartDate = new Date(eventEpgStart);
        const epgStopDate = new Date(eventEpgStop);

        const time = moment(epgStartDate);
        const endTime = moment(epgStopDate);

        const eventId = time.unix();
        const name = _.get(event, 'name', '-');
        const shortDescription = _.get(event, ['ShortEventDescriptor', 'Text', '_'], '');
        const longDescription = _.get(event, ['ExtendedEventDescriptor', 'Text', '_'], '');
        const durationHours = moment(epgStopDate).diff(moment(epgStartDate), 'hours');
        const durationMinutes = moment(epgStopDate).subtract({hours: durationHours}).diff(moment(epgStartDate), 'minutes');
        const isCurrent = moment().isBetween(time, endTime);
        const isFinished = moment().isAfter(endTime);
        const isUpcoming = moment().isBefore(time);
        //const isOpen = isCurrent;

        if (isCurrent) {
          startFromIndex = index - epgFinishedEvents;
        }

        return {
          durationHours,
          durationMinutes,
          endTime,
          eventId,
          isCurrent,
          isFinished,
          isUpcoming,
          longDescription,
          name,
          shortDescription,
          time
        };
      });

    // Truncate shows to start from start of index
    if (startFromIndex !== null) {
      shows = _.slice(shows, (startFromIndex < 0 ? 0 : startFromIndex));
    }

    return (
      <Epg
        events={shows}
      />
    );
  }

  scrollLeft = e => {
    const tabListContainer = document.querySelector('.tab-links-list');
    const tabList = document.querySelector('.tab-links-list > ul.list-reset');
    const scrollStep = tabListContainer.clientWidth / tabList.children.length

    tabListContainer.scroll({ left: tabListContainer.scrollLeft - scrollStep, behavior: 'smooth' });

    if (tabListContainer.scrollLeft < tabListContainer.scrollWidth - tabListContainer.clientWidth + scrollStep) {
      this.setState({
        showRightArrow: true
      })
    }
    if (tabListContainer.scrollLeft === 0 || tabListContainer.scrollLeft < scrollStep) {
      this.setState({
        showLeftArrow: false
      })
    }
  }

  scrollRight = e => {
    const tabListContainer = document.querySelector('.tab-links-list');
    const tabList = document.querySelector('.tab-links-list > ul.list-reset');
    const scrollStep = tabListContainer.clientWidth / tabList.children.length

    tabListContainer.scroll({ left: tabListContainer.scrollLeft + scrollStep, behavior: 'smooth' });

    if (tabListContainer.scrollLeft >= tabListContainer.scrollWidth - tabListContainer.clientWidth - scrollStep) {
      this.setState({
        showRightArrow: false
      })
    }

    if (tabListContainer.scrollLeft > 0 - scrollStep) {
      this.setState({
        showLeftArrow: true
      })
    }
  }

  render() {
    const {
      channelId,
      assetId,
      epg,
      readyEpg,
      actionGetAssetEpg,
      actionGetChannelEpg,
      customAssetId,
      epgRefreshInterval,
      epgRange,
      newHome
    } = this.props;
    // const { showSlider } = this.state;
    let defaultAssetId = customAssetId || assetId;

    if (!defaultAssetId && !channelId) {
      return null;
    }

    if (!readyEpg) {
      return (
        <LoadingIcon
          className={`
          block
          m-auto
          text-center
          lg:h-64
          lg:w-64
        `}
        />
      )
    }

    // Periodically pull for new epg data every x seconds/minutes
    if (this.epgInterval === null) {
      this.epgInterval = setInterval(() => {
        if (channelId) {
          actionGetChannelEpg(channelId);
        } else if (defaultAssetId) {
          actionGetAssetEpg(defaultAssetId);
        }
      }, epgRefreshInterval);
    }

    // Process EPG from current time -> week (7 day period)
    const parsedEpg = this.formatEpgData(epg, epgRange);
    const { tabs, tabsContent } = parsedEpg;
    const epgTabsDom = <div className={newHome ? 'xl:max-w-1204 md:max-w-754 xs:max-w-361 w-full' : ''}>
      <section className="epg-tabs-nav flex flex-no-wrap items-center overflow-hidden border-b border-teal">
        <div className="left-arrow-wrap w-32 flex-none">
          {this.state.showLeftArrow ? <BackIcon
            className="hover:text-teal cursor-pointer"
            color="white"
            onClick={(e) => {
              this.scrollLeft(e);
              // this.onNext(e)
              // this.scrollTo("right")
            }}
          /> : null}
        </div>
        <div className="tab-links-list max-w-full overflow-x-hidden overflow-y-hidden scrolling-touch min-w-100percent-minus-arrowswidth">
          <ul
            className="list-reset inline-flex flex-no-wrap leading-base"
            ref={node => {
              this.tabsEl = node;
              if (!this.initialResize) {
                this.initialResize = true;
                this.showSlideHandler();
              }
            }}
          >
            {tabs}
            {/* <li><a href="#" className="inline-block align-middle px-16 py-8 no-underline">Monday</a></li> */}
          </ul>
        </div>
        <div className="right-arrow-wrap w-32 flex-none os-rotate-180">
          {this.state.showRightArrow && this.state.showSlider && tabs.length > 0 ? <BackIcon
            className="hover:text-teal cursor-pointer"
            color="white"
            onClick={(e) => {
              this.scrollRight(e);
              // this.onNext(e)
              // this.scrollTo("right")
            }}
          /> : null}
        </div>
      </section>

      {tabsContent}
    </div>
    return tabs && tabs.length > 0 ? (
      <>
        {newHome ? <div className='flex justify-center'>
          {epgTabsDom}
        </div> : epgTabsDom}
      </>

    ) : null;
  }
}

AssetEpg.propTypes = {
  channelId: PropTypes.string,
  assetId: PropTypes.string,
  actionGetAssetEpg: PropTypes.func,
  actionGetChannelEpg: PropTypes.func,
  customAssetId: PropTypes.number,
  epg: PropTypes.object,
  readyEpg: PropTypes.bool,
  epgFinishedEvents: PropTypes.number,
  epgRange: PropTypes.number,
  epgRefreshInterval: PropTypes.number
};

AssetEpg.defaultProps = {
  channelId: null,
  assetId: null,
  customAssetId: null,
  epg: {},
  readyEpg: false,
  // Make epgFinishedEvents, epgRange and epgRefreshInterval configurable later
  epgFinishedEvents: 2,
  epgRange: 7,
  epgRefreshInterval: 60 * 1000,
};

export default AssetEpg;


