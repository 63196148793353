import React from 'react';
import PropTypes from 'prop-types';
import { getPlaceholderTeamUrl } from 'utils/path';

const renderTeam = (team, reverse) => <span className={`leading-normal sm:w-137 sm:max-w-137 md:w-180 md:max-w-180 xl:w-269 xl:max-w-269 xl:w-305 xl:max-w-305 whitespace-no-wrap text-xs text-white xl:text-base truncate ${reverse ? 'mr-5 xl:mr-14 text-right' : 'ml-5 xl:ml-14'}`}>{team}</span>;

const renderLogo = imgUrl => <img alt='' className='w-24 h-24 xl:w-72 xl:h-72 object-contain' src={imgUrl || getPlaceholderTeamUrl()} />;

const FixtureTeam = ({imgUrl, team, className, reverse}) => (
  <div className={className}>
    <div className={`flex flex-row items-center`}>
      {
        !reverse ? renderLogo(imgUrl) : renderTeam(team, reverse)
      }
      {
        !reverse ? renderTeam(team, reverse) : renderLogo(imgUrl)
      }
    </div>
  </div>
);

FixtureTeam.propTypes = {
  /** URL to the team icon to be used. */
  imgUrl: PropTypes.string.isRequired,
  /** Team Name. */
  team: PropTypes.string.isRequired,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Whether the logo & team name is displayed in reverse. */
  reverse: PropTypes.bool
};

FixtureTeam.defaultProps = {
  className: null,
  reverse: false
};

export default FixtureTeam;
