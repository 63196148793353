import React from 'react';
import Input from '../ui/ui-kit/Input';
import Label from '../ui/ui-kit/Label';

// This is necessary due to Chrome's autofill behaviour
export function renderAutoFillBuster() {
  return (
    <Input className={`hidden`} type="password" name="hidden" value="1234" />
  );
}

export function InputPassword(props) {
  const {
    lockForm,
    showFields,
    handlePasswordChange,
    handleValidatePassword
  } = props;

  return (
    <React.Fragment>
      {renderAutoFillBuster()}
      <Input
        show={showFields.includes(`password`)}
        type="password"
        id="user-password"
        name="password"
        labelText="Password *"
        disabled={lockForm}
        placeHolder="Choose a strong password"
        changed={handlePasswordChange}
        showIcon={lockForm ? false : true}
        validate={true}
        validator={(value, e) => {
          return !handleValidatePassword(value)
            ? 'Password is not valid.'
            : null;
        }}
        marginBottom="8"
      />
      <div className="md:w-353">
        <Label textSize="text-xs leading-loose font-MarkProBold text-light-grey">
          Password must be 8 characters long and include one number and one
          capital letter.
        </Label>
      </div>
    </React.Fragment>
  );
}

export function TermsAndConditionsLink(props) {
  const { termsAndConditionsUrl, termsAndConditionsTitle = 'Terms & Conditions', tAndCClassNames = 'text-teal hover:text-teal-dark active:text-teal-light' } = props;
  return (
    <a
      href={termsAndConditionsUrl}
      target="_blank"
      className={`inline-block
      os-transition-btn
      font-MarkProBold
      ${tAndCClassNames}
      no-underline`}
      rel="noopener noreferrer"
    >
      {termsAndConditionsTitle}
    </a>
  );
}
