import React from 'react';
import PropTypes from 'prop-types';

const PlusIcon = ({ width, height, fill = '#FFF', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox={`0 0 32 32`}
    {...props}
  >
    <g fill={fill} fillRule='evenodd'>
      <path fill='none' d='M0 0h32v32H0z' />
      <path d='M25 15h-8V7h-2v8H7v2h8v8h2v-8h8z' />
    </g>
  </svg>
);

PlusIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PlusIcon.defaultProps = {
  width: 32,
  height: 32,
};

export default PlusIcon;
