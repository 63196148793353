import Button from 'components/ui/ui-kit/Button';
import React, { useRef, useState } from 'react';
import { getAbsoluteStaticUrl } from 'utils/path';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import CloseIcon from './ui-kit/Icons/CloseIcon';
import DisclaimerSection from './DisclaimerSection';
import BodyEnd from './ui-kit/BodyEnd';
import { dispatchAnalytic } from 'ducks/analytic';

const SubscribeViaSubhubSection = ({
  onSubscribeViaSubhubCardClick,
  getFeatureFlags,
  user,
  open,
}) => {
  const { subscribeViaOptusSection } = getFeatureFlags(`CHECKOUT_PAGE_CONFIG`);
  const {
    showSection,
    headingText,
    subscriptionSteps,
    disclaimerText,
    subscribeViaSubhubFormData: {
      postEnabled,
      postUrl,
      appBaseUrl,
      target,
      continueToSubHubLink,
    },
  } = subscribeViaOptusSection;
  const subscriptionStepsLength = subscriptionSteps && subscriptionSteps.length;
  const [submitting, setSubmitting] = useState(false);
  const buttonRef = useRef(null);
  if (!showSection) {
    return null;
  }
  const onContinueToSubhubClick = () => {
    setSubmitting(true);
    try {
      dispatchAnalytic({ name: 'SubHub Modal Continue' });
      if (postEnabled && buttonRef.current) {
        buttonRef.current.click();
      } else {
        if (continueToSubHubLink) {
          window.open(continueToSubHubLink, '_self');
        }
      }
    } catch {
      setSubmitting(false);
    }
    // after 10 seconds if user is still in the same page for some reason then enable the button back
    setTimeout(() => setSubmitting(false), 10000);
  };

  // hidden form to make post call once the user clicks continue to subhub bugtton. This form performs redirect.
  const form = postEnabled ? (
    <form className='hidden' action={postUrl} method='post'>
      {appBaseUrl && (
        <input type='hidden' name='appBaseUrl' value={appBaseUrl} />
      )}
      <input
        name='preferredEmail'
        value={
          user?.authData?.cognitoUser?.attributes?.email || 'subhub@test.com'
        }
      />
      <input name='target' value={target} />
      <button ref={buttonRef} type='submit' id='subhub-submit-button'>
        subhub
      </button>
    </form>
  ) : null;

  const SubhubModalDom = ({ onSubscribeViaSubhubCardClick }) => (
    <>
      {form}
      <h2
        className={`block text-black text-2xl leading-32 xxs:text-3xl xxs:leading-48 font-MarkProHeavy mb-32 sm:mb-72 text-left sm:text-center modal-title`}
        dangerouslySetInnerHTML={{ __html: headingText }}
      ></h2>
      <ul
        className={`
              text-center
              flex
              flex-wrap
              sm:flex-no-wrap
              justify-center
              list-reset
              mb-0
              sm:mb-80
              m-auto
            `}
      >
        {subscriptionSteps.map((step, i) => (
          <li
            className={`
                flex
                flex-row
                sm:flex-col
                items-start
                sm:items-center
                w-full
                mr-0 ${
                  i === subscriptionStepsLength - 1 ? 'sm:mr-0' : 'sm:mr-48'
                }
                mb-32 sm:mb-0
                px-0
              `}
            key={i}
          >
            <div className={`flex mr-16 sm:mr-0 mb-0 sm:mb-20`}>
              <img
                className={`w-32 h-32 sm:w-full sm:h-full max-w-32 max-h-32 sm:max-w-full sm:max-h-full`}
                src={getAbsoluteStaticUrl(step.imageUrl)}
                alt='Mobile &amp; Tablet'
              />
            </div>
            <div className={`text-left sm:text-center`}>
              <h2
                className={`
                  font-MarkProHeavy text-cyan-blue text-lg text-base leading-24px mb-4 text-left sm:text-center
                `}
              >
                {step.title}
              </h2>
              <p
                className={`
                  text-base
                  leading-24px
                  text-alt-grey
                  text-left
                  sm:text-center
                `}
              >
                {step.subText}
              </p>
            </div>
          </li>
        ))}
      </ul>
      <div
        className={`
            flex
            flex-col
            sm:flex-row
            items-center
            justify-center
            mb-24 sm:mb-16
            pl-0
          `}
      >
        <Button
          disabled={submitting}
          text='Continue to SubHub'
          theme='light'
          loading={submitting}
          className='min-w-full sm:min-w-240 md:min-w-294 lg:min-w-343 max-w-343 mr-0 sm:mr-16'
          buttonClassName='min-h-48 min-w-full sm:min-w-240 md:min-w-294 lg:min-w-343  max-w-343 text-base leading-24px mb-16 sm:mb-0'
          handler={onContinueToSubhubClick}
        ></Button>
        <Button
          type='secondary'
          text={`Cancel`}
          className='min-w-full sm:min-w-240 md:min-w-294 lg:min-w-343 max-w-343'
          buttonClassName='min-h-48 min-w-full sm:min-w-240 md:min-w-294 lg:min-w-343 text-base text-cyan-blue leading-24px cursor-pointer border-cyan-blue'
          handler={() => onSubscribeViaSubhubCardClick(false)}
        ></Button>
      </div>
      {disclaimerText && (
        <DisclaimerSection
          disclaimerText={disclaimerText}
          containerClassNames={'mt-0'}
        />
      )}
    </>
  );
  return (
    <BodyEnd>
      <div
        className={`fixed h-modal overflow-auto pin-l pin-t modal_background my-8 mx-7 backdrop-blur modal-overlay subhub-modal-background ${
          open
            ? 'flex modal-overlay-open z-400'
            : 'hidden modal-overlay-close z--1'
        } `}
        onClick={() => onSubscribeViaSubhubCardClick(false)}
      >
        <div
          className={`relative overflow-auto lg:max-h-580 subhub-modal-wrapper px-16 sm:px-32 py-24 sm:py-56 pb-64 sm:pb-56 opacity-100 mr-32 ml-32 flex items-start justify-center
                relative overflow-auto white shadow-xl modal-overlay-child z-400  ${
                  open
                    ? 'modal-overlay-child-open'
                    : 'modal-overlay-child-close'
                }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className='w-full'>
            <section className='w-full'>
              <SubhubModalDom
                onSubscribeViaSubhubCardClick={onSubscribeViaSubhubCardClick}
              />
            </section>
          </div>
          <div
            className={`w-32 h-32 absolute pint-t pin-r mt-7 mr-7 md:mt-16 md:mr-10 os-top-0`}
          >
            <button
              className={`h-32 w-32 text-black focus:outline-none`}
              onClick={() => onSubscribeViaSubhubCardClick(false)}
            >
              <CloseIcon color='black' className='h-16 w-16' />
            </button>
          </div>
        </div>
      </div>
    </BodyEnd>
  );
};
export default withFeatureFlags(SubscribeViaSubhubSection);
