import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from 'actions/user';
import { getUserEmail, user as userSelector, isLoggedIn } from 'selectors/user';
import Button from 'components/ui/ui-kit/Button';
import H3 from 'components/ui/ui-kit/Typography/H3';
import { get } from 'lodash';

const Logout = ({ isInModal, onModalClose, recaptchaRef }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isUserLoggedIn = isLoggedIn({ user });
  const inProgress = get(user, 'inProgress', false);

  const onLogout = (e) => {
    e.stopPropagation();
    recaptchaRef.current && recaptchaRef.current.reset();
    dispatch(logoutUser());
  };

  if (isUserLoggedIn) {
    const userEmail = getUserEmail(user);

    return (
      <div
        className={`page login mx-auto px-14 w-269 xs:w-350 md:w-428 ${
          isInModal ? 'my-64' : 'my-80 md:my-144'
        }`}
      >
        <div className='flex flex-col w-full justify-center text-center'>
          <H3 className='leading-36 sm:leading-40'>
            Are you sure you want to log out of this account?
          </H3>
          <p className='leading-lg'>
            You will be logged out of{' '}
            <span className='font-MarkProBold'>{userEmail}</span>
          </p>
          <div className='flex flex-col mt-56 gap-16'>
            <Button
              type='primary'
              disabled={inProgress}
              loading={inProgress}
              handler={(e) => onLogout(e)}
              text='Log Out'
              width='w-full'
            />
            <Button
              text='Close'
              disabled={inProgress}
              handler={(e) => onModalClose(e)}
              width='w-full mx-auto'
              type='secondary'
            />
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default Logout;
