import React from 'react';

import PromotionalBanner from 'components/ui/PromotionalBanner';
import { withFeatureFlags } from 'components/context/withFeatureFlags';

function SignupPromotionalBanner({ getFeatureFlags }) {
  const subhubPromoActive = getFeatureFlags('SUBHUB_PROMO_ACTIVE');
  if (!subhubPromoActive) {
    return null;
  }
  const {
    promotionalBannerSection: { imgURL, imgAlt, title, text, ctaText, ctaLink },
  } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);

  return (
    <PromotionalBanner
      imgURL={imgURL}
      imgAlt={imgAlt}
      title={title}
      text={<span dangerouslySetInnerHTML={{ __html: text }} />}
      ctaText={ctaText}
      ctaLink={ctaLink}
    />
  );
}

export default withFeatureFlags(SignupPromotionalBanner);
