import React from 'react';
import PropTypes from 'prop-types';
import Login from 'components/views/Login';
import CloseIcon from './ui-kit/Icons/CloseIcon';

const LoginFormModal = ({
  showModal,
  closeModal,
  dismissOnOutsideClick,
  location,
  ...props
}) => {
  const closeLoginFormModal = (e) => {
    if (typeof closeModal === 'function') {
      closeModal(e);
    }
    e && e.stopPropagation();
  };

  return (
    <div
      className={`fixed pin-l pin-t modal_background backdrop-blur modal-overlay w-full h-full m-0
          ${
            showModal
              ? 'flex modal-overlay-open z-99999'
              : 'hidden modal-overlay-close z--1'
          }
        `}
      onClick={(e) => {
        if (showModal) {
          dismissOnOutsideClick && closeLoginFormModal(e);
        }
      }}
    >
      <div
        className='relative bg-darkest-grey m-auto px-16 sm:px-56 rounded-xl'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className='w-32 h-32 absolute pint-t pin-l mt-7 ml-7 md:mt-16 md:ml-10'>
          <button
            className={`h-32 w-32 text-white focus:outline-none cursor-pointer`}
            onClick={closeLoginFormModal}
          >
            <CloseIcon />
          </button>
        </div>
        <Login
          showSignup={true}
          isInModal={true}
          onCloseModal={closeLoginFormModal}
          referrer={location.pathname}
          {...props}
        />
      </div>
    </div>
  );
};

LoginFormModal.propTypes = {
  /** Current state of this LoginModal to display or not */
  showModal: PropTypes.bool,
  /** Handler function for the LoginModal close button. This function will be invoked with the click event received on the close button */
  closeModal: PropTypes.func,
  /** Whether to close this LoginModal when the user clicks outside of the LoginModal */
  dismissOnOutsideClick: PropTypes.bool,
};

LoginFormModal.defaultProps = {
  showModal: false,
  closeModal: null,
  dismissOnOutsideClick: true,
};

export default LoginFormModal;
