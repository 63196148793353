import React from 'react';
import Button from './ui-kit/Button';

const GenericActionButton = (props) => (
  <Button
    className="mb-16 flex justify-center w-full"
    width="w-full"
    rounded
    {...props}
  />
);

export default GenericActionButton;
