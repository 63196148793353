import React from 'react';

import Banner from './layouts/Banner';
import DynamicRail from './DynamicRail';
import DynamicContentCard from './components/DynamicContentCard';

function getCustomWidget({ carousel, getCarouselLayoutParams }) {
  const { assetId, contentCardLocationId, type } = carousel || {};
  switch (type) {
    case 'banner':
      return <Banner assetId={assetId} key={assetId} />;

    case 'dynamic_rail':
      return (
        <DynamicRail
          assetId={assetId}
          key={assetId}
          getCarouselLayoutParams={getCarouselLayoutParams}
        />
      );

    case 'content_card_location':
      return (
        <DynamicContentCard
          contentCardLocationId={contentCardLocationId}
          key={contentCardLocationId}
          contentCardUiComponent={Banner}
        />
      );

    default:
      return <></>;
  }
}

export default getCustomWidget;
