function getAssetIdByLocationId(dynamicContentCards, contentCardLocationId) {
  const foundDynamicContentCard =
    dynamicContentCards?.find((dynamicContentCard) =>
      dynamicContentCard.hasOwnProperty(contentCardLocationId),
    ) || undefined;
  const assetId =
    foundDynamicContentCard && foundDynamicContentCard[contentCardLocationId];
  return assetId;
}

export default getAssetIdByLocationId;
