import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import jsCookie from 'js-cookie';
import 'react-day-picker/lib/style.css';
import every from 'lodash/every';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import remove from 'lodash/remove';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserEmail,
  getUserId,
  isLoggedIn,
  user as userSelector,
} from 'selectors/user';
import { parseTemplate } from 'parser/templateParser';
import LoadingIcon from '../ui-kit/Icons/LoadingIcon';
import {
  fillFormFieldsWithApiValues,
  getAllFormFields,
  getFavouriteTeam,
  groupAllQuestionAnswers,
} from 'parser/utils';
import {
  ERRORS,
  SUBMIT_OS_COMPETITION_ENTRY,
  submitUserCompetitionEntry,
} from 'actions/userCompetition';
import { fail, start } from 'middleware/fetch';
import LogoutFormModal from '../LogoutFormModal';
import { usePrevious } from 'utils/hooks';
import { analyticAction } from 'ducks/analytic';
import {
  CHECKOUT_INITIATED_FROM_COOKIE_NAME,
  SIGNUP_INITIATED_FROM_COOKIE_NAME,
  SIGNUP_INITIATED_FROM_COOKIE_VALUES,
} from 'utils/cookies';
import renderLogin from './renderLogin';
import renderCompetitionNotStarted from './renderCompetitionNotStarted';
import renderFormSubmitError from './renderFormSubmitError';
import renderSuccess from './renderSuccess';
import { renderCompetitionClosed, renderNotEligible } from './renderInfo';
import { renderSubscribe } from './renderSubscribe';

const renderForm = (template, styles, props) => {
  return (
    <div className={styles} key='div_1'>
      {parseTemplate(template?.elements, props)}
    </div>
  );
};

const renderLoading = (styles) => {
  return (
    <div
      className={`flex justify-center items-center w-full h-256 sm:h-400 ${styles}`}
    >
      <LoadingIcon className='block text-center h-64 w-64' />
    </div>
  );
};

const UserCompetitionForm = ({ template, styles, templateProps }) => {
  const {
    isCompetitionClosed,
    isCompetitionStarted,
    competitionStartDateUtc,
    pathCompetitionId,
    didUserLogOut,
  } = templateProps;

  const location = useLocation();
  // console.log('=====location======', location);
  const history = useHistory();
  const dispatch = useDispatch();
  const recaptchaRef = useRef();

  const userLoggedIn = useSelector(isLoggedIn);
  const user = useSelector(userSelector);
  const userCompetitionStateObj = useSelector((state) => state.userCompetition);
  const email = getUserEmail(user);

  const [formFieldValues, setFormFieldValues] = useState(
    getAllFormFields(template),
  );
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  const profileData = get(user, 'profile', {});
  const updateProfileInProgress = get(profileData, 'inProgress', false);
  const settingsInProgress = get(user, 'settings.inProgress', false);
  const inProgress =
    get(userCompetitionStateObj, ['inProgress'], false) ||
    updateProfileInProgress ||
    settingsInProgress;

  const userCompetitionObj =
    find(get(userCompetitionStateObj, ['competitions'], []), {
      id: pathCompetitionId,
    }) || {};

  const errorCode = get(userCompetitionStateObj, 'error.code', '');

  const isFormSubmitSuccess =
    get(userCompetitionStateObj, 'isFormSubmitSuccess', null) ||
    errorCode === ERRORS.NOT_ELIGIBLE_ENTERED;

  const isServerError = errorCode === ERRORS.SERVER_ERROR;

  const authInProgress = get(user, 'inProgress', false);
  const loginFailure = get(user, 'loginFailure', false);
  const prevAuthInProgress = usePrevious(authInProgress);

  useEffect(() => {
    if (prevAuthInProgress && !authInProgress) {
      setFormFieldValues(getAllFormFields(template));
      if (!loginFailure) {
        setShowLoginModal(false);
      }
      setShowLogoutModal(false);
    }
    // eslint-disable-next-line
  }, [authInProgress, prevAuthInProgress, loginFailure]);

  useEffect(() => {
    setIsLoading(
      (userLoggedIn && isEmpty(profileData)) ||
        inProgress ||
        isNil(isCompetitionStarted) ||
        isNil(isCompetitionClosed),
    );

    // eslint-disable-next-line
  }, [
    userLoggedIn,
    profileData,
    inProgress,
    isCompetitionStarted,
    isCompetitionClosed,
  ]);

  useEffect(() => {
    if (!isEmpty(profileData)) {
      profileData.email = email;
      setFormFieldValues((prevValues) => {
        const filledValues = fillFormFieldsWithApiValues(
          prevValues,
          profileData,
        );
        return isEqual(prevValues, filledValues) ? prevValues : filledValues;
      });
    }
  }, [profileData, email]);

  useEffect(() => {
    setAllFieldsValid(every(formFieldValues, { isValid: true }));
  }, [formFieldValues]);

  const onFieldChanged = (id, value) => {
    setFormFieldValues((prevValues) => {
      const updatedFieldValues = prevValues.map((v) =>
        v.id === id ? { ...v, value } : v,
      );
      if (isEqual(prevValues, updatedFieldValues)) {
        return prevValues;
      }
      return updatedFieldValues;
    });
  };

  const triggerCompetitionAnalytics = ({ profileUpdated }) => {
    return (dispatch) => {
      const competitionId = get(userCompetitionObj, ['id'], '');

      const competitionTitle = get(
        userCompetitionObj,
        ['competitionDetails', 'metadata', 'title'],
        '',
      );

      let userJourney = didUserLogOut ? 'login_another' : 'login';

      const referrer = get(location, ['state', 'referrer', 'pathname'], '');

      if (referrer === '/register') {
        const signupInitiatedFrom = jsCookie.get(
          SIGNUP_INITIATED_FROM_COOKIE_NAME,
        );

        userJourney =
          signupInitiatedFrom ===
          SIGNUP_INITIATED_FROM_COOKIE_VALUES.USER_COMPETITION_SIGNUP
            ? 'signup'
            : signupInitiatedFrom ===
              SIGNUP_INITIATED_FROM_COOKIE_VALUES.USER_COMPETITION_SIGNUP_ANOTHER
            ? 'signup_another'
            : userJourney;

        jsCookie.remove(SIGNUP_INITIATED_FROM_COOKIE_NAME);
      }
      jsCookie.remove(CHECKOUT_INITIATED_FROM_COOKIE_NAME);

      dispatch(
        analyticAction({
          eventName: 'Competition Entered',
          competition_id: competitionId,
          competition_title: competitionTitle,
          profile_updated: profileUpdated,
          user_journey: userJourney,
        }),
      );
    };
  };

  const onFormSubmit = () => {
    try {
      dispatch({
        type: start(SUBMIT_OS_COMPETITION_ENTRY),
      });

      const userId = getUserId(user);
      const competitionId = get(userCompetitionObj, ['id'], '');
      if (!allFieldsValid) {
        dispatch({
          type: fail(SUBMIT_OS_COMPETITION_ENTRY),
          payload: {
            error: {
              code: 'VALIDATION_FAILURE',
            },
          },
        });
      }

      const fieldsToSubmit = filter(formFieldValues, { sendToApi: true });

      const answers = groupAllQuestionAnswers(fieldsToSubmit);
      const { teamInternalIds, favouriteTeam } = getFavouriteTeam(
        fieldsToSubmit,
      );

      // remove data that is already taken into account
      remove(
        fieldsToSubmit,
        (f) =>
          startsWith(f.id, 'question-') ||
          startsWith(f.id, 'answer-') ||
          f.id === 'favouriteTeam',
      );

      const submitPayload = fieldsToSubmit.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.value }),
        {},
      );
      submitPayload.favouriteTeam = favouriteTeam;
      submitPayload.answers = answers;

      dispatch(
        submitUserCompetitionEntry(
          competitionId,
          userId,
          teamInternalIds,
          submitPayload,
          triggerCompetitionAnalytics,
        ),
      );
    } catch (e) {
      console.error(e);
      dispatch({
        type: fail(SUBMIT_OS_COMPETITION_ENTRY),
        payload: { error: e },
      });
    }
  };

  const onFieldValidationCallback = (id, isValid) => {
    setFormFieldValues((prevValues) => {
      const updatedFieldValues = prevValues.map((v) =>
        v.id === id ? { ...v, isValid } : v,
      );
      if (isEqual(prevValues, updatedFieldValues)) {
        return prevValues;
      }
      return updatedFieldValues;
    });
  };

  const showLogoutModalFn = (e) => {
    e.preventDefault();
    setShowLogoutModal(true);
  };

  const showLoginModalFn = (e) => {
    e.preventDefault();
    setShowLoginModal(true);
  };

  const redirectToRegister = (e) => {
    e.preventDefault();
    if (didUserLogOut) {
      jsCookie.set(
        SIGNUP_INITIATED_FROM_COOKIE_NAME,
        SIGNUP_INITIATED_FROM_COOKIE_VALUES.USER_COMPETITION_SIGNUP_ANOTHER,
      );
    } else {
      jsCookie.set(
        SIGNUP_INITIATED_FROM_COOKIE_NAME,
        SIGNUP_INITIATED_FROM_COOKIE_VALUES.USER_COMPETITION_SIGNUP,
      );
    }
    history.push('/register', {
      redirectToJustAfterRegister: location.pathname,
      referrer: location,
    });
  };

  const redirectToForgotPassword = (e) => {
    e.preventDefault();
    history.push({
      pathname: '/forgotpassword',
      state: { referrer: location },
    });
  };

  const actionsList = {
    LOGIN: showLogoutModalFn,
    SHOW_LOGOUT_MODAL: showLogoutModalFn,
    SHOW_LOGIN_MODAL: showLoginModalFn,
    REDIRECT_TO_REGISTER: redirectToRegister,
    REDIRECT_TO_FORGOT_PASSWORD: redirectToForgotPassword,
  };
  templateProps.actionsList = actionsList;

  if (isLoading) {
    return renderLoading(styles);
  }

  if (isCompetitionStarted === false) {
    return renderCompetitionNotStarted(styles, competitionStartDateUtc);
  }

  if (isCompetitionClosed) {
    const winnersList = get(
      userCompetitionObj,
      ['competitionDetails', 'winners'],
      [],
    );

    const noWinnersText = get(
      userCompetitionObj,
      ['competitionDetails', 'noWinners'],
      '',
    );

    return renderCompetitionClosed(styles, winnersList, noWinnersText);
  }

  if (!userLoggedIn) {
    const templateBlock = find(template?.elements, { type: 'LOGIN_STATE' });
    return (
      <div className={styles} key='div_1'>
        {renderLogin(
          templateBlock,
          templateProps,
          showLoginModalFn,
          redirectToRegister,
          redirectToForgotPassword,
          showLoginModal,
          setShowLoginModal,
          didUserLogOut,
          location,
        )}
      </div>
    );
  }

  if (errorCode === ERRORS.NOT_ELIGIBLE_PREMIUM) {
    return renderSubscribe(styles, history, location);
  }

  if (errorCode === ERRORS.NOT_ELIGIBLE) {
    return renderNotEligible(styles);
  }

  if (isFormSubmitSuccess) {
    return renderSuccess(styles);
  }

  if (isServerError) {
    return renderFormSubmitError(styles);
  }

  // console.log('=====formFieldValues=====', formFieldValues);
  return (
    <>
      {userLoggedIn && (
        <LogoutFormModal
          showModal={showLogoutModal}
          onClose={(e) => {
            setShowLogoutModal(false);
          }}
          dismissOnOutsideClick={true}
          didUserLogOut={didUserLogOut}
          recaptchaRef={recaptchaRef}
        />
      )}
      {renderForm(template, styles, {
        ...templateProps,
        formFieldValues,
        formState: {
          allFieldsValid,
          isSubmitted: isFormSubmitSuccess,
          isCompetitionClosed,
          isLoading,
        },
        recaptchaRef,
        onFieldChanged,
        onFieldValidationCallback,
        onFormSubmit,
      })}
    </>
  );
};

export default UserCompetitionForm;
