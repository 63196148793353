import React from 'react';
import PropTypes from 'prop-types';

const FailIcon = ({ width, height, viewBox, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
        <g fill="#FF126D" fillRule="evenodd">
            <path fillRule="nonzero" d="M72.228 139c37.003 0 67-29.997 67-67s-29.997-67-67-67-67 29.997-67 67 29.997 67 67 67zm0 5c-39.764 0-72-32.235-72-72s32.236-72 72-72c39.765 0 72 32.235 72 72s-32.235 72-72 72z" />
            <path d="M92.927 50.858l3.535 3.535-43.84 43.841-3.536-3.536z" />
            <path d="M52.621 50.858l-3.535 3.535 43.84 43.841 3.536-3.536z" />
        </g>
    </svg>
);

FailIcon.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string
};

FailIcon.defaultProps = {
    width: 144,
    height: 144,
    viewBox: '0 0 144 144'
};

export default FailIcon;



