import React, { useState, useEffect } from 'react';
import { usePrevious } from '../../../utils/hooks';
import PropTypes from 'prop-types';
import ValidIcon from './Icons/FieldValidIcon';
import InvalidIcon from './Icons/FieldInvalidIcon';
import WarningIcon from './Icons/WarningIcon';
import CloseIcon from './Icons/CloseIcon';
import Label from './Label';
import InfoIcon2 from './Icons/InfoIcon2';

//Message types constants
export const TYPE_SUCCESS = 'success';
export const TYPE_WARNING = 'warning';
export const TYPE_INFO_WARNING = 'info-warning';
export const TYPE_FAILURE = 'failure';

/**
 * Alerts/Messages component
 */
// class Message extends React.Component {

const Message = (props) => {
  const {
    open,
    title,
    onPress,
    message,
    messageHtml,
    onClose,
    showIcon,
    iconPositionClass,
    showClose,
    type,
    className,
    actions,
    width,
    titleClassName,
    titleTextSize,
    messageClassName,
    messageTextSize,
    backgroundClassName,
  } = props;

  const [exited, setExited] = useState(false);

  const prevState = usePrevious(exited);

  useEffect(() => {
    // if open changes then set exited to false
    if (typeof prevState === 'undefined') {
      setExited(!open);
      return;
    }
    if (open) {
      setExited(false);
      return;
    } else {
      setExited(null);
    }
    // eslint-disable-next-line
  }, [open]);

  const handleExit = () => setExited(true);

  const handleClose = (e) => {
    if (onPress !== null && typeof onPress === 'function') {
      onPress();
      return;
    }
    if (onClose !== null && typeof onClose === 'function') {
      const close = onClose(e, 'click');
      if (close === false) {
        return;
      }
      handleExit();
    } else {
      handleExit();
    }
  };

  const borderColor = (type) => {
    switch (type) {
      case TYPE_FAILURE:
        return 'border-live-red';
      case TYPE_INFO_WARNING:
      case TYPE_WARNING:
        return 'border-yellow';
      case TYPE_SUCCESS:
      default:
        return 'border-green';
    }
  };

  const renderIcon = (type) => {
    switch (type) {
      case TYPE_SUCCESS:
        return <ValidIcon />;
      case TYPE_FAILURE:
        return <InvalidIcon />;
      case TYPE_INFO_WARNING:
        return <InfoIcon2 />;
      case TYPE_WARNING:
        return <WarningIcon />;
      default:
        return null;
    }
  };

  return !open && exited ? null : (
    <div
      style={!isNaN(width) ? { width: `${width}rem` } : null}
      className={` ${width} ${backgroundClassName} ${borderColor(
        type,
      )} border-l-8 flex flex-row flex-no-wrap pl-6 pr-8 pt-8 pb-8 rounded-lg ${
        className !== null ? ` ${className}` : ''
      }`}
    >
      {showIcon && type && (
        <div className={`flex pr-8 ${iconPositionClass}`}>
          {renderIcon(type)}
        </div>
      )}
      <div className='flex flex-col flex-no-wrap my-8 flex-grow'>
        {title && (
          <Label className={titleClassName} textSize={titleTextSize}>
            {title}
          </Label>
        )}
        {message ? (
          <Label className={messageClassName} textSize={messageTextSize}>
            {message}
          </Label>
        ) : null}
        {messageHtml ? (
          <div
            className={`${messageClassName} ${messageTextSize}`}
            dangerouslySetInnerHTML={{
              __html: messageHtml,
            }}
          ></div>
        ) : null}
        {actions && <div className='pt-8 mb-minus8'>{actions}</div>}
      </div>
      {showClose && (
        <div
          className='flex items-center ml-8 flex-no-shrink text-white cursor-pointer'
          onClick={handleClose}
        >
          <div className={`h-32 w-32 flex justify-center items-center`}>
            <CloseIcon />
          </div>
        </div>
      )}
    </div>
  );
};

Message.propTypes = {
  /** Title text. Defaults to ``null``. */
  title: PropTypes.string,
  /** Whether to show a close button for this message. */
  showClose: PropTypes.bool,
  /** Message content to show */
  message: PropTypes.any,
  /** HTML Message content to show */
  messageHtml: PropTypes.string,
  /** HTML Message content to show */
  footerLink: PropTypes.string,
  /** Width supports a Tailwind width class passed on as string or a fixed width passed on as number in rem.**/
  width: PropTypes.string,
  /** Whether to show the icons. Defaults to true */
  showIcon: PropTypes.bool,
  /** Type of this message.. */
  type: PropTypes.oneOf(['success', 'failure', 'warning', 'info-warning']),
  /** If true, `Message` is opened. */
  open: PropTypes.bool,
  /** Function to invoke when the user clicks on the close button.*/
  onClose: PropTypes.func,
  /** Function to invoke when the user clicks on the close button with a customised outcome.*/
  onPress: PropTypes.func,
  /** Any additional Tailwind classes to apply */
  className: PropTypes.string,
  /** Node with action link button */
  actions: PropTypes.node,
  /** Any additional Tailwind classes to apply for title */
  titleClassName: PropTypes.string,
  /** Title text size override */
  titleTextSize: PropTypes.string,
  /** Any additional Tailwind classes to apply for message */
  messageClassName: PropTypes.string,
  /** Message text size override */
  messageTextSize: PropTypes.string,
  /** Background color override */
  backgroundClassName: PropTypes.string,
  /** Icon Position override */
  iconPositionClass: PropTypes.string,
};

//Default props
Message.defaultProps = {
  title: null,
  width: 'w-full',
  type: 'success',
  showIcon: true,
  open: false,
  onClose: null,
  onPress: null,
  actions: null,
  titleClassName: 'font-MarkProBold mb-8',
  titleTextSize: 'text-sm',
  messageClassName: 'leading-base',
  messageTextSize: 'text-xs',
  backgroundClassName: 'bg-dark-grey',
  messageHtml: '',
  iconPositionClass: 'items-center',
};

export default Message;
