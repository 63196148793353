import React from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import Label from '../../../common/InputLabel';
import { Container } from './styles';
import { getAbsoluteStaticUrl } from 'utils/path';

class CardFieldInput extends React.Component {
  state = {};

  inputRef = React.createRef();

  // TODO: Hack to get the focus style on the parent rather than the iframe
  toggleFocus = (focused) => {
    const { current } = this.inputRef;
    if (!current) {
      return;
    }
    current.style.outline = focused ? `-webkit-focus-ring-color auto 5px` : ``;
    const callback = focused ? this.props.onFocus : this.props.onBlur;
    if (callback) {
      callback(current);
    }
  };

  onEmpty = () => {
    if (!this.state.loaded) {
      this.setState({ loaded: true });
    }
  };

  onBrand = (eventDetails = {}) => {
    const { brand } = eventDetails;
    const { allowedCardTypes = [] } = this.props;

    // From Cleeng, `mastercard` is returned as `mc` so a corresponding `mc.svg` was added.
    // Made a request to Cleeng to use `mastercard` instead of `mc`.
    const showCardImage = !isEmpty(brand) && allowedCardTypes.includes(brand);
    const cardImage = showCardImage
      ? `url(${getAbsoluteStaticUrl(`/images/cards/${brand}.svg`)})`
      : `none`;
    this.inputRef.current.style.backgroundImage = cardImage;
  };

  createField = () => {
    const style = {
      base: {
        color: `white !important`,
        background: `inherit`,
        // TODO: Consult with Bambora on injecting custom font.
        fontFamily: `MarkPro, Arial, Helvetica, sans-serif`,
        fontSize: `100%`,
        paddingTop: `0.95rem`,
        paddingBottom: `0.50rem`,
        paddingLeft: `16px`,
        paddingRight: `16px`,
      },
    };
    const {
      checkout,
      type,
      field,
      placeholder,
      allowedCardTypes = [],
    } = this.props;
    let options = {
      placeholder,
      style,
    };

    if (field === 'card-number') {
      options.brands = allowedCardTypes;
    }

    const inputField = checkout.create(type, options);
    inputField.mount(`#${field}`);
    return inputField;
  };

  onValidate = (valid, eventDetails) => {
    const { message = `Unknown error` } = eventDetails || {};
    this.setState({
      error: !valid && message,
    });
    if (this.props.onValidate) {
      const { field } = this.props;
      this.props.onValidate(field, valid);
    }
  };

  componentDidUpdate(prevProps) {
    const { event, type, checkout } = this.props;
    const { created } = this.state;
    if (!created && checkout) {
      this.createField();
      this.setState({ created: true });
      return;
    }
    if (!event) {
      return;
    }
    const { event: prevEvent } = prevProps;
    if (isEqual(event, prevEvent)) {
      return;
    }
    const [[eventName, eventDetails]] = Object.entries(event);
    const { field } = eventDetails;
    if (field !== type) {
      return;
    }
    switch (eventName) {
      case `focus`:
        this.toggleFocus(true, eventDetails);
        break;
      case `blur`:
        this.toggleFocus(false, eventDetails);
        break;
      case `brand`:
        this.onBrand(eventDetails);
        break;
      case `empty`:
        this.onEmpty();
        break;
      case `complete`:
        this.onValidate(true);
        break;
      case `error`:
        const newEventDetails =
          eventDetails?.type === 'CardNumberInvalid'
            ? {
                message: 'We are unable to accept this card.',
              }
            : {};
        this.onValidate(false, { ...eventDetails, ...newEventDetails });
        break;
      default:
        throw new Error(`CardFieldInput: Invalid event ${eventName}`);
    }
  }

  render() {
    const { error, created, loaded } = this.state;
    const {
      field,
      label,
      disabled,
      children,
      allowedCardTypes = [],
    } = this.props;
    const isDisabled = disabled || !created || !loaded;
    const disabledStyle = isDisabled ? `bg-medium-grey` : `bg-dark-grey`;
    const containerStyle = children ? `relative` : ``;

    let displayCardType;
    if (field === 'card-number') {
      displayCardType = (
        <div
          id='card-type-group'
          className={`inline-flex relative os-top-8`}
          ref={(cardTypeGroup) => (this.cardTypeGroup = cardTypeGroup)}
        >
          {allowedCardTypes.map((brand) => (
            <img
              className={`h-32 w-auto`}
              src={getAbsoluteStaticUrl(`/images/cards/${brand}.svg`)}
              alt={brand}
              key={brand}
            />
          ))}
        </div>
      );
    }

    return (
      <>
        {displayCardType ? (
          <div className='flex items-end'>
            <Label
              htmlFor={field}
              id={`${field}-error`}
              textSize='text-sm'
              className='flex-auto font-MarkProBold os-transition os-transitionproperty-all text-left'
              color={error ? 'text-live-red' : null}
            >
              {label}
            </Label>
            {displayCardType}
          </div>
        ) : (
          <Label
            htmlFor={field}
            id={`${field}-error`}
            textSize='text-sm'
            className='font-MarkProBold os-transition os-transitionproperty-all text-left'
            color={error ? 'text-live-red' : null}
          >
            {label}
          </Label>
        )}
        <Container
          id={field}
          className={`
            card-field
            ${containerStyle}
            ${disabledStyle}
            focus-within:border-teal-500
            ${error ? 'border-error-red' : 'border-light-grey'}
            rounded xs:mt-8
          `}
          ref={this.inputRef}
          tabIndex='1'
        >
          {children}
        </Container>
        {error && (
          <Label isError verticalPush={4}>
            {error}
          </Label>
        )}
      </>
    );
  }
}

export default CardFieldInput;
