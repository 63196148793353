import React from 'react';
import { isEmpty } from 'lodash';
import Button from '../ui-kit/Button';
import Link from '../ui-kit/Link';
import H3 from '../ui-kit/Typography/H3';
import { SIGNUP_INITIATED_FROM_COOKIE_VALUES } from 'utils/cookies';
import LoginFormModal from '../LoginFormModal';
import { parseTemplate } from 'parser/templateParser';

const renderLogin = (
  templateBlock,
  templateProps,
  showLoginModalFn,
  redirectToRegister,
  redirectToForgotPassword,
  showLoginModal,
  setShowLoginModal,
  didUserLogOut,
  location,
) => {
  return (
    <>
      <LoginFormModal
        showModal={showLoginModal}
        closeModal={() => setShowLoginModal(false)}
        dismissOnOutsideClick={true}
        //  history={history}
        location={location}
        signupInitiatedFrom={
          didUserLogOut
            ? SIGNUP_INITIATED_FROM_COOKIE_VALUES.USER_COMPETITION_SIGNUP_ANOTHER
            : SIGNUP_INITIATED_FROM_COOKIE_VALUES.USER_COMPETITION_SIGNUP
        }
      />
      {isEmpty(templateBlock)
        ? renderLoginForm(
            showLoginModalFn,
            redirectToRegister,
            redirectToForgotPassword,
          )
        : parseTemplate(templateBlock.elements, templateProps)}
    </>
  );
};

const renderLoginForm = (
  showLoginModalFn,
  redirectToRegister,
  redirectToForgotPassword,
) => {
  return (
    <div className='flex flex-col max-w-570 xs:items-center m-0-auto'>
      <H3 className='block text-center text-xl sm:text-2xl lg:text-3xl leading-36 lg:leading-48'>
        Log in or sign up to get access to this competition
      </H3>
      <Button
        type='submit'
        disabled={false}
        handler={showLoginModalFn}
        className='my-10'
        text='Log in'
        width='w-full xs:w-296'
      />
      <h3 className='line-around-text'>
        <span>or</span>
      </h3>
      <span className='block text-center text-xs pt-10'>
        <Button
          text='Sign up to enter'
          handler={redirectToRegister}
          width='w-full xs:w-296 mx-auto'
          type='secondary'
        />
      </span>
      <div className='pt-24'>
        <span className='block text-center text-xs'>
          <Link
            to='/forgotpassword'
            target={'self'}
            onClick={redirectToForgotPassword}
          >
            Forgot password?
          </Link>
        </span>
      </div>
    </div>
  );
};

export default renderLogin;
