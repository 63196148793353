import React, { useRef } from 'react';
import { getAbsoluteStaticUrl } from '../../../utils/path';
import { useState } from 'react';
import { withFeatureFlags } from 'components/context/withFeatureFlags';

const LogosSection = ({ getFeatureFlags }) => {
  const [showMore, setShowMore] = useState(false);
  const logosSectionRef = useRef();
  const { logosSection } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);
  if (!logosSection?.showSection) return null;

  const { headingText, logos } = logosSection;

  const renderLogos = () => {
    const allLogos = logos.map((logo, i) => (
      <li className={`h-128 w-127 mb-32 lg:mb-48`} key={i}>
        <img
          src={getAbsoluteStaticUrl(logo.url)}
          alt={logo.alt}
          width='100%'
          height='auto'
        />
      </li>
    ));

    if (allLogos.length <= 6) {
      return allLogos;
    }
    const firstPartLogosForMobile = allLogos.slice(0, 6);
    const secondPartLogosArray = logos.slice(6, allLogos.length);
    const secondPartLogosForMobile = secondPartLogosArray.map((logo, i) => (
      <li
        className={`h-128 w-127 mb-32 lg:mb-48 ${
          showMore
            ? 'signup-whatson-show-full-items'
            : 'signup-whatson-hide-few-items'
        }`}
        key={i}
      >
        <img
          src={getAbsoluteStaticUrl(logo.url)}
          alt={logo.alt}
          width='100%'
          height='auto'
        />
      </li>
    ));

    return (
      <>
        {firstPartLogosForMobile}

        {secondPartLogosForMobile}
      </>
    );
  };
  return (
    <section
      className={`
      flex
      items-center
      flex-col
      pb-80
      md:pb-88
      lg:pb-160
      w-full
      px-16
      sm:px-32
      lg-px-120
      pt-6
      bg-core-dark-grey
      `}
      ref={logosSectionRef}
    >
      <div
        className={`flex w-full lg:w-auto justify-start lg:justify-center mb-24 md:mb-32`}
      >
        <h2
          className={`
        font-MarkProHeavy
        font-900
        leading-40
        md:text-2xl
        lg:text-2xl
        `}
        >
          {headingText}
        </h2>
      </div>
      <ul
        className={`
      flex
      flex-wrap
      justify-center
      list-reset
      lg:max-w-794
    `}
      >
        {renderLogos()}
      </ul>
      <button
        className={`
        inline-block
        os-transition-btn
        font-MarkProBold
        text-teal
        hover:text-teal-dark
        active:text-teal-light
        focus:outline-none
        sm:hidden
      `}
        onClick={() => {
          setShowMore((state) => !state);
          if (showMore) {
            const rect = logosSectionRef.current.getBoundingClientRect();
            window.scrollTo({
              top: window.scrollY + rect.top - 100,
              left: 0,
              behavior: 'smooth',
            });
          }
        }}
      >
        {showMore ? 'Show less' : 'Show more'}
      </button>
    </section>
  );
};
export default withFeatureFlags(LogosSection);
