import React from 'react';

const DisclaimerSection = (props) => {
  const {
    disclaimerText,
    containerClassNames = 'mt-48 md:mt-72 lg:mt-112',
  } = props;

  return (
    <div className={containerClassNames}>
      {disclaimerText && (
        <div dangerouslySetInnerHTML={{ __html: disclaimerText }} />
      )}
    </div>
  );
};
export default DisclaimerSection;
