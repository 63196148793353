import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import { getUserId, isLoggedIn, user as userSelector } from 'selectors/user';
import { initOpenGraph } from 'utils/openGraph';
import HeaderNav from 'components/ui/ui-kit/HeaderNav';
import LogoIcon from 'components/ui/ui-kit/Icons/LogoIcon';
import { parseTemplate } from 'parser/templateParser';
import {
  ERRORS,
  GET_USER_COMPETITION_ELIGIBILITY,
  checkUserEligibility,
  loadUserCompetition,
} from 'actions/userCompetition';
import LoadingIcon from 'components/ui/ui-kit/Icons/LoadingIcon';
import PageNotFound from 'components/ui/PageNotFound';
import SaaGBarUI from 'components/ui/SaaGBarUI';
import { usePrevious } from 'utils/hooks';
import { start } from 'middleware/fetch';
import { getPageTitleFromRootPath } from 'utils';
import { getFilteredTeams } from 'actions/teams';
import defaultConfig from 'constants/defaultConfig';
// import * as localTemplate from 'store/comp-1.json';

const renderHeader = (saagEnabled, userLoggedIn, history) => (
  <div className='w-full show-header'>
    {saagEnabled && (
      <div className='hidden md:flex'>
        <SaaGBarUI />
      </div>
    )}
    <HeaderNav
      customClassNames={`${
        saagEnabled ? 'os-pin-t-0 md:os-pin-t-80' : ''
      } signup-header-nav`}
      simpleHeader={true}
      showBack={true}
      oneThirdWidth
      onBackClicked={() => window.open('/', '_self')}
      backLabel='Home'
      textClass='text-white hover:text-teal'
      textContainerClass='px-0 xs:px-0'
    >
      <div
        className='flex w-1/3 justify-center cursor-pointer m-0-auto'
        onClick={() => {
          window.open('/', '_self');
          return;
        }}
      >
        <LogoIcon />
      </div>
      {!userLoggedIn ? (
        <button
          className='os-transition text-white focus:outline-none hover:text-teal mr-16 header-login-link'
          onClick={(e) => {
            e.preventDefault();
            history.push('/login', {
              referrer: window.location.pathname,
            });
          }}
        >
          <span className='text-base font-MarkProHeavy leading-24px text-teal no-underline hover:text-teal-dark active:text-teal-light'>
            Login
          </span>
        </button>
      ) : null}
    </HeaderNav>
  </div>
);

const renderLoading = (isLoading) => {
  return (
    isLoading && (
      <div className={`flex justify-center items-center w-full h-256 sm:h-400`}>
        <LoadingIcon className='block text-center h-64 w-64' />
      </div>
    )
  );
};

const renderPageError = (showPageError) => {
  return showPageError && <PageNotFound />;
};

const renderMetadata = (metadataObj) => {
  if (isEmpty(metadataObj)) {
    return <></>;
  }
  const currentUrl = window.location.href;
  const { title, description, imageUrl } = metadataObj;
  document.title = title || getPageTitleFromRootPath();
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <link rel='canonical' href={currentUrl} />
      </Helmet>
      {initOpenGraph('website', title, description, imageUrl, currentUrl)}
    </>
  );
};

const UserCompetition = ({
  match: {
    params: { compId, pageId },
  },
}) => {
  const competitionId = toLower(compId) || toLower(pageId);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPageError, setShowPageError] = useState(false);

  const userLoggedIn = useSelector(isLoggedIn);
  const user = useSelector(userSelector);
  const previousLoggedIn = usePrevious(userLoggedIn);
  const [didUserLogOut, setDidUserLogOut] = useState(false);
  const userCompetitionStateObj = useSelector((state) => state.userCompetition);
  const navigations = useSelector((state) => state.navigation?.navigations);
  const filteredTeamsList = useSelector(
    (state) => state?.teams?.filteredTeams || [],
  );
  const inProgress = get(userCompetitionStateObj, ['inProgress'], null);
  const saagEnabled = defaultConfig.saagEnabled;

  const [isLoading, setIsLoading] = useState(inProgress);
  const osCompetition =
    find(get(userCompetitionStateObj, ['competitions'], []), {
      id: competitionId,
    }) || {};

  const competitionMetadata = get(
    osCompetition,
    ['competitionDetails', 'metadata'],
    {},
  );

  const {
    startDate: competitionStartDateUtc = '',
    endDate: competitionEndDateUtc = '',
  } = osCompetition;

  const template = get(userCompetitionStateObj, ['template', 'schema'], []); // localTemplate.default.schema
  const isTemplateLoadingSuccess = get(
    userCompetitionStateObj,
    'isSuccess',
    false,
  );

  let parsed = [];

  const competitionStartTimeMillis = useMemo(() => {
    const momentDateObj = moment(competitionStartDateUtc);
    if (momentDateObj.isValid()) {
      return momentDateObj.toDate().getTime();
    }
    return null;
  }, [competitionStartDateUtc]);

  const competitionEndTimeMillis = useMemo(() => {
    const momentDateObj = moment(competitionEndDateUtc);
    if (momentDateObj.isValid()) {
      return momentDateObj.toDate().getTime();
    }
    return null;
  }, [competitionEndDateUtc]);

  const [isCompetitionStarted, setIsCompetitionStarted] = useState(
    competitionStartTimeMillis
      ? competitionStartTimeMillis - Date.now() <= 0
      : null,
  );
  const prevIsCompetitionStarted = usePrevious(isCompetitionStarted);
  const [isCompetitionClosed, setIsCompetitionClosed] = useState(
    competitionEndTimeMillis
      ? competitionEndTimeMillis - Date.now() <= 0
      : null,
  );

  useEffect(() => {
    if (!isEmpty(navigations)) {
      let allCompetitionIds = new Set();
      navigations.map((nav) => {
        if (nav.opta || nav.optaItems) {
          const optaCompetition = nav.opta.competitionId;
          const optaItemsCompetitions =
            nav.optaItems?.map((o) => o.competitionId) || [];
          allCompetitionIds = new Set([
            ...allCompetitionIds,
            optaCompetition,
            ...optaItemsCompetitions,
          ]);
        }
        return null;
      });

      dispatch(getFilteredTeams(allCompetitionIds));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      dispatch(loadUserCompetition(userLoggedIn, competitionId));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      (previousLoggedIn === false && userLoggedIn) ||
      (prevIsCompetitionStarted === false &&
        isCompetitionStarted &&
        userLoggedIn)
    ) {
      (async () => {
        dispatch({
          type: start(GET_USER_COMPETITION_ELIGIBILITY),
          payload: {
            inProgress: true,
          },
        });

        const userId = getUserId(user);
        await checkUserEligibility(dispatch, userId, competitionId);

        dispatch({
          type: start(GET_USER_COMPETITION_ELIGIBILITY),
          payload: {
            inProgress: false,
          },
        });
      })();
    }

    if (previousLoggedIn && !userLoggedIn) {
      setDidUserLogOut(true);
    }
    // eslint-disable-next-line
  }, [
    userLoggedIn,
    previousLoggedIn,
    competitionId,
    isCompetitionStarted,
    prevIsCompetitionStarted,
  ]);

  useEffect(() => {
    if (isLoading && !inProgress) {
      setIsLoading(inProgress);
    }
  }, [inProgress, isLoading]);

  useEffect(() => {
    const {
      error,
      inProgress,
      isSuccess,
      competitions,
      template,
    } = userCompetitionStateObj;

    const errorType = get(error, 'code', '');
    if (
      (!inProgress &&
        isSuccess &&
        (isEmpty(competitions) || isEmpty(template))) ||
      includes([ERRORS.COMPETITION_ERROR], errorType)
    ) {
      setShowPageError(true);
    } else {
      setShowPageError(false);
    }
    // eslint-disable-next-line
  }, [userCompetitionStateObj]);

  if (!parsed.length && isTemplateLoadingSuccess) {
    parsed = parseTemplate(template, {
      userLoggedIn,
      didUserLogOut,
      iteratorIndex: 0,
      isCompetitionStarted,
      isCompetitionClosed,
      filteredTeamsList,
      competitionStartDateUtc,
      competitionEndDateUtc,
      competitionStartTimeMillis,
      competitionEndTimeMillis,
      pathCompetitionId: competitionId,
      setIsCompetitionStarted,
      setIsCompetitionClosed,
    });
  }

  return (
    <>
      {renderMetadata(competitionMetadata)}
      {renderHeader(saagEnabled, userLoggedIn, history)}
      {renderPageError(showPageError)}
      {renderLoading(isLoading)}
      {parsed}
    </>
  );
};

export default UserCompetition;
