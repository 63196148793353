import Url from 'url';

export function isValidPassword(val) {
  return /(?=[a-zA-Z0-9!@#$%^&*()-_=+<>?[\]{}|~`';":,.]*$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}/.test(
    val,
  );
}

export function isValidMobileNumber(val) {
  return typeof val === 'string' && /^[0][4]\d{8}$|^[1-9]\d{8}$/.test(val);
}

export function isValidateEmail(val) {
  const validEmailReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  return val && validEmailReg.test(val);
}

export const termsAndConditionsUrl = 'https://sport.optus.com.au/terms';

export function isAbsoluteUrl(val) {
  return (
    typeof val === 'string' && new RegExp('^(?:[a-z]+:)?//', 'i').test(val)
  );
}

export function isSubHubUrl(val) {
  const validSubHubDomains = [
    'subhub.optus.com.au',
    'subhub-pp.subhub.optus.com.au',
    'pp-mock.subhub.tv',
    'pp-future.subhub.tv',
    'pp-next.subhub.tv',
  ];

  const urlData = Url.parse(val);
  const { host } = urlData || {};

  return validSubHubDomains.includes(host);
}
