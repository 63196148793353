import React from 'react';
import { getAbsoluteStaticUrl } from '../../../utils/path';
import { useState } from 'react';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import Button from 'components/ui/ui-kit/Button';

const ExploreSubhubSection = ({ getFeatureFlags }) => {
  const [showMore, setShowMore] = useState(false);
  const { exploreSubhubSection = {} } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);
  if (!exploreSubhubSection?.showSection) return null;

  const {
    headingText,
    subText,
    partners,
    learnMoreLinkURL,
  } = exploreSubhubSection;

  const renderPartners = () => {
    const allPartners = partners.map((logo, i) => (
      <li className={`h-64 w-64 lg:h-80 lg:w-80`} key={i}>
        <img
          src={getAbsoluteStaticUrl(logo.url)}
          alt={logo.alt}
          width='100%'
          height='auto'
        />
      </li>
    ));

    return allPartners;
  };

  return (
    <section
      className={`
      flex
      items-center
      flex-col
      w-full
      px-16
      sm:px-32
      lg-px-120
      py-32
      bg-core-dark-grey
      explore-subhub-section
    `}
    >
      <div className='bg-white flex items-center flex-col rounded-16px lg:w-screen lg:rounded-none py-32 lg:py-96 px-16 sm:px-32 lg:px-120'>
        <div
          className={`flex flex-col w-full lg:w-auto items-center justify-start lg:justify-center mb-24 sm:mb-40 lg:mb-48`}
        >
          <h5
            className={`font-MarkProHeavy text-black text-3xl sm:leading-48 lg:text-4xl lg:leading-56 text-center mb-16 lg:mb-24`}
            dangerouslySetInnerHTML={{ __html: headingText }}
          ></h5>
          <p
            className={`
          font-MarkPro
          w-full
          text-left
          text-core-dark-grey
          sm:text-center
          mb-16 lg:mb-24
          leading-normal
          text-base lg:text-lg
        `}
          >
            {subText}
          </p>

          <Button
            type='primary'
            text='Learn more'
            htmlType='link'
            buttonClassName='min-h-48 min-w-full sm:min-w-343 md:min-w-360 text-base bg-yellow hover:bg-amber active:bg-amber xxs:rounded-lg'
            href={learnMoreLinkURL}
            target='_blank'
            handler={() => {}}
          ></Button>
        </div>
        <div>
          <p className='font-MarkProHeavy w-full text-left text-subhub-primary text-lg mb-16'>
            Our current partners
          </p>
          <ul
            className={`
      flex
      flex-wrap
      justify-center
      list-reset
      overflow-hidden
      ${showMore ? 'show-full' : ''}
    `}
          >
            <>{renderPartners()}</>
          </ul>
        </div>
        <button
          className={`
           inline-block
           os-transition-btn
           font-MarkProBold
           text-cyan-blue hover:opacity-30 active:opacity-60 hover:text-cyan-blue-lighter active:text-cyan-blue-light
           mt-32
           mb-8 sm:mb-16
           lg:hidden
           underline
           focus:outline-none
         `}
          onClick={() => {
            setShowMore((state) => !state);
          }}
        >
          {showMore ? 'Show less' : 'Show more'}
        </button>
      </div>
    </section>
  );
};
export default withFeatureFlags(ExploreSubhubSection);
