import React from 'react';
import PropTypes from 'prop-types';

const typeLive = 'live';
const typeUpcoming = 'upcoming';
const typeCompleted = 'completed';

const renderStatusText = (status, time, startDate, showScore) => {
  if (status === 'HT') {
    return (
      <React.Fragment>
        <span className='rounded-full h-6 w-6 bg-white inline-block mr-4' />
        <span className='font-MarkProBold text-2xs text-white leading-1.6 letter-spacing-0point1 uppercase'>
          {status}
        </span>
      </React.Fragment>
    );
  } else if (status === 'FT') {
    return (
      <React.Fragment>
        <span className='font-MarkProBold text-2xs text-white leading-1.6 letter-spacing-0point1 uppercase'>
          {startDate} <span className='ml-7'>{status}</span>
        </span>
      </React.Fragment>
    );
  } else if (status === 'ET' && showScore) {
    return (
      <React.Fragment>
        <span className='rounded-full h-6 w-6 bg-white inline-block mr-4' />
        <span className='font-MarkProBold text-2xs text-white leading-1.6 letter-spacing-0point1 uppercase'>
          {status}
        </span>
      </React.Fragment>
    );
  } else if (status === 'ET' && !showScore) {
    return (
      <React.Fragment>
        <span className='rounded-full h-6 w-6 bg-live-red inline-block mr-4' />
        <span className='font-MarkProBold text-2xs text-live-red leading-1.6 letter-spacing-0point1 uppercase'>
          LIVE
        </span>
      </React.Fragment>
    );
  } else if (status === 'PK' && showScore) {
    return (
      <React.Fragment>
        <span className='rounded-full h-6 w-6 bg-white inline-block mr-4' />
        <span className='font-MarkProBold text-2xs text-white leading-1.6 letter-spacing-0point1 uppercase'>
          {status}
        </span>
      </React.Fragment>
    );
  } else if (status === 'PK' && !showScore) {
    return (
      <React.Fragment>
        <span className='rounded-full h-6 w-6 bg-live-red inline-block mr-4' />
        <span className='font-MarkProBold text-2xs text-live-red leading-1.6 letter-spacing-0point1 uppercase'>
          LIVE
        </span>
      </React.Fragment>
    );
  } else if (status === 'CANCELLED') {
    return (
      <React.Fragment>
        <span className='font-MarkProBold text-2xs text-white leading-1.6 letter-spacing-0point1 uppercase'>
          {startDate} {status}
        </span>
      </React.Fragment>
    );
  } else if (status === 'POSTPONED') {
    return (
      <React.Fragment>
        <span className='font-MarkProBold text-2xs text-white leading-1.6 letter-spacing-0point1 uppercase'>
          {startDate} {status}
        </span>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <span className='rounded-full h-6 w-6 bg-live-red inline-block mr-4' />
        <span className='font-MarkProBold text-2xs text-live-red leading-1.6 letter-spacing-0point1 uppercase ml-4'>
          LIVE {time}'
        </span>
      </React.Fragment>
    );
  }
};

const renderLiveStatus = (
  startTime,
  liveStatus,
  matchPeriod,
  date,
  showScore,
) => {
  let status;

  if (matchPeriod === 'Full Time' || matchPeriod === 'FullTime') {
    status = 'FT';
  } else if (matchPeriod === 'Half Time') {
    status = 'HT';
  } else if (matchPeriod === 'First Half') {
  } else if (matchPeriod === 'Second Half') {
  } else if (
    matchPeriod === 'Extra Time' ||
    matchPeriod === 'End Of Second Half Before Extra Time' ||
    matchPeriod === 'Extra Time First Half' ||
    matchPeriod === 'Extra Time Half Time' ||
    matchPeriod === 'Extra Time Second Half'
  ) {
    status = 'ET';
  } else if (matchPeriod === 'Extra First Half') {
  } else if (matchPeriod === 'Extra Half Time') {
  } else if (matchPeriod === 'Extra Second Half') {
  } else if (
    matchPeriod === 'Penalty Shootout' ||
    matchPeriod === 'End of Extra Time Before Penalties'
  ) {
    status = 'PK';
  } else if (matchPeriod === 'PostMatch') {
  } else if (matchPeriod === 'Abandoned' || matchPeriod === 'Cancelled') {
    status = 'CANCELLED';
  } else if (matchPeriod === 'Postponed') {
    status = 'POSTPONED';
  }

  return (
    <span className='inline-flex items-center'>
      {renderStatusText(status, startTime, date, showScore)}
    </span>
  );
};

const renderUpcomingStatus = (startDate, startTime, matchPeriod) => {
  return (
    <span className='inline-flex items-center'>
      <span className='font-MarkProBold text-2xs text-white leading-1.6 letter-spacing-0point1 uppercase'>
        {startDate}{' '}
        <span className='ml-6'>
          {matchPeriod &&
          (matchPeriod === 'Postponed' || matchPeriod === 'Cancelled')
            ? matchPeriod
            : startTime}
        </span>
      </span>
    </span>
  );
};

const renderCompletedStatus = (startDate) => {
  return (
    <span className='inline-flex items-center'>
      <span className='font-MarkProBold text-2xs text-white leading-1.6 letter-spacing-0point1 uppercase'>
        {startDate} <span className='ml-7'>FT</span>
      </span>
    </span>
  );
};

const StatusBar = ({
  label,
  type,
  time,
  startTime,
  startDate,
  liveStatus,
  matchPeriod,
  showScore,
}) => (
  <div className='flex flex-row items-center relative w-full h-24'>
    {type === typeLive
      ? renderLiveStatus(time, liveStatus, matchPeriod, startDate, showScore)
      : null}
    {type === typeUpcoming
      ? renderUpcomingStatus(startDate, startTime, matchPeriod)
      : null}
    {type === typeCompleted ? renderCompletedStatus(startDate) : null}
  </div>
);

StatusBar.propTypes = {
  /** Label for the status item */
  label: PropTypes.string,
  /** Type of status item */
  type: PropTypes.oneOf([typeLive, typeUpcoming, typeCompleted]),
  time: PropTypes.string,
  startTime: PropTypes.string,
  startDate: PropTypes.string,
  /** Show score option */
  showScore: PropTypes.bool,
};

StatusBar.defaultProps = {
  label: null,
  type: typeLive,
  showScore: false,
};

export default StatusBar;
