import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

const RightIcon = ({ width, height, theme, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox={`0 0 32 32`}
      {...props}
    >
      <g fill='none' fillRule='evenodd'>
        <path fill={styles[theme]?.color} fillOpacity='0' d='M0 0h32v32H0z' />
        <path
          stroke={styles[theme]?.color}
          strokeWidth='2'
          d='M9 5.414l11 11-11 11'
        />
      </g>
    </svg>
  );
};

RightIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  theme: PropTypes.string,
};

RightIcon.defaultProps = {
  width: 32,
  height: 32,

  theme: 'dark',
};

export default RightIcon;
