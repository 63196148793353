import React from 'react';
import { useHistory } from 'react-router-dom';

function ContentCardButton({ label, className, onClick }) {
  const history = useHistory();

  const actionClassName = `rounded-4xl active:bg-teal focus:outline-none h-48 flex items-center justify-center px-16 text-sm truncate os-transition-btn w-full font-MarkProBold ${className}`;

  const btnAttrs = {};

  btnAttrs.onClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClick && onClick(history);
  };

  return (
    <button className={actionClassName} type='button' {...btnAttrs}>
      {label}
    </button>
  );
}

export default ContentCardButton;
