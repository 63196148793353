import React from 'react';

export const defaultSignupContext = {
  theme: 'default',
  userLoggedIn: false,
  featurePaymentPage: false,
  handleOnViewSubscriptionButtonClick: () => {},
};

const SignupContext = React.createContext(defaultSignupContext);

export const SignupContextProvider = SignupContext.Provider;

export default SignupContext;
