import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DownIcon from './Icons/DownIcon';

class AccordionSection extends Component {
  static propTypes = {
    /** Children to render*/
    children: PropTypes.instanceOf(Object).isRequired,
    /** Whether this accordion section is open or not*/
    isOpen: PropTypes.bool.isRequired,
    /** Label for this accordion*/
    label: PropTypes.string.isRequired,
    /** onClick handler for this accordion item*/
    onClick: PropTypes.func.isRequired,
    /** Additional class names */
    customClassNames: PropTypes.string,
    /** Pass theme to indicate dark or light theme **/
    theme: PropTypes.string,
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: {
        isOpen,
        label,
        customClassNames = 'p-8 font-MarkProBold border-b border-medium-grey h-48',
        theme,
      },
    } = this;
    let titleColorClass = 'text-white';
    let arrowIconColor = '#FFF';
    if (theme === 'light') {
      titleColorClass = 'text-black';
      arrowIconColor = '#000';
    } else {
      if (isOpen) {
        titleColorClass = 'text-teal';
        arrowIconColor = '#05cedd';
      }
    }
    return (
      <div
        className={`
          ${titleColorClass}
          ${customClassNames || ''}
        `}
      >
        <div
          onClick={onClick}
          className={`
            cursor-pointer
            w-full
            flex
            items-center
          `}
        >
          <span
            className={`flex justify-start flex-grow p-16`}
            dangerouslySetInnerHTML={{ __html: label || '' }}
          ></span>
          <div
            className={`
              justify-end
              h-32
            `}
          >
            {!isOpen && (
              <span>
                <DownIcon color={arrowIconColor} />
              </span>
            )}
            {isOpen && (
              <span>
                <DownIcon invert color={arrowIconColor} />
              </span>
            )}
          </div>
        </div>
        <div
          className={`
            font-MarkPro
            ${theme === 'light' ? 'text-black' : 'text-white'}
            os-dropdown
            ${
              isOpen
                ? 'os-transition-slide-down'
                : 'os-transition-slide-up'
            }
          `}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default AccordionSection;
