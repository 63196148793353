import API from '@aws-amplify/api';

import * as types from '../constants/actionTypes';
import { assetMapping } from '../middleware/assetMapping';

export function getBannerAsset(assetId) {
  return (dispatch) => {
    if (assetId) {
      const path = `/assets/v2/${assetId}/web`;
      dispatch(setBannerAsset(assetId));
      return API.get('fe-api-dev-metadataGetAsset', path, {})
        .then((asset) => {
          const parsedAsset = assetMapping(asset);
          dispatch(setBannerAssetSuccess(parsedAsset));
        })
        .catch((error) => {
          dispatch(setBannerAssetFailure(assetId));
          throw error;
        });
    }
  };
}

export function setBannerAsset(assetId) {
  return {
    type: types.GET_BANNER_ASSET,
    assetId,
  };
}

export function setBannerAssetSuccess(asset) {
  return (dispatch) => {
    dispatch(setBannerAsset(asset.id));
    return {
      type: types.GET_BANNER_ASSET_SUCCESS,
      asset,
    };
  };
}
export function setBannerAssetFailure(assetId) {
  return {
    type: types.GET_BANNER_ASSET_FAILURE,
    assetId,
  };
}
