import React, { useEffect, useRef, useState, useContext } from 'react';
import _ from 'lodash';

import { withFeatureFlags } from 'components/context/withFeatureFlags';
import SubscriptionPlan from './SubscriptionPlan';
import SignupContext from './SignupContext';
import DisclaimerSection from '../../ui/DisclaimerSection';

const SubscriptionPlanSection = ({
  showCTA,
  sectionClassName = '',
  getFeatureFlags,
  onSubscribeViaSubhubCardClick,
  isAccountCreatedOrUpdated,
  isInternationalUser = false,
  userLoggedIn,
}) => {
  const signupContext = useContext(SignupContext);
  const isUserLoggedIn = userLoggedIn || signupContext.userLoggedIn;

  const [isFirstVisible, setIsFirstVisible] = useState(true);
  const [isLastVisible, setLastIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const { subscriptionPlanSection } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);
  const { enableSubscribeViaSubhub } = getFeatureFlags('CHECKOUT_PAGE_CONFIG');
  const paymentPageFlag = getFeatureFlags('FEATURE_PAYMENT_PAGE');
  const showSection = subscriptionPlanSection.showSection;
  const subscriptionPlans = _.filter(
    subscriptionPlanSection.subscriptionPlansList,
    {
      showSubscription: true,
      ...(isAccountCreatedOrUpdated
        ? { isInternationalPass: isInternationalUser }
        : {}),
    },
  );
  const subscriptionPlansLength = subscriptionPlans && subscriptionPlans.length;
  const headingText = subscriptionPlanSection.headingText;
  const disclaimerText = subscriptionPlanSection.disclaimerText;

  useEffect(() => {
    handleOnScroll(undefined, sectionRef.current);
    window.addEventListener(
      'resize',
      _.throttle(() => handleOnScroll(undefined, sectionRef.current), 800),
    );
  }, []);

  if (!showSection) {
    return null;
  }

  const handleOnScroll = (event, targetEle) => {
    const target = event?.target || targetEle;
    if (!target) return;
    const scrollPosition = target.scrollLeft;
    const clientWidth = target.clientWidth;
    const scrollWidth = target.scrollWidth;
    setIsFirstVisible(scrollPosition < 20);
    setLastIsVisible(clientWidth + scrollPosition >= scrollWidth);
  };

  return (
    <section
      className={`
        flex
        items-center
        flex-col
        w-full
        relative
        flex
        justify-center
        mb-48
        lg:mb-112
        px-16
        sm:px-32
        lg:px-120
        max-w-screen
        ${sectionClassName}
      `}
    >
      <div className={`flex w-full lg:w-auto`}>
        <h1
          className={`
            font-MarkProHeavy
            font-900
            text-xl
            leading:32
            sm:text-2xl
            sm:leading:40
            mb-8 sm:mb-24
            `}
        >
          {headingText}
        </h1>
      </div>
      <div
        ref={sectionRef}
        onScroll={handleOnScroll}
        className={`w-full max-w-screen flex flex-col gap-12 sm:gap-0 sm:flex-row items-center
        mb-16 sm:mb-24 pt-8 lg:pt-8 overflow-unset sm:overflow-scroll overflow-y-hidden overflow-x-hidden
        subscription-plans ${!isLastVisible ? 'subscription-plans-last' : ''} ${
          !isFirstVisible ? 'subscription-plans-first' : ''
        }`}
        id='subscription-plans'
      >
        {subscriptionPlans.map((sp, i) => {
          const getPlanCardClass = (i) => {
            const lastCard = subscriptionPlansLength - 1;
            switch (i) {
              // First card
              case 0:
                return 'sm:ml-auto mr-0 sm:mr-8';
              // Last card
              case lastCard:
                return 'sm:mr-auto';
              // All other cards
              default:
                return 'mr-0 sm:mr-8';
            }
          };

          return (
            <SubscriptionPlan
              cardClass={
                subscriptionPlansLength === 1 ? 'm-auto' : getPlanCardClass(i)
              }
              subscriptionPlan={sp}
              showCTA={showCTA}
              isFeaturePaymentFlagEnabled={paymentPageFlag}
              enableSubscribeViaSubhub={enableSubscribeViaSubhub}
              onSubscribeViaSubhubCardClick={onSubscribeViaSubhubCardClick}
              isAccountCreatedOrUpdated={isAccountCreatedOrUpdated}
              userLoggedIn={isUserLoggedIn}
              key={i}
            />
          );
        })}
      </div>
      <a
        href='/campaign'
        className={`
          inline-block
          os-transition-btn
          font-MarkProBold
          text-teal
          hover:text-teal-dark
          active:text-teal-light
          underline
        `}
      >
        Use Optus Sport gift card
      </a>
      {disclaimerText && (
        <DisclaimerSection
          disclaimerText={disclaimerText}
          containerClassNames={'mt-48'}
        />
      )}
    </section>
  );
};
export default withFeatureFlags(SubscriptionPlanSection);
