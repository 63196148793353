import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { calculateTimeLeft } from 'parser/utils';
import { isNil } from 'lodash';

const Countdown = ({
  style,
  countDownStartDate,
  countDownEndDate,
  templateProps,
}) => {
  const {
    isCompetitionStarted,
    isCompetitionClosed,
    competitionEndTimeMillis = '',
    competitionStartTimeMillis = '',
  } = templateProps;

  let countdownEndDateTime = useMemo(
    () =>
      competitionEndTimeMillis ||
      moment(countDownEndDate)
        .toDate()
        .getTime(),
    [countDownEndDate, competitionEndTimeMillis],
  );

  let countDownStartDateTime = useMemo(
    () =>
      competitionStartTimeMillis ||
      moment(countDownStartDate)
        .toDate()
        .getTime(),
    [countDownStartDate, competitionStartTimeMillis],
  );
  const [countdownTime, setCountdownTime] = useState(
    useMemo(
      () =>
        calculateTimeLeft(
          isCompetitionStarted,
          countDownStartDateTime,
          countdownEndDateTime,
        ),
      [isCompetitionStarted, countDownStartDateTime, countdownEndDateTime],
    ),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTimeMillis = Date.now();
      const isCompetitionStarted =
        templateProps.isCompetitionStarted ||
        countDownStartDateTime - currentTimeMillis <= 0;

      const { setIsCompetitionStarted } = templateProps;
      if (
        setIsCompetitionStarted &&
        typeof setIsCompetitionStarted === 'function'
      ) {
        templateProps.setIsCompetitionStarted(isCompetitionStarted);
      }

      const timeLeft = calculateTimeLeft(
        isCompetitionStarted,
        countDownStartDateTime,
        countdownEndDateTime,
      );
      const totalTimeRemaining = Object.values(timeLeft).reduce(
        (acc, val) => acc + Number(val),
        0,
      );
      if (isCompetitionStarted && totalTimeRemaining <= 0) {
        const { setIsCompetitionClosed } = templateProps;
        if (
          setIsCompetitionClosed &&
          typeof setIsCompetitionClosed === 'function'
        ) {
          templateProps.setIsCompetitionClosed(true);
        }
        clearInterval(interval);
      } else {
        templateProps.setIsCompetitionClosed(false);
      }

      setCountdownTime(timeLeft);
    }, 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [setCountdownTime, countDownStartDateTime, countdownEndDateTime]);

  const entries = Object.entries(countdownTime);

  if (isNil(isCompetitionStarted) || isNil(isCompetitionClosed)) {
    return <></>;
  }

  return (
    <div className={`w-full ${style}`}>
      <div className='text-center mb-30 font-MarkProHeavy text-2xl'>
        Competition {isCompetitionStarted ? 'ends' : 'starts'} in
      </div>
      <div className='flex m-0-auto justify-between gap-5 max-w-500'>
        {entries.map(([label, value], index) => (
          <React.Fragment key={label}>
            <div className='flex flex-col items-center w-full min-w-54'>
              <div className='square-card flex h-full max-h-100 w-full font-MarkProHeavy items-center justify-center bg-core-dark-grey rounded-xl text-xl xs:text-3xl sm:text-4xl'>
                <span>{value}</span>
              </div>
              <span className='pt-10'>{label}</span>
            </div>
            {index < entries.length - 1 && (
              <div className='flex flex-col justify-center'>
                <div className='font-MarkProHeavy text-xl xs:text-3xl sm:text-4xl'>
                  :
                </div>
                <div className='pt-10'>&nbsp;</div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Countdown;
