import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = (props) => {
  const {
    checked: checkedProp,
    changed: changedProp,
    disabled,
    className,
    checkedClass,
    unCheckedClass,
    id,
    name,
    rounded,
    children,
    ...rest
  } = props;

  const handleChange = () => {
    if (typeof changedProp === 'function') {
      const checked = !checkedProp;
      changedProp(checked);
    }
  };
  const uncheckedBorderColor = unCheckedClass || 'border-white';

  return (
    <div
      className={`block text-grey font-bold flex-row flex ${
        className !== null ? ` ${className}` : ''
      }`}
      {...rest}
    >
      <div className='relative pt-4'>
        <div
          className={`flex border-2 justify-center items-center w-16 h-16 ${checkedClass}
            ${rounded ? `rounded-full` : ``}
            ${
              disabled
                ? `border-medium-grey${checkedProp ? ' bg-medium-grey' : ''}`
                : `${
                    checkedProp
                      ? 'border-transparent bg-green '
                      : uncheckedBorderColor
                  }`
            }`}
        >
          {checkedProp && (
            <div
              className={`os-tick ${disabled ? 'opacity-40' : ''}`}
              style={{
                borderBottom: `2px solid black`,
                borderLeft: `2px solid black`,
              }}
            />
          )}
        </div>
        <input
          name={name}
          className='absolute pin-l top-5 opacity-0'
          type='checkbox'
          id={id ? `${id}` : null}
          onClick={handleChange}
          defaultChecked={checkedProp}
          disabled={disabled}
        />
      </div>
      {children && <div className='ml-8 leading-base'>{children}</div>}
    </div>
  );
};

CheckBox.propTypes = {
  /** Whether this checkbox is checked or not */
  checked: PropTypes.bool,
  /** Whether this checkbox is disabled or not */
  disabled: PropTypes.bool,
  /** Handler for change event. This function will be invoked with the new value when a user toggles the checkbox. */
  changed: PropTypes.func,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Classes to apply to the actual tick or untick of this component. . */
  checkedClass: PropTypes.string,
  unCheckedClass: PropTypes.string,
  /** id of the checkbox's input */
  id: PropTypes.string,
  /** Whether this checkbox is rounded or not */
  rounded: PropTypes.bool,
};

CheckBox.defaultProps = {
  checked: false,
  disabled: false,
  changed: null,
  className: null,
  checkedClass: '',
  unCheckedClass: '',
  id: null,
};

export default CheckBox;
