import React, { useState, useEffect, useRef } from 'react';
import { get, isEmpty } from 'lodash';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jsCookie from 'js-cookie';

import { analyticAction } from '../../ducks/analytic';
import usePrevious from '../../utils/hooks/usePrevious';
import H3 from '../ui/ui-kit/Typography/H3';
import Input from '../ui/ui-kit/Input';
import Button from '../ui/ui-kit/Button';
import Message from '../ui/ui-kit/Message';
import LoadingIcon from '../ui/ui-kit/Icons/LoadingIcon';
import { SIGNUP_INITIATED_FROM_COOKIE_NAME } from 'utils/cookies';

const validateEmail = (value) => {
  const validEmailReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  return !isEmpty(value) && validEmailReg.test(value);
};

const SignUpFooter = ({ ctvLink, referrer, history, signupInitiatedFrom }) => (
  <span
    className={`
      block
      text-center
      text-xs
      pt-10
    `}
  >
    <Button
      text='Sign up'
      handler={(e) => {
        e.preventDefault();

        if (signupInitiatedFrom) {
          jsCookie.set(SIGNUP_INITIATED_FROM_COOKIE_NAME, signupInitiatedFrom);
        }

        history.push({
          pathname: ctvLink ? `/register/${ctvLink}` : `/register`,
          state: { redirectToJustAfterRegister: referrer },
          referrer: history.location,
        });
      }}
      width='w-full mx-auto'
      type='secondary-alternate'
    />
  </span>
);

const LoginForm = (props) => {
  const {
    inProgress,
    onMount,
    onSubmit,
    toggleAuthInProgress,
    loginUser,
    onError,
    bannerMessage,
    disableEmail,
    showSignup,
    initObserver,
    loginFailure,
    email,
    ctvLink,
    isInModal,
    onCloseModal,
    signupInitiatedFrom,
    isPasswordChanged,
    ...rest
  } = props;

  const prevState = usePrevious(inProgress || null);
  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const emailEl = useRef();
  const passwordEl = useRef();

  const [state, setState] = useState({
    inProgress: false,
    errors: {},
  });

  // Check the inProgress state on props
  useEffect(() => {
    if (inProgress !== prevState) {
      setState((state) => ({
        ...state,
        inProgress: false,
      }));
    }
    // eslint-disable-next-line
  }, [inProgress]);

  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, [onMount]);

  const getErrorMessage = (stateErrors, resultError) => {
    let message = {
      title: null,
      type: null,
      text: null,
    };

    // Errors
    if (stateErrors || resultError) {
      message.type = 'failure';

      const emailError = get(stateErrors, 'email');
      const passwordError = get(stateErrors, 'password');

      if (emailError || passwordError) {
        if (emailError && passwordError) {
          message.text = 'You must enter a valid email and password.';
        } else {
          message.text = `${emailError || ''} ${passwordError || ''}`;
        }
      } else {
        // Option: Default resultError to static text instead of error messaging from api
        // i.e. Please check your login credentials and try again.
        message.text = resultError;
      }
    }
    if (message.text) {
      return message;
    }
    return false;
  };

  const getPasswordChangeMessage = () => {
    if (isPasswordChanged) {
      return {
        title: 'Your password has been changed',
        type: 'success',
        text:
          'We have logged you out of all your devices for security. Please log in using your new password.',
      };
    }
    return false;
  };

  const onLogin = (e) => {
    e.preventDefault();
    // Application has no info on user
    dispatch(
      analyticAction({
        eventName: 'Login Button Clicked',
        level: 'info',
      }),
    );
    if (onSubmit) {
      onSubmit();
    }
    if (state.inProgress) {
      return false;
    } else {
      // Dispatch action to lock auth process
      setState((state) => ({ ...state, inProgress: true }));
      if (toggleAuthInProgress) {
        toggleAuthInProgress(true);
      }
    }
    // Check if all mandatory fields have been filled in
    let stateChangeObj = {};
    let emailInput = get(emailEl.current, ['state', 'value']),
      passwordInput = get(passwordEl.current, ['state', 'value']);

    const validEmail = validateEmail(emailInput);

    if (validEmail && !isEmpty(passwordInput)) {
      // Dispatch action to login here
      loginUser({
        email: emailInput,
        password: passwordInput,
      });
      // Clear any existing errors
      setState((state) => ({
        ...state,
        errors: {},
      }));
    } else {
      if (!validEmail || isEmpty(passwordInput)) {
        setState((state) => ({
          ...state,
          errors: {},
        }));

        if (!validEmail) {
          const errors = {
            email: 'Please enter a valid email.',
          };
          setState((state) => ({
            ...state,
            errors,
          }));
        }
        if (isEmpty(passwordInput)) {
          const errors = {
            password: 'Please enter a valid password.',
          };
          setState((state) => ({
            ...state,
            errors,
          }));
          if (onError) {
            onError();
          }
        }
      }
      // Dispatch action to unlock auth process
      setState((state) => ({ ...state, inProgress: false }));
      toggleAuthInProgress(false);
    }

    if (!isEmpty(stateChangeObj)) {
      setState(stateChangeObj);
    }
  };

  if (!initObserver) {
    return (
      <div
        className={`
            page
            login
            mt-80
            mx-auto
            px-14
            w-269
            xs:w-350
            md:mt-144
            md:w-369
            lg:w-381
          `}
      >
        <LoadingIcon
          className={`
              block
              m-auto
              text-center
              lg:h-64
              lg:w-64
            `}
        />
      </div>
    );
  }

  const loggedIn = get(rest, 'loggedIn');
  const redirectTo = location?.state?.redirectTo;
  const referrer = get(rest, 'referrer') || location?.state?.referrer;
  const to = redirectTo
    ? {
        pathname: redirectTo,
        state: { referrer },
      }
    : referrer || '/';

  if (loggedIn) {
    if (location?.state?.errorCode && referrer === '/help') {
      const toWithErrorCode = {
        pathname: '/help',
        state: location?.state?.errorCode,
      };
      return <Redirect to={toWithErrorCode} />;
    }
    return <Redirect to={to} />;
  }

  // const loginFailure = get(loginFailure);
  const inProgressLocal = get(state, 'inProgress');

  const stateErrors = get(state, 'errors');
  // Get message details

  const message =
    getErrorMessage(stateErrors, loginFailure) || getPasswordChangeMessage();
  const messageType = get(message, 'type');
  const messageTitle = get(message, 'title');
  const messageText = get(message, 'text');
  const displayMessage = messageType && messageText;

  return (
    <div
      className={`page login mx-auto px-14 w-269 xs:w-350 md:w-369 lg:w-381 ${
        isInModal ? 'mt-32' : 'mt-80 md:mt-144'
      }`}
    >
      <form>
        <div className='inline-block text-center w-90p my-16'>
          <H3 className={`text-xl md:text-2xl`}>
            {showSignup ? 'Log in or Sign up' : 'Log in to Optus Sport'}
          </H3>
        </div>
        {displayMessage ? (
          <Message
            className={`mb-16`}
            type={messageType}
            open={true}
            title={messageTitle}
            message={messageText}
            showIcon={true}
            showClose={false}
          />
        ) : null}
        {bannerMessage && bannerMessage()}
        <Input
          type='string'
          disabled={inProgressLocal || disableEmail}
          id='login-email'
          placeHolder='Enter your email'
          labelText='Email'
          validate={true}
          validator={(value, e) => {
            return isEmpty(value) || !validateEmail(value)
              ? 'Invalid email provided.'
              : null;
          }}
          ref={emailEl}
          value={email}
          marginBottom={'24'}
          showLockedIcon={disableEmail}
        />
        <Input
          type='password'
          disabled={inProgressLocal}
          id='login-pwd'
          name='login-pwd'
          placeHolder='Enter your password'
          labelText='Password'
          validate={true}
          ref={passwordEl}
          validator={(value, e) => {
            return isEmpty(value) ? 'No password provided.' : '';
          }}
        />

        <div
          className={`
              mt-8
              mb-30
            `}
        >
          <Button
            type='submit'
            disabled={inProgress}
            loading={inProgress}
            handler={onLogin}
            text='Log in'
            width='w-full'
          />
        </div>
        <div>
          {showSignup && (
            <>
              <h3 className='login-bottom mb-24'>
                <span
                  className={`px-20 py-0 ${
                    isInModal ? 'bg-darkest-grey' : 'bg-black'
                  }`}
                >
                  or
                </span>
              </h3>

              <SignUpFooter
                ctvLink={ctvLink}
                referrer={referrer}
                history={history}
                signupInitiatedFrom={signupInitiatedFrom}
              />
            </>
          )}
        </div>
        <div className={`pt-24`}>
          <span className={`block text-center text-xs`}>
            <Link
              to='/forgotpassword'
              className={`font-MarkProBold leading-lg text-teal hover:text-teal-dark active:text-teal-light os-transition-btn underline`}
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: '/forgotpassword',
                  state: { referrer: location },
                });
              }}
            >
              Forgot password?
            </Link>
          </span>
        </div>
        <div className={`py-24`}>
          <span className={`block text-center text-xs`}>
            <a
              className={`font-MarkProBold leading-lg text-teal hover:text-teal-dark active:text-teal-light os-transition-btn underline`}
              href='https://www.optus.com.au/for-you/support/answer?requestType=NormalRequest&id=7040&source=5&question=Optus%20Sport%20username%20&%20password%20help'
              target='_blank'
              rel='noopener noreferrer'
            >
              Can't log in?
            </a>
          </span>
        </div>
      </form>
    </div>
  );
};

LoginForm.defaultProps = {
  showBorderBottom: true,
  showSignup: true,
  disableEmail: false,
  isInModal: false,
  signupInitiatedFrom: null,
};

export default LoginForm;
