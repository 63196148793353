import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'intersection-observer';
import { InView } from 'react-intersection-observer';
import GroupHeader from './GroupHeader';
import defaultConfig from '../../../../constants/defaultConfig';

class CompetitionGroup extends Component {
  static propTypes = {
    /** Children to render*/
    children: PropTypes.instanceOf(Object).isRequired,
    /** Whether this accordion section is open or not*/
    isOpen: PropTypes.bool.isRequired,
    /** Label for this accordion*/
    label: PropTypes.string.isRequired,
    /** onClick handler for this accordion item*/
    onClick: PropTypes.func.isRequired,
  };

  onClick = (e, totalCount, index, label) => {
    this.props.onClick(e, totalCount, index, this.props.label);
  };

  renderNoMatchesData = (label) => {
    const inProgress =
      this.props.fixturesInProgress ||
      this.props.resultsInProgress ||
      this.props.livematcheseplInProgress ||
      this.props.livematchesuclInProgress ||
      this.props.livematchesuelInProgress ||
      this.props.livematchesunlInProgress;
    const isSuccess =
      this.props.fixturesIsSuccess ||
      this.props.resultsIsSuccess ||
      this.props.livematcheseplIsSuccess ||
      this.props.livematchesuclIsSuccess ||
      this.props.livematchesuelIsSuccess ||
      this.props.livematchesunlIsSuccess;

    let finalLabel = '';

    if (label === 'EPL') {
      finalLabel = 'epl';
    } else if (label === 'UCL') {
      finalLabel = 'uefa-champions-league';
    } else if (label === 'UEL') {
      finalLabel = 'uefa-europa-league';
    } else if (label === 'UNL') {
      finalLabel = 'internationals';
    } else if (label === 'JPJL') {
      finalLabel = 'j-league';
    } else if (label === 'EC2020') {
      finalLabel = 'uefa-euro-2020';
    } else if (label === 'KL') {
      finalLabel = 'k-league';
    } else if (label === 'WQE') {
      finalLabel = 'internationals';
      // } else if (label === 'COA') {
      //   finalLabel = 'copa-america-2024';
    } else if (label === 'FCW') {
      finalLabel = 'womens';
    } else if (label === 'DFB') {
      finalLabel = 'dfb-pokal';
    } else if (label === 'WWC') {
      finalLabel = 'womens-world-cup-2023';
    } else if (label === 'ECQ2020') {
      finalLabel = 'internationals';
      // } else if (label === 'EUC') {
      //   finalLabel = 'uefa-euro-2024';
    } else if (label === 'NWSL') {
      finalLabel = 'nwsl';
    } else if (label === 'FAC') {
      finalLabel = 'fa-cup';
    }
    if (!inProgress && isSuccess) {
      return (
        <div className='flex flex-no-wrap flex-col items-center justify-center w-full max-w-100percent-minus-161 flex-no-grow flex-no-shrink'>
          <div className='text-white text-sm font-MarkProBold leading-loose mb-4'>
            No games this week
          </div>

          <a
            href={`/${finalLabel}/fixtures`}
            className='text-teal text-sm font-MarkProBold leading-loose underline hover:text-teal-dark active:text-teal-light os-transition-btn'
          >
            View Fixtures
          </a>
        </div>
      );
    }
    return null;
  };

  onHide = (isFirstItem, label) => {
    this.props.updateStatusState(label, isFirstItem, 'hidden');
  };

  onShow = (isFirstItem, label) => {
    this.props.updateStatusState(label, isFirstItem, 'shown');
  };

  renderChildren = (children, label) => {
    return children.map((child, i) => {
      if (i === 0 || i === children.length - 1) {
        return (
          <InView
            key={i}
            tag='div'
            className='intersection-visible-wrapper'
            onChange={(inView) =>
              inView ? this.onShow(i === 0, label) : this.onHide(i === 0, label)
            }
          >
            {child}
          </InView>
        );
      }
      return child;
    });
  };

  render() {
    const {
      onClick,
      props: { isOpen, label, index, totalCount },
    } = this;

    const groupHeaderImg =
      label === 'EPL'
        ? 'comp-football-premier-league-white@3x.png'
        : label === 'UCL'
        ? 'champions-white.svg'
        : label === 'UEL'
        ? 'europa-white.svg'
        : label === 'UNL'
        ? 'nations-white.svg'
        : label === 'ECQ2020'
        ? 'european-qualifiers-tm-white.svg'
        : label === 'WWC'
        ? 'fifa-womens-white-2023.svg'
        : label === 'USC'
        ? 'super-cup.svg'
        : label === 'WSL'
        ? 'wsl-white.svg'
        : label === 'JPJL'
        ? 'j-league-white.svg'
        : label === 'EC2020'
        ? 'uefa-euro-tm-white.svg'
        : label === 'KL'
        ? 'k-league-white.png'
        : label === 'FCWC'
        ? 'club-world-cup-white.svg'
        : label === 'WQE'
        ? 'international-white.svg'
        : // : label === 'COA'
        // ? 'copa-america-2024.svg'
        label === 'FCW'
        ? 'fcw-white.svg'
        : label === 'DFB'
        ? 'dfb-pokal-white.svg'
        : // : label === 'EUC'
        // ? 'uefa-euro-2024.svg'
        label === 'NWSL'
        ? 'nwsl.svg'
        : label === 'FAC'
        ? 'fa-cup.svg'
        : '';

    const { children } = this.props;
    let staggerDelay = 0;

    const childrenWithProps = React.Children.map(children, (child) => {
      staggerDelay = staggerDelay + 100;
      if (child !== null) {
        return React.cloneElement(child, {
          staggerDelay,
          showScore:
            this.props.ScoreProtectionDisabled.toString() === 'true'
              ? true
              : false,
        });
      }
    });

    // Ensure children are never single
    if (childrenWithProps.length === 1) {
      childrenWithProps.push(<div />);
    }

    const rightClasses = [
      'pin-r',
      'pin-r-50',
      'pin-r-100',
      'pin-r-150',
      'pin-r-200',
      'pin-r-250',
      'pin-r-300',
      'pin-r-350',
      'pin-r-400',
    ];
    let pinClassName = 'pin-l';
    const pinRClass = index
      ? rightClasses[totalCount - index - 1]
      : pinClassName;

    // OSN-1659:
    // Currently WQE has taken over the Internationals competition so we shall just name it Internationals
    // as we want to group other competitions in there such as Women's World Cup Qualifiers
    const labelLowerCase = label.toLowerCase();
    const competitionTitle =
      labelLowerCase === 'wqe'
        ? 'Internationals'
        : defaultConfig.getCompetitionTitle(label.toLowerCase());
    return (
      <React.Fragment>
        <GroupHeader
          onClick={onClick}
          className={`flex flex-no-wrap justify-center items-center h-80 w-51 bg-medium-dark-grey border-l border-medium-grey hover:bg-medium-grey os-transition-btn os-box-sizing-border-box flex-none outline-none ${
            isOpen ? 'border-r' : ''
          } ${
            index === 0 ? 'fixed ml-32' : 'sticky'
          } z-300 ${pinRClass} ${label}-sticky-header sticky-header`}
          imgUrl={groupHeaderImg}
          alt={label}
          totalCount={totalCount}
          index={index}
          title={competitionTitle}
        />
        <div className='competition-group-wrapper inline-flex flex-no-wrap'>
          {isOpen && childrenWithProps && childrenWithProps.length !== 0
            ? this.renderChildren(childrenWithProps, label)
            : this.renderChildren(
                [this.renderNoMatchesData(label), <div />],
                label,
              )}
        </div>
      </React.Fragment>
    );
  }
}

export default CompetitionGroup;
