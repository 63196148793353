import React from 'react';
import Message from '../../components/ui/ui-kit/Message';
import PropTypes from 'prop-types';

const ErrorMessages = ({
  needles = [],
  haystack = [],
  callback = () => {},
}) => {
  const filteredErrors = haystack
    .filter((match) => needles.includes(match.name))
    .map((error) => error);

  if (!filteredErrors || filteredErrors.length <= 0) return null;

  return filteredErrors.map((item, index) => (
    <Message
      key={index}
      className="mt-16 mb-24"
      type="failure"
      message={<p className="font-MarkProBold">{item.message}</p>}
      onPress={() => callback(item.name)}
      showClose
      open
    />
  ));
};

Message.propTypes = {
  /** Array of items passed in which can be rendered */
  needles: PropTypes.array,
  /** Array of items to be filtered by */
  haystack: PropTypes.array,
  /** Function to invoke when the user clicks on the close button with a customised outcome.*/
  callback: PropTypes.func,
};

export default ErrorMessages;
