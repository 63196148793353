import React from 'react';
import PropTypes from 'prop-types';
import Logout from 'components/views/Logout';

const LogoutFormModal = ({ showModal, onClose, dismissOnOutsideClick, ...props }) => {
  const closeLogoutFormModal = (e) => {
    if (typeof onClose === 'function') {
      onClose(e);
    }
    e && e.stopPropagation();
  };

  return (
    <div
      className={`fixed pin-l pin-t modal_background backdrop-blur modal-overlay w-full h-full m-0
          ${
            showModal
              ? 'flex modal-overlay-open z-99999'
              : 'hidden modal-overlay-close z--1'
          }
        `}
      onClick={(e) => {
        if (showModal) {
          dismissOnOutsideClick && closeLogoutFormModal(e);
        }
      }}
    >
      <div
        className='bg-darkest-grey m-auto px-0 xs:px-8 sm:px-56 rounded-xl'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Logout isInModal={true} onModalClose={closeLogoutFormModal} {...props} />
      </div>
    </div>
  );
};

LogoutFormModal.propTypes = {
  /** Current state of this LoginModal to display or not */
  showModal: PropTypes.bool,
  /** Handler function for the LoginModal close button. This function will be invoked with the click event received on the close button */
  onClose: PropTypes.func,
  /** Whether to close this LoginModal when the user clicks outside of the LoginModal */
  dismissOnOutsideClick: PropTypes.bool,
};

LogoutFormModal.defaultProps = {
  showModal: false,
  onClose: null,
  dismissOnOutsideClick: true,
};

export default LogoutFormModal;
