import _ from 'lodash';

export function isEmptyJumboTron(getFeatureFlags, user, path, panels) {
  const fitnessConfig = getFeatureFlags('FITNESS_CONFIG');
  const fitnessPaywallEnabled = _.get(fitnessConfig, 'paywallEnabled');
  const userInitObserver = user?.initObserver;
  const userLoggedIn = _.get(user, 'loggedIn');
  return (
    !_.find(panels, { panelType: 'mainCarousel' }) &&
    userInitObserver &&
    userLoggedIn &&
    (path !== 'fitness' || !fitnessPaywallEnabled)
  );
}

export function isEmptyEditorialAssets(panel, editorials) {
  if (!panel?.id) {
    return true;
  }
  const editorial = editorials?.find(
    (editorial) => editorial?.id === panel?.id
  );
  const assets = _.get(editorial, 'assets', []);
  return assets.length === 0;
}