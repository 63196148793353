import React from 'react';
import H3 from '../ui-kit/Typography/H3';
import { getDisplayDateTime } from 'parser/utils';

const renderCompetitionNotStarted = (styles, competitionStartDate) => {
  const displayDateTime = getDisplayDateTime(competitionStartDate);
  return (
    <div className={`flex justify-center items-center w-full py-80 ${styles}`}>
      <div className='flex flex-col max-w-701 items-center'>
        <H3
          className='block text-center md:mb-32 mt-32 lg:text-3xl'
          marginY='mb-16'
        >
          The competition will open shortly
        </H3>
        {displayDateTime && (
          <p className='w-full leading-normal text-center'>
            Competition entry access will begin on the {displayDateTime}
          </p>
        )}
      </div>
    </div>
  );
};

export default renderCompetitionNotStarted;
