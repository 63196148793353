export default {
  lightv2: {
    classes: 'text-black',
    title: 'border-cyan font-MarkProBold',
    tabStyles: '',
  },
  dark: {
    classes: 'text-white',
    title: 'border-teal px-16',
    tabStyles: 'cursor-pointer mx-4',
  },
};
