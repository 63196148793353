import Button from 'components/ui/ui-kit/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getPurchaseSubscriptionViaStoreUrl } from 'ducks/subscription';
import styled from 'styled-components';

const InnerBoxTitle = styled.div`
  min-height: 72px;
  @media only screen and (min-width: 331px) {
    min-height: 56px;
  }
`;
const InnerBoxPrice = styled.div`
  min-height: 65px;
`;

const InnerBoxText = styled.div`
  min-height: 26px;
`;

const SubscriptionPlan = ({
  subscriptionPlan,
  ref,
  showCTA,
  isFeaturePaymentFlagEnabled,
  enableSubscribeViaSubhub,
  onSubscribeViaSubhubCardClick,
  isAccountCreatedOrUpdated,
  userLoggedIn,
  cardClass,
}) => {
  const history = useHistory();
  if (!subscriptionPlan.showSubscription) return null;
  const cardBgClass = subscriptionPlan.canSubscribeViaSubhub
    ? 'bg-white'
    : 'bg-core-dark-grey';
  const cardtextClass = subscriptionPlan.canSubscribeViaSubhub
    ? 'text-black'
    : 'text-white';
  const cardAmountColorClass = subscriptionPlan.canSubscribeViaSubhub
    ? 'text-green-dark'
    : 'text-green';
  const cardSubtextColorClass = subscriptionPlan.canSubscribeViaSubhub
    ? 'text-core-dark-grey'
    : 'text-white';
  const cardAmountBeforeColorClass = subscriptionPlan.canSubscribeViaSubhub
    ? 'text-medium-grey'
    : 'text-light-indicator-grey';
  const cardBillingFrequencyColorClass = subscriptionPlan.canSubscribeViaSubhub
    ? 'text-dark-indicator-grey'
    : 'text-light-indicator-grey';
  const positionOffset = subscriptionPlan.canSubscribeViaSubhub
    ? { top: '16px', left: '-54px' }
    : { top: '16px', left: '-60px' };
  const hrClass = subscriptionPlan.canSubscribeViaSubhub
    ? 'h-2 mt-10 mb-16 bg-black10'
    : 'h-2 mt-10 mb-16 bg-white10';
  const linkAccountClickHandler = () => {
    return onSubscribeViaSubhubCardClick && enableSubscribeViaSubhub
      ? onSubscribeViaSubhubCardClick(true)
      : window.open(subscriptionPlan.subscribeViaSubhubLink, '_blank');
  };
  const purchaseSubscriptionViaStoreUrl = getPurchaseSubscriptionViaStoreUrl();
  const purchaseSubscriptionClickHandler = () => {
    if (!isFeaturePaymentFlagEnabled) {
      window.open(purchaseSubscriptionViaStoreUrl, '_self');
      return;
    }
    if (!subscriptionPlan.canSubscribeViaSubhub) {
      history.push(`/checkout`, {
        offerId: subscriptionPlan.id,
      });
    }
  };

  /**
   * In register success page:
   * when payment flag is OFF and if user clicks (or right clicks & open in new tab) on monthly/annual card's 'purchase subscription' button then send user to optus app store pages
   * when payment flag is on and if user clicks (or right clicks & open in new tab) on monthly/annual card's 'purchase subscription' button then send user to check out page
   * In sign up landing page:
   * when payment flag is OFF, when user clicks on any subscription card(including white optus card) then user is taken to app store pages.
   */
  const href = subscriptionPlan.canSubscribeViaSubhub
    ? subscriptionPlan.subscribeViaSubhubLink
    : !isFeaturePaymentFlagEnabled
    ? purchaseSubscriptionViaStoreUrl
    : `/checkout?planType=${subscriptionPlan.planTitle.replace(/\s+/g, '_')}`;
  const subscribeViaOptusCardHeight = isAccountCreatedOrUpdated
    ? 'min-h-auto sm:min-h-292'
    : 'min-h-auto sm:min-h-212 lg:min-h-194';
  return (
    <div
      className={`relative flex flex-col flex items-center justify-center rounded-16px ${cardBgClass} py-24 px-16
      ${cardClass} mb-16 sm:mb-0 text-justify font-white cursor-default inline-block
      no-underline text-light-indicator-grey w-full min-w-253 xxs:min-w-279 xs:min-w-396 max-w-396 ${subscribeViaOptusCardHeight} ${
        !showCTA ? 'cursor-pointer hover:opacity-30' : ''
      }`}
      onClick={(e) => {
        if (!showCTA) {
          if (isFeaturePaymentFlagEnabled) {
            userLoggedIn
              ? window.open(`/checkout`, '_self')
              : window.open(`/register`, '_self');
          } else {
            window.open(purchaseSubscriptionViaStoreUrl, '_self');
          }
        }
        e.preventDefault();
      }}
      ref={subscriptionPlan.canSubscribeViaSubhub ? ref : undefined}
    >
      {subscriptionPlan.badgeText && (
        <div className='h-16 inline-flex items-center text-black text-sm bg-yellow rounded-xl shadow-sm absolute px-14 os-right-8 os-top--6 mr-24 xxs:mr-8 xs:mr-0'>
          <span
            className='leading-base text-xs font-MarkProBold'
            dangerouslySetInnerHTML={{ __html: subscriptionPlan.badgeText }}
          ></span>
        </div>
      )}
      <InnerBoxTitle>
        {subscriptionPlan.canSubscribeViaSubhub ? (
          <h5
            className={`flex font-MarkProHeavy ${cardtextClass} text-xl font-900 leading-32 mb-8 justify-center`}
            dangerouslySetInnerHTML={{ __html: subscriptionPlan.planTitle }}
          ></h5>
        ) : (
          <h5
            className={`font-MarkProHeavy ${cardtextClass} text-xl font-900 leading-32 mb-8 text-center justify-center`}
          >
            {subscriptionPlan.planTitle}
          </h5>
        )}
        <span
          className={`text-base leading-16px block text-center relative ${cardSubtextColorClass} ${
            subscriptionPlan.beforePrice
              ? 'mb-0'
              : 'mb-0 px-5 xs:px-0 text-center'
          }`}
          dangerouslySetInnerHTML={{
            __html: subscriptionPlan.subscriptionSubtext,
          }}
        />
      </InnerBoxTitle>

      <InnerBoxPrice>
        <div className={`flex items-center justify-center`}>
          <div className='relative'>
            <span
              className={`absolute text-sm leading-16px text-left relative ${cardAmountBeforeColorClass} ${subscriptionPlan.beforePrice &&
                'line-through'}`}
              style={positionOffset}
            >
              {subscriptionPlan.beforePrice}
              &nbsp;
              {/* &nbsp; to keep the height space */}
            </span>
            <div className='flex w-full justify-center align-center'>
              <h4
                className={`
                  text-2xl
                  leading-40
                  font-MarkProHeavy
                  ${cardAmountColorClass}
                  mb-8
                  `}
                dangerouslySetInnerHTML={{ __html: subscriptionPlan.price }}
              />
            </div>
          </div>
        </div>
      </InnerBoxPrice>
      <InnerBoxText>
        <span
          className={`
            mt-2
            mb-4
            text-sm
            leading-20px
            block
            text-center
            relative
            ${cardBillingFrequencyColorClass}`}
          dangerouslySetInnerHTML={{ __html: subscriptionPlan.promoText }}
        />
      </InnerBoxText>
      {isAccountCreatedOrUpdated && <hr className={hrClass} />}
      {showCTA && (
        <>
          <Button
            className={'w-full'}
            type='primary'
            text={
              subscriptionPlan.canSubscribeViaSubhub
                ? 'Link account'
                : 'Purchase subscription'
            }
            htmlType='link'
            href={href}
            onClick={
              subscriptionPlan.canSubscribeViaSubhub
                ? linkAccountClickHandler
                : purchaseSubscriptionClickHandler
            }
            buttonClassName={`min-h-48 min-w-full sm:min-w-268 lg:min-w-360 text-base ${
              subscriptionPlan.canSubscribeViaSubhub
                ? 'bg-cyan-blue xxs:text-white subhub-link-button'
                : ''
            }`}
          ></Button>
        </>
      )}
    </div>
  );
};
export default SubscriptionPlan;
