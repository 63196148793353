import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Button from './ui-kit/Button';
import { getAbsoluteStaticUrl } from 'utils/path';

const WatchLiveButton = ({ systemConfig, width = 'w-full', ...props }) => {
  const watchLiveLink =
    get(systemConfig, 'links.watchLive') || '/videos/v42463/optus-sport-1';
  return (
    <Button
      handler={() => window.open(watchLiveLink, '_self')}
      text='Watch Optus Sport live!'
      className={`mb-16 text-black ${width}`}
      type='primary'
      width={width}
      rounded
      textClassName={`font-MarkProHeavy text-base leading-normal`}
      icon={
        <img
          className={`max-w-32`}
          width='32'
          height='32'
          src={getAbsoluteStaticUrl('/images/icon-youtube-black.svg')}
          alt='tv-icon'
        />
      }
      {...props}
    />
  );
};

export default connect(({ systemConfig }) => ({ systemConfig }))(
  WatchLiveButton,
);
