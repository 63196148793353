import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  confirmCode,
  resendConfirmationCode,
  STAGE_CONFIRM_CODE,
  STAGE_RESEND_CODE
} from '../../ducks/verification';
import ReactCodeInput from './ReactCodeInput';
import Paper from './Paper';
import LinkButton from './ui-kit/LinkButton';
import Button from './ui-kit/Button';
import { triggerGoogleAnalyticsEvent } from '../../utils';
import Message from './ui-kit/Message';

const MAX_DIGITS = 6;

const VerifyCodeLabel = () => (
  <>
    <p className="mb-16 text-left font-MarkProHeavy leading-normal xl:leading-none text-base xl:text-lg whitespace-pre-line">{`A ${MAX_DIGITS}-DIGIT CODE HAS BEEN SENT TO
    YOUR MOBILE DEVICE`}</p>
    <label className="text-white text-sm font-MarkProBold os-transition os-transitionproperty-all">
      Your {MAX_DIGITS}-digit confirmation code *
    </label>
  </>
);

class VerifyCode extends React.Component {
  state = {};

  isValid = () => {
    const { code } = this.state;
    return code && code.length === MAX_DIGITS;
  };

  onSuccess = () => {
    this.setState({
      showError: false,
      errorMessage: ``,
      inProgress: false,
      stage: null
    });
    const { stage } = this.state;
    const { onSuccess } = this.props;
    if (stage === STAGE_CONFIRM_CODE && onSuccess) {
      onSuccess();
    }
  };

  onError = () => {
    this.setState({ inProgress: false });
    const { onError, verification } = this.props;
    const { errorCode } = verification;
    if (errorCode && onError) {
      onError(errorCode);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { inProgress: prevInProgress } = prevState;
    const { inProgress: currInProgress } = this.state;
    const hasTransitionedToInProgress = !prevInProgress && currInProgress;
    if (hasTransitionedToInProgress) {
      this.setState({
        showError: false,
        errorMessage: ``
      });
      const { stage } = this.state;
      const { data } = this.props;
      const { email, number, token } = data;
      if (stage === STAGE_CONFIRM_CODE) {
        const { code } = this.state;
        this.props.confirmCode({ token, email, number, code });
      }
      if (stage === STAGE_RESEND_CODE) {
        this.props.resendConfirmationCode({ token, email, number });
      }
      return;
    }
    const { verification: currVerification } = this.props;
    const { verification: prevVerification } = prevProps;
    const hasVerificationChanged = !_.isEqual(
      currVerification,
      prevVerification
    );
    if (!hasVerificationChanged) {
      return;
    }
    const { error: currError, stage } = currVerification;
    this.setState({
      errorMessage: currError,
      showError: currError
    });
    const isCurrentStage =
      stage === STAGE_CONFIRM_CODE || stage === STAGE_RESEND_CODE;
    if (isCurrentStage && currError) {
      this.onError();
      return;
    }
    const { token } = currVerification;
    if (isCurrentStage && token) {
      this.onSuccess();
      return;
    }
  }

  render() {
    const { showError, errorMessage, inProgress, stage } = this.state;
    const { show, disabled, inProgress: externalInProgress } = this.props;
    const confirmCodeInProgress = inProgress && stage === STAGE_CONFIRM_CODE;
    const resendCodeInProgress = inProgress && stage === STAGE_RESEND_CODE;
    return (
      show && (
        <>
          {showError && (
            <Message
              className="mb-16"
              type="failure"
              open
              message={errorMessage}
              showIcon
            />
          )}
          <Paper
            className="p-16 mb-16"
            bgColor="transparent"
          >
            <VerifyCodeLabel />
            <ReactCodeInput
              type="number"
              fields={MAX_DIGITS}
              onChange={code => this.setState({ code })}
            />
            <div className="w-full flex justify-center">
              <LinkButton
                text="Resend Code"
                disabled={
                  confirmCodeInProgress || resendCodeInProgress || disabled
                }
                handler={() => {
                  this.setState({
                    inProgress: true,
                    stage: STAGE_RESEND_CODE
                  });
                  triggerGoogleAnalyticsEvent(
                    'voucher',
                    'step-mobile',
                    'resend'
                  );
                }}
              />
            </div>
          </Paper>
          <Button
            loading={confirmCodeInProgress || externalInProgress}
            disabled={
              (!this.isValid() && !confirmCodeInProgress) ||
              resendCodeInProgress
            }
            text="Verify my mobile"
            type="primary-alternate"
            width="w-full mb-16 md:w-266 xl:w-276"
            handler={() =>
              this.setState({
                inProgress: true,
                stage: STAGE_CONFIRM_CODE
              })
            }
            rounded
          />
        </>
      )
    );
  }
}

VerifyCode.propTypes = {
  show: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  data: PropTypes.shape({
    email: PropTypes.string,
    number: PropTypes.string,
    token: PropTypes.string
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onReset: PropTypes.func
};

function mapStateToProps(state) {
  return {
    verification: state.verification
  };
}

const mapDispatchToProps = {
  resendConfirmationCode,
  confirmCode
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCode);
