import React, { Component } from "react";
import PropTypes from 'prop-types';

class Score extends Component {
  render() {
    const { score, leading, showScore } = this.props;
    
    return (
      <div className={`team-score absolute pin-r pin-t os-top-1 mr-14 ${showScore ? 'score-fade-in' : 'score-fade-out'}`}>
        <span className={`${leading ? 'font-MarkProHeavy' : 'font-MarkPro'} text-xs leading-loose text-white os-tabular-nums`}>{score}</span>
      </div>
    );
  }
}

Score.propTypes = {
  /** Score */
  score: PropTypes.number.isRequired,
  /** Leading or not */
  leading: PropTypes.bool.isRequired,
};

Score.defaultProps = {
  score: 0,
  leading: false,
};

export default Score;