import React from 'react';
import PropTypes from 'prop-types';

const SuccessIcon = ({ width, height, viewBox, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <g fill="#00FF91" fillRule="evenodd">
      <path fillRule="nonzero" d="M72 139c37.003 0 67-29.997 67-67S109.003 5 72 5 5 34.997 5 72s29.997 67 67 67zm0 5c-39.765 0-72-32.235-72-72S32.235 0 72 0s72 32.235 72 72-32.235 72-72 72z"/>
      <path d="M62.393 91.163l40.305-40.305 3.536 3.535-40.305 40.305-3.536 3.536-22.627-22.627 3.536-3.536 19.091 19.092z"/>
    </g>
  </svg>
);

SuccessIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

SuccessIcon.defaultProps = {
  width: 144,
  height: 144,
  viewBox: '0 0 144 144'
};

export default SuccessIcon;



