import React from 'react';
import loadable from '@loadable/component';
import _ from 'lodash';
import { selectors, useSelector } from 'store/createPortalStore';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import LoadingIcon from '../ui-kit/Icons/LoadingIcon';

const DefaultJumbotron = loadable(() => import('./components/Default'));
const FitnessPageJumbotron = loadable(() => import('./components/Fitness'));
const ContentJumbotron = loadable(() =>
  import('components/features/MainCarousel'),
);

const NewHomeSalesPromo = ({
  path = 'home',
  getFeatureFlags,
  jumbotron,
  theme,
} = {}) => {
  const user = useSelector(selectors.user.user);
  const userStrictType = useSelector(selectors.user.userStrictType);
  const userInitObserver = user?.initObserver;
  const userLoggedIn = _.get(user, 'loggedIn');

  const fitnessConfig = getFeatureFlags('FITNESS_CONFIG');
  const fitnessPaywallEnabled = _.get(fitnessConfig, 'paywallEnabled');
  const jumbotronComponents = {
    DefaultJumbotron: DefaultJumbotron,
    FitnessPageJumbotron: FitnessPageJumbotron,
    ContentJumbotron: ContentJumbotron,
  };

  const isFitnessCustom =
    fitnessPaywallEnabled && path === 'fitness' && userStrictType !== 'premium';
  const isFitnessCustomPremium =
    fitnessPaywallEnabled && path === 'fitness' && userStrictType === 'premium';
  let fitnessCustomClassName = '';
  if (isFitnessCustom) {
    fitnessCustomClassName =
      'compact min-h-1024 xs:min-h-800 md:min-h-800 lg:justify-start';
  } else if (isFitnessCustomPremium) {
    fitnessCustomClassName = 'fitness-custom';
  } else {
    fitnessCustomClassName = 'min-h-480 xl:h-667';
  }
  const jumbotronId = _.get(jumbotron, 'id', '');

  // New App Manager driven page configuration:
  // 1. Jumbotron type is now stored inside layout > id instead of
  // type
  // 2. ContentJumbotron has been replaced by mainCarousel panelType
  const panelType = _.get(jumbotron, 'panelType', '');

  const jumbotronTypeV2 = _.get(jumbotron, 'layout.id', null);
  const jumbotronType = panelType.toLowerCase() === 'maincarousel' ? 'ContentJumbotron' : _.get(jumbotron, 'type', jumbotronTypeV2);
  const JumobtronComponent = _.get(jumbotronComponents, jumbotronType, DefaultJumbotron);

  if (jumbotronType === 'ContentJumbotron') {
    return (
      <span className='text-white'>
        <JumobtronComponent mainCarouselId={jumbotronId} theme={theme} />
      </span>
    );
  }

  return userInitObserver &&
    userLoggedIn &&
    (path !== 'fitness' || !fitnessPaywallEnabled) ? null : (
    <section
      className={`
          signup-hero relative w-full flex justify-center overflow-hidden text-white ${fitnessCustomClassName}
        `}
    >
      {!userInitObserver ? (
        <LoadingIcon className={'block m-auto text-center lg:h-64 lg:w-64'} />
      ) : (
        JumobtronComponent && <JumobtronComponent theme={theme} />
      )}
    </section>
  );
};

export default withFeatureFlags(NewHomeSalesPromo);
