import Button from 'components/ui/ui-kit/Button';
import React from 'react';
import { getAbsoluteStaticUrl } from 'utils/path';
import { isMobile } from 'utils';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import DisclaimerSection from 'components/ui/DisclaimerSection';

const DevicesSection = ({ getFeatureFlags }) => {
  const { devicesSection } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);
  if (!devicesSection?.showSection) {
    return null;
  }
  const {
    headingText,
    imageUrlMobile,
    imageUrl,
    subText,
    showDeviceItemsSection,
    disclaimerText,
  } = devicesSection;

  return (
    <section
      className={`
          px-16
          sm:px-32
          lg:px-120
          py-40
          sm:py-48
          lg:py-72
          bg-core-dark-grey
          signup-devices-section
        `}
    >
      <div className='flex flex-col lg:flex-row justify-center items-center mb-32 sm:mb-24 lg:mb-0'>
        <picture className='w-full lg:w-auto'>
          <source
            media={`(max-width:576px)`}
            srcSet={getAbsoluteStaticUrl(imageUrlMobile)}
          />
          <source
            media='(min-width: 768px)'
            srcSet={getAbsoluteStaticUrl(imageUrl)}
          />
          <source
            media='(max-width: 767px)'
            srcSet={getAbsoluteStaticUrl(imageUrl)}
          />
          <source
            media='(min-width: 767px)'
            srcSet={getAbsoluteStaticUrl(imageUrl)}
          />
          <img
            className='w-full h-full lg:max-w-772'
            src={getAbsoluteStaticUrl(imageUrl)}
            alt={''}
          />
        </picture>
        <div className='flex flex-col justify-center items-start sm:items-center lg:items-start lg:max-w-421 lg:ml-16'>
          <h1
            className={`
              font-MarkProHeavy
              font-900
              text-xl
              sm:text-2xl
              leading-32
              smleading-48
              mb-8
            `}
          >
            {headingText}
          </h1>
          <p
            className={`
            font-MarkPro
            leading-normal
            text-base
            mb-0
            lg:mb-40
            `}
          >
            {subText}
          </p>
          <div className={`hidden lg:flex supported-devices-button`}>
            <Button
              type='secondary-alternate-filter'
              style={{ minWidth: '343px' }}
              text='How to watch Optus Sport'
              theme='light'
              htmlType='link'
              buttonClassName='min-w-full sm:min-w-343 text-base'
              href='https://www.optus.com.au/for-you/support/answer?id=6989'
              handler={() => {}}
            ></Button>
          </div>
        </div>
      </div>
      {showDeviceItemsSection && (
        <ul
          className={`
            text-center
            flex
            flex-wrap
            sm:flex-no-wrap
            justify-center
            list-reset
            mb-0
            sm:mb-8
            sm:mb-24
            m-auto
            px-16
            max-w-1120
            sm:px-0
            devices-list
          `}
        >
          <li
            className={`
              flex
              flex-row
              sm:flex-col
              items-center
              w-full
              mb-24
              px-0
              md:w-1/2
              md:px-8
              sm:w-1/4
              sm:px-16
              sm:max-w-277
            `}
          >
            <div
              className={`bg-white10 sm:bg-transparent flex items-center justify-center rounded-50% sm:rounded-0 min-w-96 sm:min-w-100% h-96 sm:h-auto`}
            >
              <img
                className={`
            h-64 sm:h-80 md:h-auto w-64 sm:w-80 md:w-auto
            `}
                src={
                  isMobile()
                    ? getAbsoluteStaticUrl(
                        '/images/signup/mobile-and-tablet-mobile.svg',
                      )
                    : getAbsoluteStaticUrl(
                        '/images/signup/mobile-and-tablet.svg',
                      )
                }
                alt='Mobile &amp; Tablet'
              />
            </div>
            <div className={`text-left sm:text-center ml-16 sm:ml-0`}>
              <h3
                className={`
                font-MarkPro
                text-base
                md:text-xl
                leading-none
                mb-4
              `}
              >
                Mobile &amp; Tablet
              </h3>
              <p
                className={`
                text-sm
                md:text-base
                leading-normal
                text-light-indicator-grey
              `}
              >
                We support Android &amp; iOS devices. Download us from Google
                Play or the Apple app store.
              </p>
            </div>
          </li>
          <li
            className={`
          flex
          flex-row
          items-center
          sm:flex-col
              w-full
              mb-24
              px-0
              md:w-1/2
              md:px-8
              sm:w-1/4
              sm:px-16
            `}
          >
            <div
              className={`bg-white10 sm:bg-transparent flex items-center justify-center rounded-50% sm:rounded-0 min-w-96 sm:min-w-100% h-96 sm:h-auto`}
            >
              <img
                className={`
            h-64 sm:h-80 md:h-auto w-64 sm:w-80 md:w-auto
            `}
                src={getAbsoluteStaticUrl('/images/signup/tv-device.svg')}
                alt='TV'
              />
            </div>
            <div className={`text-left sm:text-center ml-16 sm:ml-0`}>
              <h3
                className={`
                font-MarkPro
                text-base
                md:text-xl
                leading-none
                mb-4
              `}
              >
                TV
              </h3>
              <p
                className={`
                text-sm
                md:text-base
                leading-normal
                text-light-indicator-grey
              `}
              >
                Watch us on the big screen! With Fetch TV, Apple TV, Android TV,
                Samsung, LG, Amazon Fire, and Chromecast.
              </p>
            </div>
          </li>
          <li
            className={`
          flex
          flex-row
          sm:flex-col
          items-center
              w-full
              mb-24
              px-0
              md:w-1/2
              md:px-8
              sm:w-1/4
              sm:px-16
            `}
          >
            <div
              className={`bg-white10 sm:bg-transparent flex items-center justify-center rounded-50% sm:rounded-0 min-w-96 sm:min-w-100% h-96 sm:h-auto`}
            >
              <img
                className={`
            h-64 sm:h-80 md:h-auto w-64 sm:w-80 md:w-auto
            `}
                src={getAbsoluteStaticUrl('/images/signup/pc-and-mac.svg')}
                alt='Computer'
              />
            </div>
            <div className={`text-left sm:text-center ml-16 sm:ml-0`}>
              <h3
                className={`
                font-MarkPro
                text-base
                md:text-xl
                leading-none
                mb-4
              `}
              >
                Computer
              </h3>
              <p
                className={`
                text-sm
                md:text-base
                leading-normal
                text-light-indicator-grey
              `}
              >
                All major modern browsers are supported. Chrome, Firefox,
                Safari, Edge.
              </p>
            </div>
          </li>
          <li
            className={`
          flex
          flex-row
          sm:flex-col
          items-center
              w-full
              px-0
              md:w-1/2
              md:px-8
              sm:w-1/4
              sm:px-16
            `}
          >
            <div
              className={`bg-white10 sm:bg-transparent flex items-center justify-center rounded-50% sm:rounded-0 min-w-96 sm:min-w-100% h-96 sm:h-auto`}
            >
              <img
                className={`
            h-64 sm:h-80 md:h-auto w-64 sm:w-80 md:w-auto
            `}
                src={getAbsoluteStaticUrl('/images/signup/game-console.svg')}
                alt='Game Consoles'
              />
            </div>
            <div className={`text-left sm:text-center ml-16 sm:ml-0`}>
              <h3
                className={`
                font-MarkPro
                text-base
                leading-24px
                md:text-xl
                leading-none
                mb-4
              `}
              >
                Game Consoles
              </h3>
              <p
                className={`
                text-sm
                md:text-base
                leading-normal
                text-light-indicator-grey
              `}
              >
                Enjoy Optus Sport on PlayStation 4, Xbox One and Xbox Series
                consoles.
              </p>
            </div>
          </li>
        </ul>
      )}
      <div
        className={`flex justify-center w-full supported-devices-button device-list-button-sm-md lg:hidden`}
      >
        <Button
          type='secondary-alternate-filter'
          text='How to watch Optus Sport'
          theme='light'
          htmlType='link'
          buttonClassName='min-w-full sm:min-w-343 text-base'
          href='https://www.optus.com.au/for-you/support/answer?id=6989'
          handler={() => {}}
        ></Button>
      </div>
      {disclaimerText && <DisclaimerSection disclaimerText={disclaimerText} />}
    </section>
  );
};
export default withFeatureFlags(DevicesSection);
