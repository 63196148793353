import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AccordionSection from './AccordionSection';

/** Accordion component. Each child node, for example a ```<div>``` will be an accordion item with title passed on as ```label``` prop to the child. */
class Accordion extends Component {
  static propTypes = {
    /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
    className: PropTypes.string,
    /** Whether this accordion supports multiple items to be open simultaneously **/
    allowMultipleOpen: PropTypes.bool,
    /** Content for the accordion **/
    children: PropTypes.instanceOf(Object).isRequired,
    /** Additional class names for children i.e. AccordionSection */
    customChildClassNames: PropTypes.string,
    /** Pass theme to indicate dark or light theme **/
    theme: PropTypes.string,
  };

  static defaultProps = {
    allowMultipleOpen: false,
    className: '',
    customChildClassNames: '',
    theme: 'dark',
  };

  constructor(props) {
    super(props);

    const openSections = {};

    this.props.children.forEach &&
      this.props.children.forEach((child) => {
        if (child.props.isOpen) {
          openSections[child.props.label] = true;
        }
      });

    this.state = { openSections };
  }

  onClick = (label) => {
    const {
      props: { allowMultipleOpen },
      state: { openSections },
    } = this;

    const isOpen = !!openSections[label];

    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [label]: !isOpen,
        },
      });
    } else {
      this.setState({
        openSections: {
          [label]: !isOpen,
        },
      });
    }
  };

  render() {
    const {
      onClick,
      props: { children, theme },
      state: { openSections },
    } = this;

    return (
      <div className={`w-auto ${this.props.className || ''}`}>
        {children.map ? (
          children.map((child, index) => (
            <AccordionSection
              isOpen={!!openSections[child.props.label]}
              label={child.props.label}
              key={index}
              onClick={onClick}
              customClassNames={child.props.customChildClassNames}
              theme={theme}
            >
              {child.props.children}
            </AccordionSection>
          ))
        ) : (
          <AccordionSection
            isOpen={!!openSections[children.props.label]}
            label={children.props.label}
            key={children.props.label}
            onClick={onClick}
            customClassNames={this.props.customChildClassNames}
            theme={theme}
          >
            {children}
          </AccordionSection>
        )}
      </div>
    );
  }
}

export default Accordion;
