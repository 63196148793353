import React from 'react';
import ConnectedLogin from '../features/Login';

const Login = props => (
  <ConnectedLogin
    {...props}
  />
);

export default Login;
