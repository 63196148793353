import React from 'react';
import SimpleFormContainer from './SimpleFormContainer';

class ActionModal extends React.PureComponent {
  state = {};

  componentDidMount() {
    this.toggleRootClassList('overflow-hidden', true);
    const { onLoad = '' } = this.props;
    if (onLoad) {
      onLoad();
    }
  }

  componentWillUnmount() {
    this.toggleRootClassList('overflow-hidden', false);
  }

  toggleRootClassList = (className, toggle) => {
    const { rootToggled } = this.state;
    const root = document.getElementById('root');
    const hasClassName = root.classList.contains(className);

    if (!rootToggled && toggle && hasClassName) {
      // Do nothing as the root class has already the className
      // and action is targeting to add the className
      return;
    }
    this.setState({ rootToggled: toggle });
    toggle ? root.classList.add(className) : root.classList.remove(className);
  };

  render() {
    const {
      icon,
      title,
      content,
      isHtmlContent = false,
      footnote,
      modalWidth = 'w-269 xs:w-350 md:w-369 lg:w-381',
      overrideClass = '',
      onBackClicked,
      renderActions,
      backLabel = '',
      modalContainerClassName = '',
      useOwnPageHeader = true,
      subTextBlockStyles = '',
      customClassNames = '',
      showHeaderInsideContent = false
    } = this.props;
    // const widthStyles = `w-full sm:w-1/2 sm:mx-auto`;
    const widthStyles = `w-full`;
    const modalStyles = `fixed md:w-full h-full pin-l pin-t pin-r pin-b modal_background-full backdrop-blur modal-overlay flex modal-overlay-open z-400 modal-full-height`;

    const defaultOnBackClicked = onBackClicked
      ? () => {
        // Enforce toggle off when navigating back
        this.toggleRootClassList('overflow-hidden', false);
        onBackClicked();
      }
      : null;
    const headerDom = <>
      {icon && (
        <div className='block m-auto text-center mb-32'>{icon}</div>
      )}
      <h2
        className={`block text-2xl xl:text-4xl leading-none font-MarkProHeavy mb-32 text-center ${widthStyles} modal-title`}
      >
        {title}
      </h2>
    </>
    return (
      <div className={`${modalStyles} ${modalContainerClassName}`}>
        <SimpleFormContainer
          onBackClicked={defaultOnBackClicked}
          backLabel={backLabel}
          useOwnPageHeader={useOwnPageHeader}
          customClassNames={customClassNames}
        >
          <div
            className={`flex flex-col action mb-48 px-16 md:px-0 ${modalWidth} ${overrideClass}`}
          >
            {!showHeaderInsideContent && headerDom}
            <div
              className={`font-MarkPro leading-normal text-base ${widthStyles} mb-32 md:text-center ${subTextBlockStyles}`}
            >
              <div className='w-full'>
                {isHtmlContent ? (
                  <div className=''>
                    {showHeaderInsideContent && headerDom}
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                  </div>
                ) : (
                  content
                )}
                {footnote && <div className='text-xs'> *{footnote}</div>}
              </div>
            </div>
            <div
              className={`flex flex-col justify-center items-center ${widthStyles}`}
            >
              {renderActions}
            </div>
          </div>
        </SimpleFormContainer>
      </div>
    );
  }
}

export default ActionModal;
