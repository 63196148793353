import _ from 'lodash';
import React, { Component } from 'react';
import {connect} from 'react-redux';
import {default as PageNotFoundUI} from '../ui/PageNotFound';
import PageLoading from '../ui/PageLoading';

class PageNotFound extends Component {
  render() {
    const navigations = _.get(this.props, 'navigation.navigations', null);
    const inProgress = _.get(this.props, 'navigation.inProgress', false);
    if (!Array.isArray(navigations) || inProgress) {

      return <PageLoading />
    }

    return <PageNotFoundUI />;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    navigation: state.navigation
  };
}

export default connect(mapStateToProps)(PageNotFound);
