import React, { useContext } from 'react';
import jsCookie from 'js-cookie';
import styled from 'styled-components';

import Button from 'components/ui/ui-kit/Button';
import {
  SIGNUP_INITIATED_FROM_COOKIE_NAME,
  SIGNUP_INITIATED_FROM_COOKIE_VALUES,
} from 'utils/cookies';
import { getAbsoluteStaticUrl } from 'utils/path';
import { withFeatureFlags } from 'components/context/withFeatureFlags';

import SignupContext from './SignupContext';

const StyledHeroSection = styled.section`
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.0001) 65%,
      #000000 100%
    ),
    url(${(props) => props.mobileImageUrl});
  @media (min-width: 576px) {
    background-image: url(${(props) => props.tabletImageUrl});
  }
  @media (min-width: 992px) {
    background-image: url(${(props) => props.desktopImageUrl});
  }
`;

const defaultMobileImageUrl = '/images/signup/signup-hero-pl-mobile.png';
const defaultTabletImageUrl = '/images/signup/signup-hero-pl-tablet.png';
const defaultDesktopImageUrl = '/images/signup/signup-hero-pl-desktop.png';

const HeroSection = ({ getFeatureFlags }) => {
  const {
    theme: currentTheme,
    userLoggedIn: isUserLoggedIn,
    featurePaymentPage: isFeaturePaymentPage,
    handleOnViewSubscriptionButtonClick: onSubscriptionButtonClick,
  } = useContext(SignupContext);

  const { heroSection } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);
  if (!heroSection || !heroSection[currentTheme]?.showSection) {
    return null;
  }
  const {
    headingText,
    subText,
    className,
    mobileImageUrl,
    tabletImageUrl,
    desktopImageUrl,
    bottomText,
    showButton,
  } = heroSection[currentTheme];

  return (
    <StyledHeroSection
      mobileImageUrl={getAbsoluteStaticUrl(
        mobileImageUrl || defaultMobileImageUrl,
      )}
      tabletImageUrl={getAbsoluteStaticUrl(
        tabletImageUrl || defaultTabletImageUrl,
      )}
      desktopImageUrl={getAbsoluteStaticUrl(
        desktopImageUrl || defaultDesktopImageUrl,
      )}
      className={`
      w-full
      relative
      flex
      items-end
      sm:items-center
      justify-center
      lg:justify-start
      ${!showButton ? `min-h-608` : `min-h-652`}
      sm:min-h-469
      lg:min-h-687
      lg:max-h-full
      xxxxl:min-h-carousel
      ${className}
      signup-hero-section
      sm:pt-0
      mb-32
      sm:mb-12
      lg:mb-48
      px-16
      sm:px-32
      lg:px-120
      z-1
    `}
    >
      <div
        className={`
        z-100
        w-full
        lg:max-w-1204
        flex
        items-start
        md:items-center
      `}
      >
        <div
          className={`
          text-left
          w-full
          sm:text-left
        `}
        >
          <h1
            className={`
            font-MarkProHeavy
            font-900
            text-3xl
            leading-48
            lg:text-4xl
            leading-64
            mb-16
            w-full
            max-w-412
            sm:max-w-459
            lg:max-w-581
            xl:max-w-611
          `}
          >
            <div dangerouslySetInnerHTML={{ __html: headingText }} />
          </h1>
          <p
            className={`
            font-MarkPro
            mb-32
            sm:mb-24
            lg:mb-48
            leading-normal
            text-base
            lg:text-lg
            w-inherit
            sm:max-w-459
            lg:max-w-581
            xl:max-w-611
          `}
            dangerouslySetInnerHTML={{ __html: subText }}
          ></p>
          {showButton && (
            <div
              className={`
              flex
              flex-col
              items-center
              justify-start
              pb-24
              sm:pb-0
              sm:flex-row
              sm:items-start
              md:justify-start
              pl-0
            `}
            >
              <Button
                type='primary'
                text='Create account'
                htmlType='link'
                buttonClassName='min-h-48 min-w-full sm:min-w-229 lg:min-w-294 mr-0 sm:mr-8 lg:mr-16 xs:max-w-full sm:max-w-229 lg:max-w292 text-base'
                handler={(e) => {
                  jsCookie.set(
                    SIGNUP_INITIATED_FROM_COOKIE_NAME,
                    SIGNUP_INITIATED_FROM_COOKIE_VALUES.OTHER_SIGNUP_LINK,
                  );
                  return true;
                }}
                href={
                  !isUserLoggedIn || !isFeaturePaymentPage
                    ? '/register'
                    : '/checkout'
                }
              ></Button>
              <Button
                className='min-w-full sm:min-w-229 lg:min-w-294'
                type='secondary-alternate-filter'
                text={`View subscriptions`}
                buttonClassName='min-h-48 min-w-full sm:min-w-229 lg:min-w-294 mt-8 sm-mt-24 sm:mt-0 md:mt-0 xs:max-w-full sm:max-w-229 lg:max-w292 text-base'
                theme='light'
                href='https://subhub.optus.com.au/streaming-services/optussport'
                handler={onSubscriptionButtonClick}
              ></Button>
            </div>
          )}

          {bottomText && (
            <div
              className='text-xs mt-0 sm:mt-32 sm:max-w-459
              lg:max-w-581
              xl:max-w-611
              leading-base'
              dangerouslySetInnerHTML={{ __html: bottomText }}
            />
          )}
        </div>
      </div>
    </StyledHeroSection>
  );
};
export default withFeatureFlags(HeroSection);
