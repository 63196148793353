export const validateName = (name) => {
  const validNamelReg = /^[a-z-]+( [a-z-]+)*$/i;
  return (
    typeof name === 'string' && validNamelReg.test(name) && name.length <= 40
  );
};

export const validateEmail = (email) => {
  const validEmailReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  return typeof email === 'string' && validEmailReg.test(email);
};

export const validateMobileNumber = (mobileNumber) => {
  const validMobileNumberReg = /^[0][4]\d{8}$|^[1-9]\d{8}$/;
  return (
    typeof mobileNumber === 'string' && validMobileNumberReg.test(mobileNumber)
  );
};

export const validatePhoneOrMobile = (phoneNumber) => {
  const validPhoneNumberReg = /^(?:\((?=.*\)))?(0?[2-57-8])\)?(\d\d(?:(?=\d{3})|(?!\d\d\d[- ]))\d\d\d\d{3})$/;
  return (
    typeof phoneNumber === 'string' && validPhoneNumberReg.test(phoneNumber)
  );
};
