import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import H2 from './ui-kit/Typography/H2';
import NotFoundIcon from './ui-kit/Icons/NotFoundIcon';
import Button from './ui-kit/Button';
import { isFitnessSectionEnabled } from 'utils/getAssetData';

class PageNotFound extends Component {
  render() {
    let { title, description } = this.props;
    const isFitness = isFitnessSectionEnabled();
    title = title || '404! Oh no...';
    description =
      description ||
      `The page you are looking for doesn't exist. If you typed the url
    directly into the search bar, check for any errors. Otherwise
    something went wrong and it's entirely our fault! Sorry...`;
    let descriptionStyle = {
      margin: 'auto',
    };
    return (
      <div className={`min-h-page flex items-center justify-center ${isFitness ? 'text-black' : ''}`}>
        <div className='align-middle text-center p-14'>
          <NotFoundIcon className={`block m-auto text-center`} />
          <H2 className={`block text-center md:mb-32 mt-32  ${isFitness ? 'text-black' : ''}`} marginY='mb-16'>
            {title}
          </H2>

          <p
            className='w-full xs:w-369 leading-normal'
            style={descriptionStyle}
          >
            {description}
          </p>

          <div className='flex justify-center mt-32'>
            <Button
              className='w-full xs:w-369'
              type='quaternary'
              width='w-full'
              text='Back to Home'
              handler={(e) => {
                this.props.history.push('/');
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PageNotFound);
