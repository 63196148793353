import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Label from './ui-kit/Label';
import CalendarIcon from './ui-kit/Icons/CalendarIcon';
import LockedIcon from './ui-kit/Icons/LockedIcon';
import FieldValidIcon from './ui-kit/Icons/FieldValidIcon';
import FieldInvalidIcon from './ui-kit/Icons/FieldInvalidIcon';
import moment from 'moment';

const MAX_AGE = 119;
const MIN_AGE = 0;

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const fromMonth = new Date(currentYear - MAX_AGE, 0);
const toMonth = new Date(currentYear - MIN_AGE, currentMonth);
const defaultMonth = new Date(currentYear - 20, currentMonth);

//Tailwind classes used by the component
const styleClasses = (error, disabled, inputFieldBgColor) =>
  `${!disabled ? `${inputFieldBgColor || 'bg-dark-grey'}` : 'bg-medium-grey'}
  ${
    error
      ? 'border-live-red border-2 rounded'
      : 'border-light-grey border-2 focus:border-white rounded'
  }`;

function YearMonthForm({ date, localeUtils, onChange }) {
  const MAX_OPTIONS = 10;
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
    e.target.size = 0;
  }

  function onMouseDown(e) {
    // Small hack to focus the current parent element so the select dropdown does not lose focus
    // else fall back to default browser treatment of dropdown
    const overlayParent = e.target.closest('.os-date-picker-overlay');

    if (overlayParent) {
      overlayParent && overlayParent.focus();
      e.target.size = MAX_OPTIONS;
    }
  }

  function onBlur(e) {
    // e.target.size = 0;
  }

  return (
    <form className='DayPicker-Caption h-24'>
      <div className='flex'>
        <div className='relative pointer-events-none overflow-hidden h-auto z-99999'>
          <select
            className='bg-dark-grey text-white pointer-events-auto'
            name='month'
            onChange={handleChange}
            onMouseDown={onMouseDown}
            onBlur={onBlur}
            value={date.getMonth()}
          >
            {months.map((month, i) => (
              <option key={month} value={i}>
                {month}
              </option>
            ))}
          </select>
        </div>
        <div className='relative pointer-events-none overflow-hidden h-auto z-99999'>
          <select
            className='bg-dark-grey text-white pointer-events-auto'
            name='year'
            onChange={handleChange}
            onMouseDown={onMouseDown}
            onBlur={onBlur}
            value={date.getFullYear()}
          >
            {years.reverse().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    </form>
  );
}

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    const selectedDay = props.defaultValue
      ? moment(props.defaultValue, 'DD/MM/YYYY').toDate()
      : null;
    this.state = {
      month: defaultMonth,
      isEmpty: true,
      validate: props.validate,
      validator: props.validator,
      _validatorMessage: '',
      _disabled: props.disabled,
      selectedDay,
      showLockedIcon: props.showLockedIcon,
    };
  }

  handleYearMonthChange = (month) => {
    this.setState({ month });
  };

  handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
    const { value } = dayPickerInput.state || {};
    selectedDay && selectedDay.setHours && selectedDay.setHours(0, 0, 0, 0);
    this.setState({
      selectedDay,
      isEmpty: value ? !value.trim() : true,
    });
    const { onChange, validate, validator } = this.props;
    if (onChange) {
      onChange(selectedDay);

      if (validate && typeof validator === 'function') {
        this.validatorFunction(selectedDay);
      }
    }
  };

  renderLabel() {
    const { labelText } = this.props;
    const { _validatorMessage } = this.state;
    return (
      labelText && (
        <Label
          textSize='text-sm'
          className='font-MarkProBold os-transition os-transitionproperty-all'
          color={_validatorMessage ? 'text-live-red' : 'text-white'}
        >
          {labelText}
        </Label>
      )
    );
  }

  renderIcons() {
    const { showLockedIcon, _validatorMessage, isEmpty } = this.state;
    return (
      <div className='absolute pin-r p-8 pointer-events-none'>
        {!isEmpty && _validatorMessage == null && <FieldValidIcon />}
        {_validatorMessage && <FieldInvalidIcon />}
        {showLockedIcon && <LockedIcon />}
        <CalendarIcon
          className={`os-transition os-transitionproperty-all ${
            _validatorMessage ? 'text-live-red' : 'text-white'
          }`}
        />
      </div>
    );
  }

  getDayPickerProps = () => {
    const { month, selectedDay } = this.state;
    return {
      fromMonth,
      toMonth,
      initialMonth: month,
      month,
      fixedWeeks: true,
      selectedDays: selectedDay,
      showOutsideDays: true,
      disabledDays: [
        {
          after: new Date(),
        },
      ],
      captionElement: ({ date, localeUtils }) => (
        <YearMonthForm
          date={date}
          localeUtils={localeUtils}
          onChange={this.handleYearMonthChange}
        />
      ),
    };
  };

  getInputProps = () => {
    const { inputFieldBgColor } = this.props;
    const { _validatorMessage, _disabled } = this.state;
    return {
      className: `font-MarkPro text-white px-16 h-48 w-full ${styleClasses(
        _validatorMessage,
        _disabled,
        inputFieldBgColor,
      )}`,
      readOnly: true,
    };
  };

  getInputClassNames = () => {
    const { width = '' } = this.props;
    return {
      container: width || `w-full md:w-1/2`,
      overlay: ``,
      overlayWrapper: `os-date-picker-overlay w-min-content font-MarkPro bg-dark-grey text-white`,
    };
  };

  renderInputComponent = React.forwardRef((props, ref) => (
    <div className='relative w-full'>
      {this.renderIcons()}
      <input {...props} />
    </div>
  ));

  validatorFunction = (selectedDay) => {
    const { validator } = this.props;
    const _validatorMessage = validator(selectedDay);
    this.setState({ _validatorMessage });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = {};
    const nextDisabled = get(nextProps, 'disabled');
    const nextValidate = get(nextProps, 'validate');
    const nextForcedValidatorMessage = get(nextProps, 'forcedValidatorMessage');

    if (nextDisabled !== prevState._disabled) {
      nextState._disabled = nextDisabled;
    }

    if (nextValidate !== prevState.validate) {
      nextState.validate = nextValidate;
    }

    if (
      nextForcedValidatorMessage &&
      nextForcedValidatorMessage !== prevState._validatorMessage
    ) {
      nextState._validatorMessage = nextForcedValidatorMessage;
    }
    return nextState;
  }

  componentDidMount() {
    const { defaultValue } = this.props;

    if (defaultValue) {
      const _validatorMessage = this.props.validator(defaultValue);
      defaultValue && this.setState({ _validatorMessage });
    }
  }

  render() {
    const { selectedDay, _validatorMessage } = this.state;
    const { show, placeHolder, containerClass = '' } = this.props;
    const hidden = show ? `` : `hidden`;
    return (
      <div className={`${hidden} ${containerClass}`}>
        <div className='w-full relative'>
          {this.renderLabel()}
          <div className='my-16 flex flex-row flex-no-wrap'>
            <DayPickerInput
              value={selectedDay}
              formatDate={(date) => date.toLocaleDateString()}
              parseDate={(date) => date && moment(date, 'DD/MM/YYYY').toDate()}
              placeholder={placeHolder}
              onDayChange={this.handleDayChange}
              classNames={this.getInputClassNames()}
              inputProps={this.getInputProps()}
              dayPickerProps={this.getDayPickerProps()}
              // TODO: For now, this works as input is disabled. Otherwise, there will be issues with input focus
              component={this.renderInputComponent}
            />
          </div>
          <Label
            textSize={'text-xs'}
            color={'text-live-red'}
            className={`font-MarkProBold block os-transition os-transitionproperty-all
              ${_validatorMessage ? `mb-16` : ``}
            `}
          >
            {_validatorMessage}
          </Label>
        </div>
      </div>
    );
  }
}

DatePicker.propTypes = {
  show: PropTypes.bool,
};

DatePicker.defaultProps = {
  show: true,
};

export default DatePicker;
