import styled, { css } from 'styled-components';

const Container = styled.div`
  ${props => {
    const {
      theme: { palette },
      isDisabled
    } = props;
    return css`
      background: ${isDisabled
        ? palette?.['os-medium-grey']
        : palette?.['os-dark-grey']};
    `;
  }}
  ${props =>
    props.isActive &&
    css`
      position: relative;
    `}
`;

export { Container };
