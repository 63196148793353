import React, { useContext } from 'react';
import jsCookie from 'js-cookie';
import Button from 'components/ui/ui-kit/Button';
import {
  SIGNUP_INITIATED_FROM_COOKIE_NAME,
  SIGNUP_INITIATED_FROM_COOKIE_VALUES,
} from 'utils/cookies';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import DisclaimerSection from '../../ui/DisclaimerSection';
import SignupContext from './SignupContext';

const CreateAccountSection = ({
  userLoggedIn,
  featurePaymentPage,
  getFeatureFlags,
}) => {
  const signupContext = useContext(SignupContext);
  const isUserLoggedIn = userLoggedIn || signupContext.userLoggedIn;
  const isFeaturePaymentPage =
    featurePaymentPage || signupContext.featurePaymentPage;

  const { createAccountSection } = getFeatureFlags(`SIGNUP_PAGE_CONFIG`);

  if (!createAccountSection?.showSection) {
    return null;
  }

  const {
    className,
    headingText,
    subText,
    hideViewActionButton,
    disclaimerText,
  } = createAccountSection;

  return (
    <section
      className={`
        flex
        items-center
        flex-col
        w-full
        flex
        justify-center
        pt-24 sm:pt-56 lg:pt-80
        px-16 lg:px-0
        pb-72 sm:pb-96 lg:pb-144
        create-account-section
        ${className || ''}
      `}
    >
      <h1
        className={`
        font-MarkProHeavy
        font-900
        text-xl
        sm:text-2xl
        sm:leading-40
        w-full
        text-left
        sm:text-center
        mb-8
        lg:mb-16
        `}
      >
        {headingText}
      </h1>
      <p
        className={`
          font-MarkPro
          w-full
          text-left
          sm:text-center
          mb-32
          lg:mb-56
          lg:mb-40
          leading-normal
          text-base lg:text-lg
        `}
      >
        {subText}
      </p>

      {!hideViewActionButton && (
        <Button
          type='primary'
          text='Create free account'
          htmlType='link'
          buttonClassName='min-h-48 min-w-full sm:min-w-343 text-base'
          onClick={(e) => {
            jsCookie.set(
              SIGNUP_INITIATED_FROM_COOKIE_NAME,
              SIGNUP_INITIATED_FROM_COOKIE_VALUES.OTHER_SIGNUP_LINK,
            );
            return true;
          }}
          href={
            !isUserLoggedIn || !isFeaturePaymentPage ? '/register' : '/checkout'
          }
          handler={() => {}}
        ></Button>
      )}
      {disclaimerText && <DisclaimerSection disclaimerText={disclaimerText} />}
    </section>
  );
};
export default withFeatureFlags(CreateAccountSection);
