import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormSection from '../../ui/ui-kit/FormSection';
import CheckBox from '../../ui/ui-kit/CheckBox';
import Label from '../../ui/ui-kit/Label';
import Button from '../../ui/ui-kit/Button';
import _ from 'lodash';
import voucherConfig from '../../../constants/voucherConfig';
import ReactHtmlParser from 'react-html-parser';
import Message from '../../ui/ui-kit/Message';
import { completeCurrentStage, isCurrentStage, getFormSectionProps, isLastStage } from '../../../ducks/stepper';
import { isLoggedIn } from '../../../ducks/user';

class VoucherTermsConditions extends Component {
    state = {
        checked: false,
        errorMessage: '',
        showFailure: false,
    }

    componentDidMount() {
        const { onLoad } = this.props;
        if (onLoad) {
            onLoad();
        }
    }

    shouldCompleteStage = (prevProps, prevState) => {
        const { inProgress: prevInProgress } = prevState;
        const { inProgress } = this.state;
        return !prevInProgress && inProgress;
    }

    shouldShowError = (prevProps) => {
        const { inProgress } = this.state;
        const { stepper, stage, user } = this.props;
        const { subscriptionUserCreate: currUser } = user; // TODO: Remove dependency `subscriptionUserCreate`. Use voucher state.
        const { subscriptionUserCreate: prevUser } = prevProps.user; // TODO: Remove dependency `subscriptionUserCreate`. Use voucher state.
        const { isSuccess } = currUser;
        const { isSuccess: prevUserCreateSuccess } = prevUser;
        const hasChanged = !_.isEqual(isSuccess, prevUserCreateSuccess);
        return inProgress && isCurrentStage(stepper, stage) && hasChanged && !isSuccess;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.shouldCompleteStage(prevProps, prevState)) {
            const { checked } = this.state;
            const { onSubmit } = this.props;
            if (onSubmit) {
                onSubmit({ checked });
            }
            this.setState({ showFailure: false });
            this.props.completeCurrentStage();
            return;
        }
        if (this.shouldShowError(prevProps)) {
            // TODO: Refactor error handling
            const errorMessage = _.get(this.props.user, 'subscriptionVoucher.voucherData.errorMessage') || `Sorry,
            we encountered an error and the code was not redeemed. Please try again.
            If the error persists, please contact Optus Sport support.`;
            this.setState({
                inProgress: false,
                showFailure: true,
                errorMessage
            });
            return;
        }
    }

    handleOnCheck = (checked) => {
        this.setState({ checked });
    }

    getButtonText = () => {
        const { stepper, user, stage } = this.props;
        const isLast = isLastStage(stepper, stage);
        const shouldApplyOnNextStep = isLoggedIn(user) && isLast;
        if (!shouldApplyOnNextStep) {
            return `Next`;
        }
        const { showFailure } = this.state;
        return showFailure ? `Please try again.` : `Submit`;
    }

    getTermsText = () => {
        // OSN-2729 - deprecate using ml-config as we will use a generic t&cs for all campaigns
        return _.get(voucherConfig, 'content');

        // const voucherData = _.get(this.props.user, 'subscriptionVoucher.voucherData');
        // return _.get(voucherData, 'termsAndConditions.content') || _.get(voucherConfig, 'content');
    }

    getCheckBoxText = () => {
        // OSN-2729 - deprecate using ml-config as we will use a generic t&cs for all campaigns
        return _.get(voucherConfig, 'checkBoxText');

        // const voucherData = _.get(this.props.user, 'subscriptionVoucher.voucherData');
        // return _.get(voucherData, 'termsAndConditions.checkbox.text') || _.get(voucherConfig, 'checkBoxText');
    }

    shouldShowCheckBox = () => {
        const voucherData = _.get(this.props.user, 'subscriptionVoucher.voucherData');
        return _.get(voucherData, 'termsAndConditions.checkbox.required', null) || _.get(voucherConfig, 'required');
    }

    render() {
        const {
            checked,
            inProgress,
            showFailure,
            errorMessage,
        } = this.state;
        const checkBoxText = this.getCheckBoxText();
        const termConditionsRichText = this.getTermsText();
        const showCheckBox = this.shouldShowCheckBox();

        const { stepper, stage } = this.props;
        const formSectionProps = getFormSectionProps(stepper, stage);
        return (
            <FormSection
                hideEdit
                label="Access code Terms &amp; Conditions"
                {...formSectionProps}
            >
                <div className="w-full">
                    <div className="voucher-terms-richtext text-xs max-h-240 p-16 font-MarkPro text-white leading-loose overflow-y-auto scrolling-touch">
                        {ReactHtmlParser(termConditionsRichText)}
                    </div>
                    {showCheckBox &&
                        <div>
                            <CheckBox className="mt-24" checked={checked} changed={this.handleOnCheck} id='terms-conditions'>
                                <Label className="text-sm leading-1.14" htmlFor='terms-conditions'>{checkBoxText}</Label>
                            </CheckBox>
                        </div>
                    }
                    <div className={`${(checked || !showCheckBox) ? 'pointer-events-auto' : 'pointer-events-none'}`}>
                        <Button
                            text={this.getButtonText()}
                            type="primary-alternate"
                            loading={inProgress}
                            handler={() => this.setState({ inProgress: true })}
                            width="w-full mt-24 mb-16 md:w-266 xl:w-276"
                            rounded
                        />
                    </div>
                    {
                        showFailure &&
                        <Message
                            type="failure"
                            className="mt-8 mb-16"
                            message={errorMessage}
                            open={true}
                        />
                    }
                </div>
            </FormSection>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        stepper: state.stepper,
    };
}

const mapDispatchToProps = {
    completeCurrentStage,
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherTermsConditions);
