import React from 'react';
import PropTypes from 'prop-types';
import { getAbsoluteStaticUrl } from 'utils/path';

const GroupHeader = ({imgUrl, className, onClick, label, totalCount, index, title}) => (
  <button
    onClick={(e) => onClick(e, totalCount, index, label)}
    className={`flex flex-no-wrap justify-center items-center h-80 w-51 bg-medium-dark-grey hover:bg-medium-grey os-transition-btn os-box-sizing-border-box flex-none outline-none ${className}`}
    title={title}
  >
    <span className="h-24 w-24">
      <img src={getAbsoluteStaticUrl(`/images/${imgUrl}`)} className="object-contain" alt={label} />
    </span>
  </button>
);

GroupHeader.propTypes = {
  /** URL to the competition icon to be used. */
  imgUrl: PropTypes.string.isRequired,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Alt text */
  alt: PropTypes.string.isRequired,
  /** Click handler function */
  onClick: PropTypes.func,
  /** Label text */
  label: PropTypes.string,
  /** Title text */
  title: PropTypes.string,
};

GroupHeader.defaultProps = {
  className: '',
  alt: '',
  imgUrl: '',
  title: '',
};

export default GroupHeader;
