import React from 'react';
import PropTypes from 'prop-types';

const WarningIcon = ({ width, height, color, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 32 32`} {...props}>
  <g fill="none" fillRule="evenodd">
  <path fill="#FFF" fillOpacity="0" d="M0 0h32v32H0z"/>
  <path fill="#E5BB00" d="M15.999 8A7.999 7.999 0 0 0 8 16a8 8 0 1 0 7.999-8z"/>
  <path fill="#000" d="M11.36 18.964l4.695-8.139 4.695 8.139h-9.39zm6.585-1.676l-1.9-3.453-1.9 3.453h3.8z"/>
  </g>
  </svg>
  );

WarningIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

WarningIcon.defaultProps = {
	width: 32,
	height: 32
};

export default WarningIcon;
