import React from 'react';
import { getAbsoluteStaticUrl } from 'utils/path';

const PaymentCard = ({ detail, borderStyle = '' }) => {

  const {
    cardBrand = 'generic-card',
    cardLastFourDigits = '0000',
    cardExpirationDate = '01/70'
  } = detail;

  const cardImage = getAbsoluteStaticUrl(`/images/cards/${cardBrand}.svg`);

  return (
    <div className={`flex flex-col payment-card rounded-lg mb-24 ${borderStyle}`}>
      <div className={`flex flex-row-reverse pt-8 pr-16`}>
        <img
          className={`relative os-top-8`}
          width='48'
          height='48'
          src={cardImage}
          alt={cardBrand}
          onError={e => e.target.src = getAbsoluteStaticUrl('/images/cards/generic-card.svg')}
        />
      </div>
      <div className={`pl-20 font-MarkProBold text-base leading-normal text-white`}>
        <div className={`w-43 h-32 mb-10 payment-card-details-bg rounded-lg`} />
        <div className={`w-fit-content mb-8 payment-card-details-bg rounded-lg`}>
          <span className={`px-8`}>• • • •  • • • •  • • • • {cardLastFourDigits}</span>
        </div>
        <div className={`w-fit-content mb-24 payment-card-details-bg rounded-lg`}>
          <span className={`px-8`}>{cardExpirationDate}</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
