import React, { useEffect, useState } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withFeatureFlags } from '../../context/withFeatureFlags';
import * as editorialAction from '../../../actions/editorial';
import { selectors } from 'store/createPortalStore';
import { getCarouselLayout } from '../../../components/ui/new-home/utils';
import { getEditorialLimit } from '../../ui/new-home/utils';
import { isValidContentCardByBE } from './utils';
import SectionLoader from '../../ui/ui-kit/SectionLoader';

function DynamicRail({
  assetId,
  getCarouselLayoutParams,
  contentCardsInObj,
  favTeamCode,
  editorialActions,
  getFeatureFlags,
  editorials,
}) {
  const [bucketKey, setBucketKey] = useState(undefined);
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFavourite, setIsFavourite] = useState(false);

  useEffect(() => {
    if (contentCardsInObj.isReady && favTeamCode.isReady) {
      const {
        data: contentCardsInObjData,
        dataExtra: contentCardsDataExtra,
      } = contentCardsInObj;
      if (isValidContentCardByBE({ id: assetId }, contentCardsInObjData)) {
        const contentCards = contentCardsDataExtra || [];
        const contentCard = contentCards.find(
          (contentCard) => contentCard.id === assetId,
        );
        const panel = getCarouselLayoutParams?.carousel || {};
        const limit = getEditorialLimit(panel);
        if (contentCard?.dataSource === 'FAVOURITE') {
          setIsFavourite(true);
          if (favTeamCode.data) {
            setBucketKey(assetId);
            editorialActions.loadFavouriteEditorial(
              assetId,
              limit,
              favTeamCode.data,
              panel,
            );
          } else {
            setIsLoading(false);
            setIsReady(false);
            setBucketKey(undefined);
          }
        } else {
          const panelId = (contentCard?.bucketKey || '')
            .replace(/-/g, '_')
            .toLowerCase();
          setBucketKey(panelId);
          const returnPanel = { ...panel, id: panelId };
          editorialActions.loadEditorial(
            panelId,
            limit,
            returnPanel,
            getFeatureFlags,
          );
        }
      } else {
        setIsLoading(false);
        setIsReady(false);
      }
    }
    // eslint-disable-next-line
  }, [contentCardsInObj.isReady, favTeamCode.isReady]);

  useEffect(() => {
    if (bucketKey) {
      const editorial = editorials.find(
        (editorial) => editorial.id === bucketKey,
      );

      if (editorial?.isSuccess) {
        setIsReady(true);
        setIsLoading(false);
      }
      if (!editorial?.isSuccess && !editorial?.inProgress) {
        setIsLoading(false);
      }
    }
  }, [bucketKey, editorials]);

  if (isLoading) {
    return <SectionLoader />;
  }

  if (isReady) {
    const params = {
      ...getCarouselLayoutParams,
      carousel: {
        ...getCarouselLayoutParams?.carousel,
        id: bucketKey,
        ...(isFavourite && { showViewAllLink: false }),
      },
    };
    return getCarouselLayout({ ...params });
  }

  return <></>;
}

function mapStateToProps(state) {
  const contentCardsInObj = selectors.contentCards.getContentCardsInObj(state);
  const favTeamCode = selectors.user.getFavTeamCode(state);
  return {
    editorials: state.editorials,
    contentCardsInObj,
    favTeamCode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editorialActions: bindActionCreators(editorialAction, dispatch),
  };
}

export default compose(
  withFeatureFlags,
  connect(mapStateToProps, mapDispatchToProps),
)(DynamicRail);
