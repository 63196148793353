import React, { useEffect } from 'react';
import { selectors, useSelector } from '../../../store/createPortalStore';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import SuccessIcon from '../ui-kit/Icons/SuccessIcon';
import FailIcon from '../ui-kit/Icons/FailIcon';
import Button from '../ui-kit/Button';
import defaultConfig from '../../../constants/defaultConfig';
import voucherConfig from '../../../constants/voucherConfig';
import _ from 'lodash';
import { triggerGoogleAnalyticsEvent } from '../../../utils';
import { resetStage, getFirstStage } from '../../../ducks/stepper';
import { isInternationalUser } from 'selectors/user';

const redirectToURL = () => {
  const externalUrl = defaultConfig.campaignSuccessRedirectURL;
  if (externalUrl) {
    window.open(externalUrl, '_self');
    return;
  }
};

const Congratulations = (props) => {
  const { stepper: stepperSelector, voucher: voucherSelector } = selectors;
  // Selectors
  const error = useSelector(voucherSelector.error);
  const stepper = useSelector(stepperSelector.stepper);
  // Props
  let voucherData = _.get(props, 'subscriptionVoucher.voucherData');
  const isValid = _.get(voucherData, 'eligibility');
  // Hooks
  const dispatch = useDispatch();
  const isIntlUser = useSelector(isInternationalUser);

  if (isIntlUser) {
    voucherData = {
      successDialog: {
        title: 'Welcome to Optus Sport!',
        content: `<b>Important note:</b> If you are on roaming, please ensure you are connected to a local Australian Wi-Fi network when using Optus Sport. The service may not work if you are using mobile data while roaming.`,
        footnote: '',
      },
    };
  }

  useEffect(() => {
    if (!isValid || error) {
      triggerGoogleAnalyticsEvent('voucher', 'ineligible', 'load');
    } else {
      triggerGoogleAnalyticsEvent('voucher', 'success', 'load');
    }
    // eslint-disable-next-line
  }, [isValid, error]);

  const redirectToEnterVoucher = () => {
    const stage = getFirstStage(stepper);
    dispatch(resetStage({ stage }));
  };

  const renderSubscriptionUserFailure = () => (
    <div>
      We encountered an error and the code was not redeemed. Please try again.
      If the issue persists, please refer to{' '}
      <a
        className={`
          os-transition-btn
          text-teal
          hover:text-teal-dark
          active:text-teal-light
        `}
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.optus.com.au/for-you/support/answer?id=7054'
      >
        {`Gift Card help`}
      </a>
      {' or '}
      <a
        className={`
          os-transition-btn
          text-teal
          hover:text-teal-dark
          active:text-teal-light
        `}
        target='_blank'
        rel='noopener noreferrer'
        href='/help'
      >
        {`contact us.`}
      </a>
    </div>
  );

  const title =
    _.get(voucherData, 'successDialog.title') ||
    _.get(voucherConfig, 'successTitle');
  const content =
    _.get(voucherData, 'successDialog.content') ||
    _.get(voucherConfig, 'successContent');
  const footnote = _.get(voucherData, 'successDialog.footnote', null);
  const errorContent =
    _.get(voucherData, 'errorDialog.content', null) ||
    _.get(voucherConfig, 'errorContent');
  const hasSuccessBackgroundImage = _.get(
    voucherData,
    'successDialog.hasSuccessBackgroundImage',
  );
  const successPageBackgroundImage =
    _.get(voucherData, 'successDialog.successPageBackgroundImage') ||
    _.get(voucherConfig, 'successPageBackgroundImage');
  const successPageMainImage1X =
    _.get(voucherData, 'successDialog.successPageMainImage1X') ||
    _.get(voucherConfig, 'successPageMainImage1X');
  const successPageMainImage2X =
    _.get(voucherData, 'successDialog.successPageMainImage2X') ||
    _.get(voucherConfig, 'successPageMainImage2X');

  if (hasSuccessBackgroundImage && isValid && !error) {
    return (
      <CSSTransition in timeout={1500} classNames='os-dropdown' unmountOnExit>
        <div className='flex w-full flex-col'>
          <div
            className='relative w-full overflow-hidden h-309 bg-auto-100% md:bg-100% xl:bg-100% xl:h-544 bg-no-repeat bg-center text-center md:bg-100 xl:bg-100'
            style={{ backgroundImage: `url(${successPageBackgroundImage})` }}
          >
            <div className='voucherHeroGradient-top h-160 w-1440' />
            <div className='flex flex-col items-center'>
              <img
                className='w-322 h-auto xl:w-618 absolute pin-t'
                srcSet={`${successPageMainImage1X} 1x, ${successPageMainImage2X} 2x`}
                alt='campaing-logo'
              />
            </div>
            <div className='os-linear-gradient-bottom-mask' />
          </div>
          <div className='flex items-center justify-center z-100'>
            <div className={`align-middle text-center`}>
              <SuccessIcon className={`block m-auto text-center`} />
              <h2 className='block text-2xl mt-32 mb-16 leading-none text-center font-MarkProHeavy md:mb-32 xl:text-4xl xs:w-369'>
                {title}
              </h2>
              <div className='font-MarkPro leading-normal text-center text-base w-full xs:w-369'>
                <div>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                  {footnote && <div className='text-xs'> *{footnote}</div>}
                </div>
              </div>
              <div className='flex justify-center mt-32'>
                <Button
                  className='w-full xs:w-369 text-black'
                  type='primary'
                  width='w-full'
                  text={error ? 'Please try again.' : 'Explore Optus Sport'}
                  handler={error ? redirectToEnterVoucher : redirectToURL}
                  rounded
                />
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
  return (
    <CSSTransition in timeout={1500} classNames='os-dropdown' unmountOnExit>
      <div className='mt-48 flex items-center justify-center'>
        <div className='align-middle text-center mt-32 md:mt-40 xl:mt-96'>
          {!isValid || error ? (
            <>
              <FailIcon className={`block m-auto text-center`} />
              <h2 className='block text-2xl mt-32 mb-16 leading-none text-center font-MarkProHeavy md:mb-32 xl:text-4xl'>
                Sorry
              </h2>
              <div className='font-MarkPro leading-normal text-base w-full xs:w-369'>
                <div>
                  <div>
                    {error ? renderSubscriptionUserFailure() : errorContent}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <SuccessIcon className={`block m-auto text-center`} />
              <h2 className='block text-2xl mt-32 mb-16 leading-none text-center font-MarkProHeavy md:mb-32 xl:text-4xl xs:w-369'>
                {title}
              </h2>
              <div className='font-MarkPro leading-normal text-base w-full xs:w-369'>
                <div>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                  {footnote && <div className='text-xs'> *{footnote}</div>}
                </div>
              </div>
            </>
          )}
          <div className='flex justify-center mt-32'>
            <Button
              className='w-full xs:w-369 text-black'
              type='primary'
              width='w-full'
              text={error ? 'Please try again.' : 'Explore Optus Sport'}
              handler={error ? redirectToEnterVoucher : redirectToURL}
              rounded
            />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Congratulations;
