import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { parsePhoneNumberFromString, isValidNumber } from 'libphonenumber-js';
import Input from './ui-kit/Input';
import { ERROR_MESSAGES } from '../../ducks/verification';

const validations = {
  AU: {
    prefixes: {
      national: (value) => /^04/.test(value),
      international: (value) => /^\+614/.test(value),
    },
    maxLength: {
      // https://optussport.atlassian.net/browse/WB-988
      // This is just a quick fix for now to not affect the Input component.
      national: 20,
      international: 20,
    },
    validator: (value) => {
      const national = /^04\d{8}$|^[1-9]\d{8}$/.test(value);
      const international = /^\+614\d{8}$|^[1-9]\d{8}$/.test(value);
      return national || international;
    },
  },
};

class PhoneNumberInput extends React.Component {
  ref = React.createRef();
  
  state = {};

  country = `AU`;

  getCountry = () => {
    return this.props.country || this.country;
  }

  isValid = (value) => {
    try {
      const country = this.getCountry();
      const { validator } = validations[country];
      const isValidFormat = validator(value);
      const parsed = parsePhoneNumberFromString(value, country);
      return !isEmpty(parsed) && isValidNumber(parsed.number) && isValidFormat;
    } catch (error) {
      return false;
    }
  }

  isChanged = (number) => {
    return number !== this.state.number;
  }

  getValidationMessage = (isValid) => {
    return isValid
      ? null
      : ERROR_MESSAGES.INVALID_PHONE_NUMBER;
  }

  setMaxLength = (number) => {
    const country = this.getCountry();
    const { maxLength } = validations[country];
    const { prefixes } = validations[country];
    if (prefixes.national(number) && this.state.maxLength !== maxLength.national) {
      this.setState({ maxLength: maxLength.national });
      return;
    }
    if (prefixes.international(number) && this.state.maxLength !== maxLength.international) {
      this.setState({ maxLength: maxLength.international });
      return;
    }
    // Known limitation: When copy-pasting, the value and maxLength are updated together.
    // Ideally, maxLength should be set first before value.
    // To demonstrate the issue:
    // 1. Type a national number
    // 2. Copy-paste an international number
    // 3. The pasted number is maxed out to the national number max length.
    // if (!this.state.dynamicMaxLength && number.length === maxLength.international && this.state.maxLength === maxLength.national) {
    //   console.log(`>>> copy-paste`);
    //   this.setState({
    //     maxLength: maxLength.international,
    //     dynamicMaxLength: true,
    //   });
    //   return;
    // }
  }

  validator = (number) => {
    const isValid = this.isValid(number);
    const validationMessage = this.getValidationMessage(isValid);
    const overrideValidationMessage = this.onValidate({ number, isValid });
    return overrideValidationMessage || validationMessage;
  }

  onValidate = ({ number, isValid }) => {
    if (!this.isChanged(number)) {
      return;
    }
    this.setState({ number, isValid });
    const { onValidate } = this.props;
    if (onValidate) {
      const country = this.getCountry();
      const parsedNumber = parsePhoneNumberFromString(number, country);
      return onValidate({ number, parsedNumber, isValid });
    }
  }

  render() {
    const { defaultValue, autoFocus } = this.props;
    const { maxLength } = this.state;
    return (
      <Input
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        disabled={this.props.disabled}
        className="leading-1.14 mb-9 md:w-360 xl:w-381"
        type="tel"
        inputRef={this.ref}
        labelText="Mobile Number *"
        placeHolder="e.g 0400 123 123"
        validate
        changed={this.setMaxLength}
        validator={this.validator}
        maxLength={maxLength}
      />
    );
  }
};

export default PhoneNumberInput;
