import React from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';

const large = 'large';
const small = 'small';

class ScoreProtection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: cookie.load('scoreprotection') === "true" ? true : false,
      disabled: props.disabled
    }
  }

  handleToggle (e) {
    if(this.state.disabled)
      return;

    //Toggle state on click
    let nextState = !this.state.status;

    cookie.save('scoreprotection', nextState, { path: '/' })

    this.setState({
      status: nextState
    });

    if(typeof this.props.changed === 'function')
      this.props.changed(nextState);
  }

  buttonBorderColor = (disabled, status) => (disabled && 'opacity-25 border-white') || (status ? 'border-green' : 'border-white');
  buttonBgColor = (disabled, status) => (disabled && 'bg-white') || (status ? 'bg-green' : 'bg-white');

  render () {
    const { className } = this.props;
    const { status, disabled } = this.state;
        
    return (
      <section className="score-protection-wrapper w-96 h-80 bg-medium-dark-grey">
        <header className="pt-16 pb-12">
          <h6 className="text-white font-MarkProBold text-xs text-center leading-loose">Scores</h6>
        </header>

        <div className={`flex flex-no-wrap justify-center items-center ${ className ? ` ${className}` : ''}`}>
          <span className={`label-left font-MarkProBold text-2xs text-right leading-1.6 mr-5 ${!status ? 'text-white': 'text-medium-grey'}`}>OFF</span>

          <div className={`mb-2 w-25 h-16 inline-block relative`}>
            <div className={`${this.buttonBorderColor(disabled, status)} os-transition relative border-2 rounded-2xl border-grey cursor-pointer w-25 h-16 px-2`}>
              <span className={`os-transition os-transitionproperty-all ${status ? `os-tx-100p`: 'os-tx-0p'} ${this.buttonBgColor(disabled, status)} absolute rounded-full w-8 h-8 mt-2 mx-0 shadow os-shadow-toggle`}>
              </span>
            </div>
            <input className={`absolute cursor-pointer opacity-0 w-25 h-16 pin-t`} type="checkbox" checked={status} onChange={(e) => this.handleToggle(e) }/>
          </div>

          <span className={`label-right font-MarkProBold text-2xs text-left leading-1.6 ml-5 ${status ? 'text-green': 'text-light-grey'}`}>ON</span>
        </div>
      </section>
    )
  };
}



ScoreProtection.propTypes = {
  /** Click handler function for this switch. This function will be invoked with the click event received on the switch */
  onClick: PropTypes.func,
  /** Focus handler function for this switch. This function will be invoked with the focus event received on the switch */
  onFocus: PropTypes.func,
  /** Blur handler function for this switch. This function will be invoked with the blur event received on the switch */
  onBlur: PropTypes.func,
  /** State of the switch */
  status: PropTypes.bool,
  /** Whether the switch is disabled or not. Defaults to false.**/
  disabled: PropTypes.bool,
  /** Handler function to invoke when a state change is detected. This function will be invoked with current status of the switch**/
  changed: PropTypes.func,
  /** Label for this switch*/
  label: PropTypes.string,
  /** Type of this switch*/
  type: PropTypes.oneOf([large,small]),
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Whether to show the current status of the switch */
  showStatus: PropTypes.bool,
  /** Whether to show both On and Off labels */
  showAllLabels: PropTypes.bool
};

ScoreProtection.defaultProps = {
  status: false,
  disabled: false,
  onClick: null,
  changed: null,
  onFocus: null,
  onBlur: null,
  label: null,
  type: small,
  className: null,
  showStatus: false,
  showAllLabels: false
};

export default ScoreProtection;
