import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import Input from 'components/ui/ui-kit/Input';
import UserCompetitionForm from 'components/ui/UserCompetitionForm';
import Label from 'components/ui/ui-kit/Label';
import SelectBox from 'components/ui/ui-kit/SelectBox';
import DatePicker from 'components/ui/DatePicker';
import CheckBox from 'components/ui/ui-kit/CheckBox';
import { validateField } from './utils';
import Button from 'components/ui/ui-kit/Button';
import TextArea from 'components/ui/ui-kit/TextArea';
import Accordion from 'components/ui/ui-kit/Accordion';
import Countdown from 'components/ui/ui-kit/Countdown';
import TeamSelect from 'components/ui/ui-kit/TeamSearch/TeamSelect';
import ReCAPTCHA from 'react-google-recaptcha';
import defaultConfig from 'constants/defaultConfig';
import Link from 'components/ui/ui-kit/Link';
import Rail from 'components/ui/UserCompetitionForm/renderRail';

export const parseTemplate = (template, props) => {
  return iterator(template, props);
};

const iterator = (template, props) => {
  const parsed = [];
  map(template, (block) => {
    const cssStyleClass = parseStyles(block);
    const cssClass = cssStyleClass ? { className: cssStyleClass } : {};

    let clickActionHandler = null;
    if (block?.action && props?.actionsList) {
      clickActionHandler = get(props.actionsList, block.action, null);
    }

    if (!isEmpty(block.type)) {
      props.iteratorIndex += 1;
      block.key = `${block.type}_${props.iteratorIndex}`;
      const defaultValue = geDefaultValue(block, props);
      switch (block.type) {
        case 'LAYOUT': {
          parsed.push(parseLayout(block, cssClass, props));
          break;
        }
        case 'TEXT': {
          parsed.push(parseText(block, cssClass));
          break;
        }
        case 'TEXT_UL': {
          parsed.push(parseTextUnorderedList(block, cssClass, props));
          break;
        }
        case 'TEXT_OL': {
          parsed.push(parseTextOrderedList(block, cssClass, props));
          break;
        }
        case 'TEXT_LI': {
          parsed.push(parseTextList(block, cssClass));
          break;
        }
        case 'PICTURE': {
          parsed.push(parsePicture(block, cssClass));
          break;
        }
        case 'IMAGE': {
          parsed.push(parseImage(block, cssClass));
          break;
        }
        case 'COUNTDOWN': {
          parsed.push(
            <Countdown
              countDownStartDate={block?.startDateTime}
              countDownEndDate={block?.endDateTime}
              key={block?.key}
              style={cssStyleClass}
              templateProps={props}
            />,
          );
          break;
        }
        case 'INPUT_TEXT': {
          parsed.push(
            <Input
              key={block.key}
              show={true}
              type='string'
              id={block.id}
              name={block.id}
              labelText={block.label}
              placeHolder={block.placeHolder}
              disabled={block.disabled}
              width={cssStyleClass}
              inputFieldBgColor='bg-black'
              value={defaultValue}
              changed={(value, event) => props?.onFieldChanged(block.id, value)}
              validate={true}
              validator={(value, e) => {
                return validateField(block, props, value);
              }}
            />,
          );
          break;
        }
        case 'INPUT_SELECT': {
          parsed.push(
            <div key={block.key} {...cssClass}>
              <div className='w-full relative flex flex-col items-left'>
                <Label className='font-bold'>Gender</Label>
                <SelectBox
                  width='w-full mt-16'
                  inputFieldBgColor='bg-black'
                  defaultItem={block?.options?.find(
                    (g) => g.value === defaultValue,
                  )}
                  items={(() =>
                    block?.options?.filter((el) => el.value !== ''))()}
                  changed={(value, event) => {
                    const isRequired = block?.required || false;
                    if (isRequired) {
                      validateField(block, props, value.value);
                    }
                    return props?.onFieldChanged(block.id, value.value);
                  }}
                  validateOnLoad={true}
                  validationFn={(value) =>
                    validateField(block, props, value.value)
                  }
                  placeHolder={block?.placeHolder}
                  isFormField={block?.isFormField}
                  disabled={block?.disabled}
                />
              </div>
            </div>,
          );
          break;
        }
        case 'DATE_PICKER': {
          parsed.push(
            <DatePicker
              key={block.key}
              show={true}
              id={block.id}
              name={block.id}
              labelText={block.label}
              placeHolder={block.placeHolder}
              showIcon
              containerClass={cssStyleClass}
              inputFieldBgColor='bg-black'
              width='w-full'
              disabled={block.disabled}
              defaultValue={defaultValue}
              showLockedIcon={false}
              validate={true}
              onChange={(value) => props?.onFieldChanged(block.id, value)}
              validator={(value) => {
                return validateField(block, props, value);
              }}
            />,
          );
          break;
        }
        case 'INPUT_TEXT_AREA': {
          parsed.push(
            <TextArea
              type='string'
              key={block.key}
              id={block.id}
              name={block.id}
              labelText={block.label}
              labelTextSize={`text-base`}
              value={defaultValue}
              maxWords={block?.maxWords}
              inputFieldBgColor='bg-black'
              changed={(value) => props?.onFieldChanged(block.id, value)}
              placeHolder={block.placeholder}
              validate={true}
              validator={(value) => {
                return validateField(block, props, value);
              }}
            />,
          );
          break;
        }
        case 'CHECKBOX': {
          parsed.push(
            <CheckBox
              name={block.id}
              key={block.key}
              className='mt-24'
              checked={!!defaultValue}
              changed={(value, event) => {
                //const isRequired = block?.required || false;
                validateField(block, props, value);
                return props?.onFieldChanged(block.id, value);
              }}
              id={block.id}
            >
              <Label
                className='text-sm leading-normal'
                htmlFor={block.id}
                dangerouslySetInnerHTML={{ __html: block?.label || '' }}
              ></Label>
            </CheckBox>,
          );
          break;
        }
        case 'SUBMIT': {
          parsed.push(
            <Button
              text='Submit'
              key={block.key}
              width='w-full'
              handler={(e) => props?.onFormSubmit(e)}
              loading={props?.formState?.isLoading || false}
              type='primary'
              className={`${cssStyleClass} ${
                props?.formState?.allFieldsValid
                  ? 'pointer-events-auto'
                  : 'pointer-events-none'
              }`}
              disabled={!props?.formState?.allFieldsValid}
            />,
          );
          break;
        }
        case 'BUTTON': {
          parsed.push(
            <Button
              text={block.label}
              key={block.key}
              width='w-full'
              handler={clickActionHandler}
              type={block.subType}
              className={cssStyleClass}
            />,
          );
          break;
        }
        case 'RECAPTCHA': {
          parsed.push(
            <div
              className={`recaptchaWrapper ${cssStyleClass}`}
              key={block.key}
            >
              <ReCAPTCHA
                ref={props?.recaptchaRef}
                size='normal'
                sitekey={defaultConfig.recaptchaApiKey}
                asyncScriptOnLoad={() => {
                  validateField(block, props, '');
                }}
                onChange={(value) => {
                  validateField(block, props, value);
                  return props?.onFieldChanged(block.id, value);
                }}
                theme='dark'
              />
            </div>,
          );
          break;
        }
        case 'LINK': {
          parsed.push(
            <div className={cssStyleClass} key={block.key}>
              <Link
                link={block.to || '#'}
                target={block.target || 'self'}
                onClick={clickActionHandler}
              >
                {block.text}
              </Link>
            </div>,
          );
          break;
        }
        case 'LINK_BUTTON': {
          break;
        }
        case 'ACCORDION': {
          parsed.push(
            <Accordion key={block.key} {...cssClass} theme={'dark'}>
              {iterator(block?.elements, props)}
            </Accordion>,
          );
          break;
        }
        case 'ACCORDION_SECTION': {
          parsed.push(
            <div
              label={block.title}
              isOpen={false}
              key={block.key}
              customChildClassNames={cssStyleClass}
            >
              <div
                dangerouslySetInnerHTML={{ __html: block?.content || '' }}
              ></div>
            </div>,
          );
          break;
        }
        case 'USER_COMPETITION_FORM': {
          parsed.push(
            <UserCompetitionForm
              key='main_template'
              template={block}
              styles={cssStyleClass}
              templateProps={props}
            />,
          );
          break;
        }
        case 'TEAM_SELECT': {
          const containerStyles = parseStyles({
            styles: block.containerStyles,
          });
          parsed.push(
            <TeamSelect
              key={block.key}
              className={cssStyleClass}
              items={props?.filteredTeamsList}
              label={block?.label}
              placeholder={block?.placeholder}
              sortBy={block?.sortBy}
              filterBy={block?.filterBy}
              containerClass={containerStyles}
              onChange={(value) => {
                validateField(block, props, value);
                return props?.onFieldChanged(block.id, value);
              }}
            />,
          );
          break;
        }
        case 'RAIL': {
          parsed.push(
            <Rail key={block.key} block={block} styles={cssStyleClass} />,
          );
          break;
        }
        case 'DIVIDER': {
          parsed.push(
            <h3 key={block?.key} className='line-around-text'>
              <span>{block?.text}</span>
            </h3>,
          );
          break;
        }
        default: {
        }
      }
    }
  });
  return parsed;
};

const parseLayout = (block, cssClass, props) => {
  return (
    <div {...cssClass} key={block.key}>
      {iterator(block.elements, props)}
    </div>
  );
};

const parseText = (block, cssClass) => {
  return (
    <p
      key={block.key}
      {...cssClass}
      dangerouslySetInnerHTML={{ __html: block?.defaultValue || '' }}
    ></p>
  );
};

const parseTextList = (block, cssClass) => {
  return (
    <li
      key={block.key}
      {...cssClass}
      dangerouslySetInnerHTML={{ __html: block?.defaultValue || '' }}
    ></li>
  );
};

const parseTextUnorderedList = (block, cssClass, props) => {
  const listElements = block?.elements || [];
  if (listElements.length) {
    return (
      <ul {...cssClass} key={block.key}>
        {iterator(listElements, props)}
      </ul>
    );
  }
  return [];
};

const parseTextOrderedList = (block, cssClass, props) => {
  const listElements = block?.elements || [];
  if (listElements.length) {
    const listType = block?.listType;
    return (
      <ol
        {...(listType ? { type: listType } : {})}
        {...cssClass}
        key={block.key}
      >
        {iterator(listElements, props)}
      </ol>
    );
  }
  return [];
};

const parsePicture = (block, cssClass) => {
  const { default: defaultValue, ...restValues } = block?.src;
  const srcStyles = parseStyles({ styles: block.srcStyles });

  return (
    <picture {...cssClass} key={block.key}>
      {map(
        restValues,
        (value, key) =>
          value && <source key={key} media={key} srcSet={value} />,
      )}
      <img src={defaultValue} alt={block?.alt || ''} className={srcStyles} />
    </picture>
  );
};

const parseImage = (block, cssClass) => {
  return (
    <img
      key={block.key}
      {...cssClass}
      src={block?.src}
      alt={block?.alt || ''}
    />
  );
};

const parseStyles = ({ styles = {} }) => {
  const { default: defaultStyle = '', ...restStyles } = styles;
  let classNames = defaultStyle;

  forEach(restStyles, (value, key) => {
    classNames +=
      ' ' +
      value
        .split(' ')
        .map((v) => (v ? `${key}:${v}` : null))
        .join(' ');
  });
  return classNames;
};

const geDefaultValue = (block, props) => {
  const fieldObj = find(props?.formFieldValues, { id: block.id });
  return get(fieldObj, 'value', get(block, 'defaultValue', ''));
};
