import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DownIcon from './Icons/DownIcon'

class EpgRow extends Component {
  onClick = () => {
    const { event, eventId, onClick } = this.props;

    if (onClick && typeof onClick === 'function') {
      onClick(eventId || _.get(event, 'eventId'));
    }
  };

  render() {
    const { event, isOpen, className } = this.props;

    const {
      durationHours,
      durationMinutes,
      endTime,
      isCurrent,
      isFinished,
      isUpcoming,
      longDescription,
      name,
      shortDescription,
      time
    } = event;
    const timespan = `${time.format('h:mma')} - ${endTime.format('h:mma')}`;
    let customClassName;

    if (isCurrent) {
      customClassName = ' current';
    } else if (isFinished) {
      customClassName = ' finished';
    } else if (isUpcoming) {
      customClassName = ' upcoming';
    }

    return (
      <div
        className={`
          py-4
          font-MarkProBold
          min-w-360
          lg:py-8
          ${isCurrent ? 'text-teal' : 'text-white'}
          ${className || '' }
          ${customClassName || ''}
        `}
      >
        <div
          className={`
            cursor-pointer
            flex
            min-h-72
            hover:bg-medium-dark-grey
            ${isOpen ? 'bg-medium-dark-grey' :'bg-widget-grey'}
            ${isFinished && !isOpen ? 'opacity-50' : ''}
          `}
          onClick={this.onClick}
        >
          <div
            className={`
              bg-white10
              flex-1
              leading-loose
              max-w-85
              pt-16
              text-center
              text-xs
              tracking-0.1
              ${isOpen ? 'border-l-3 border-teal' : ''}
            `}
          >
            <p
              className={`
                ${isOpen ? '-ml-3' : ''}
              `}
            >
              <span>{time.format('h:mm')}</span>
              <span
                className={`
                  font-MarkPro
                `}
              >
                {time.format('a')}
              </span>
            </p>
          </div>
          <div
            className={`
              flex-1
              mt-16
              ml-22
            `}
          >
            <ul
              className={`
                leading-1.14
                list-reset
                text-sm
              `}
            >
              <li>{name}</li>
              <li
                className={`
                  font-MarkPro
                  mt-8
                `}
              >{shortDescription}</li>
            </ul>
            <div
              className={`
                flex
                flex-col
                font-MarkPro
                os-dropdown
                text-white
                text-xs
                ${isOpen ? 'os-transition-slide-down mt-8' : 'os-transition-slide-up mt-8'}`}
            >
              <div
                className={`
                  opacity-50
                  pb-8
                `}
              >
                <span
                  className={`
                    pr-16
                  `}
                >
                  {timespan}
                </span>
                <span>
                  {'Duration: ' + (durationHours > 0 ? (durationHours + ' hrs ') : '') + (durationMinutes > 0 ? (durationMinutes + ' min') : '')}
                </span>
              </div>
              <div
                className={`
                  pb-16
                `}
              >
                <span>{longDescription}</span>
              </div>
            </div>
          </div>
          <div
            className={`
              mt-8
              mr-8
            `}
          >
            {!isOpen && <span><DownIcon/></span>}
            {isOpen && <span><DownIcon invert color="#05cedd"/></span>}
          </div>
        </div>
      </div>
    );
  }
}

EpgRow.propTypes = {
  className: PropTypes.string,
  eventId: PropTypes.number,
  event: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

EpgRow.defaultProps = {
  isOpen: false
};


export default EpgRow;
