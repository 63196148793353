export const postCodeRegex = new RegExp(
  /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/,
);

export const genderSelectValues = [
  {
    label: 'Select Gender',
    value: '',
  },
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Non-binary',
    value: 'non-binary',
  },
  {
    label: "I'd rather not say",
    value: 'not-applicable',
  },
];

export const getValidGenderValues = () =>
  genderSelectValues.filter((el) => el.value !== '');

export const getGenderMappingByValue = (value) =>
  genderSelectValues.find((g) => g.value === value);
