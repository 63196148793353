import React from 'react';
import PropTypes from 'prop-types';

const calcMarginY = isAtTop => isAtTop ? `my-24` : `mb-24`;

const H2 = ({children, className, marginY, isAtTop, color, ...props }) => (
  <h2
    className={`
      text-3xl
      lg:text-4xl
      font-MarkProHeavy
      ${color}
      ${className}
      ${ marginY || calcMarginY(isAtTop) }
    `}
    {...props}
  >
    {children}
  </h2>
);

H2.propTypes = {
  /** tailwind class names for styling */
  className: PropTypes.string,
  /** Child components*/
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** tailwind margin classes to add vertical margin, default if my-24 */
  marginY: PropTypes.string,
  /** true to remove top margin, else false */
  isAtTop: PropTypes.bool,
  /** Tailwind color class, default is text-white*/
  color: PropTypes.string,
};

H2.defaultProps = {
  className: ``,
  marginY: null,
  isAtTop: false,
  color: 'text-white',
};

export default H2;
