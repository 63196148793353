import React, { useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import parseMobile, { parsePhoneNumber } from 'libphonenumber-js/mobile';
import { ERROR_MESSAGES } from '../../ducks/verification';
import Label from './ui-kit/Label';
import { usePrevious } from 'utils/hooks';

const InternationalPhoneNumberInput = ({
  onValidate,
  autoFocus,
  width = 'md:w-360 xl:w-381',
  containerClass = 'mb-25',
}) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const prevPhoneNumber = usePrevious(phoneNumber);
  const [isValid, setIsValid] = useState(true);
  const [validatorMessage, setValidatorMessage] = useState('');

  const getValidationMessage = (isValid) => {
    return isValid ? null : ERROR_MESSAGES.INVALID_PHONE_NUMBER;
  };

  const validator = (number) => {
    let isValidNum = true;
    if (!number) {
      isValidNum = false;
    } else {
      isValidNum = isValidPhoneNumber(number) && parseMobile(number).isValid();
    }
    const validationMessage = getValidationMessage(isValidNum);
    const overrideValidationMessage = onValidateFn({
      number,
      isValid: isValidNum,
    });
    return overrideValidationMessage || validationMessage;
  };

  const onValidateFn = ({ number, isValid }) => {
    setIsValid(isValid);
    if (onValidate) {
      const parsedNumber = number && isValid && parsePhoneNumber(number);
      return onValidate({ number, parsedNumber, isValid });
    }
  };

  return (
    <div className={`${isValid ? containerClass : 'mb-9'} ${width}`}>
      <Label
        className={`font-MarkProBold os-transition os-transitionproperty-all md:text-left ${
          !isValid ? 'text-live-red' : ''
        }`}
      >
        Mobile Number *
      </Label>
      <PhoneInput
        defaultCountry='AU'
        international
        autoFocus={autoFocus}
        addInternationalOption={false}
        // countryCallingCodeEditable={false}
        placeholder='e.g 0400 123 123'
        onChange={(value) => {
          setPhoneNumber(value);
        }}
        onBlur={() => {
          if (phoneNumber && phoneNumber === prevPhoneNumber) {
            return;
          }
          const validatorMessage = validator(phoneNumber);
          validatorMessage && setValidatorMessage(validatorMessage);
        }}
        className={`os-transition os-transitionproperty-all font-MarkPro
          focus:outline-none focus:border-white
          ${!isValid ? ' error' : ''}`}
      />
      {!isValid && (
        <Label
          textSize={'text-xs'}
          color={'text-live-red'}
          className={`
            font-MarkProBold
            block
            os-transition
            os-transitionproperty-all
            ${validatorMessage ? `mb-16` : ``}
          `}
        >
          {validatorMessage}
        </Label>
      )}
    </div>
  );
};

export default InternationalPhoneNumberInput;
