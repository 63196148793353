import _ from 'lodash';
import { isCarouselTypeCustomWidget } from '../components/ui/PromotionalPlacements/utils';
import keyToID from 'utils/keyToID';

const pageMapping = ({
  page,
  id,
  customWidgetConfig,
  isPromotionalPlacementsEnable,
}) => {
  const pageData = _.get(page, 'data', page);
  const panels = _.get(pageData, 'panels');

  // We cannot use key as id at the moment until we completely move off v1
  // since we need the fallback and key is in the format of all uppercase
  // and hyphens i.e. NEWS-PREMIER-LEAGUE (v2) instead of news_premier-league (v1)
  // const key = _.get(pageData, 'key');

  // We will transform all panel ids to lowercase/underscore for time being until we make
  // other applicable changes across the site to support uppercase and hyphens
  // i.e. HOME-FEATURED -> home_featured

  const parsedPanels = _.map(panels, (panel) => {
    const panelType = panel?.type;
    if (
      isCarouselTypeCustomWidget(customWidgetConfig, panelType) &&
      !isPromotionalPlacementsEnable
    ) {
      return false;
    }

    let panelId = _.get(panel, 'id', '');

    if (!panelId) {
      // support for v3
      panelId = _.get(panel, 'bucketKey', '');
      _.set(panel, 'panelType', _.get(panel, 'type', ''));

      let layout = _.get(panel, 'layout', '');
      if (typeof layout === 'string') {
        const rows = _.get(panel, 'rows', '');
        layout = { id: layout, ...(rows && { rows }) };
        _.set(panel, 'layout', layout);
      }
    }
    const lowerCaseUnderscorePanelId = _.toLower(panelId.replace(/-/g, '_'));

    _.set(panel, 'id', lowerCaseUnderscorePanelId);

    // support for custom panels
    if (
      panel.config &&
      customWidgetConfig &&
      customWidgetConfig.hasOwnProperty(panel.panelType)
    ) {
      panel = { ...panel, ...customWidgetConfig[panel.panelType] };
      if (!panel.id) {
        panel.id = keyToID(panel.widgetKey);
      }
    }

    return panel;
  });

  const filteredPanels = parsedPanels.filter((panel) => panel !== false);

  return {
    id,
    panels: filteredPanels,
  };
};

export default pageMapping;
