import React from 'react';
import PropTypes from 'prop-types';
import { getAbsoluteStaticUrl } from 'utils/path';

const RightScrollControl = ({hasGradient, className, onClick}) => (
  <button
    onClick={onClick}
    className={`flex flex-no-wrap justify-center items-center h-80 w-32 bg-medium-dark-grey border-l border-r border-medium-grey os-transition-btn os-box-sizing-border-box flex-none outline-none relative ${className}`}
  >
    <span className="h-32 w-32">
      <img src={getAbsoluteStaticUrl("/images/icon-small-right.svg")} className="object-contain" alt="" />
    </span>
  </button>
);

RightScrollControl.propTypes = {
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Click handler function */
  onClick: PropTypes.func,
};

RightScrollControl.defaultProps = {
  className: '',
  imgUrl: ''
};

export default RightScrollControl;
